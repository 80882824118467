import React from 'react'
import { orderStatus, payment, deliveryAction } from 'helpers/translates'
import { format } from 'date-fns'
import { OrderStatus } from 'helpers/enums'

export const colorsStatus = (value) => {
  switch (value) {
    case OrderStatus.Created:
      return {
        backgroundColor: 'green',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Pickup:
      return {
        backgroundColor: '#c7c7c7',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Waiting:
      return {
        backgroundColor: 'red',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Canceled:
      return {
        backgroundColor: 'red',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Pickuped:
      return {
        backgroundColor: '#8FC95C',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Completed:
      return {
        backgroundColor: '#8FC95C',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Delivered:
      return {
        backgroundColor: '#FFBA01', //yellow
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Checked:
      return {
        backgroundColor: '#8FC95C',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Discarded:
      return {
        backgroundColor: 'red',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }
    case OrderStatus.Replacing:
      return {
        backgroundColor: '#FFBA01', //yellow
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }

    case OrderStatus.DeliveryCheck:
      return {
        backgroundColor: '#8FC95C',
        width: '100%',
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center'
      }

    default:
      break
  }
}

export const columns = [
  {
    field: 'orderNum',
    type: 'number',
    headerName: 'Номер замовлення',
    width: 200
  },

  {
    field: 'store',
    headerName: 'Адреса',
    valueGetter: (params) => params.row.store.address,
    width: 250
  },
  {
    field: 'createdAt',
    type: 'dateTime',
    headerName: 'Дата оформлення',
    width: 200,
    renderCell: (params) => {
      return format(new Date(params.value), 'dd.MM.yyyy Час: HH:mm')
    }
  },
  {
    field: 'status',
    headerName: 'Статус',
    width: 300,
    valueGetter: ({ value }) => orderStatus[value],
    renderCell: (params) => {
      return <div style={colorsStatus(params.row.status)}>{params.value}</div>
    }
  },
  {
    field: 'paymentAction',
    headerName: 'Спосіб оплати',
    width: 200,
    valueGetter: ({ value }) => payment[value]
  },
  {
    field: 'deliveryAction',
    headerName: 'Спосіб доставки',
    width: 200,
    valueGetter: ({ value }) => deliveryAction[value]
  },
  {
    field: 'address',
    headerName: 'Aдреса',
    width: 200,
    valueGetter: (params) => {
      if (!params.row.address) return ''
      return `${params.row.address?.street} ${params.row.address?.building}`
    }
  },
  {
    field: `user`,
    headerName: `Ім'я клієнта`,
    width: 350,
    valueGetter: (params) =>
      `${params.row.user.name} ${params.row.user.lastName} ${params.row.user.phone}`
  }
]
