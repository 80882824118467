import { ExsistStatuses } from './enums'

export const existedStatusesTranslate = {
  [ExsistStatuses.PickupStartAt]: 'Прийняття замовлення',
  [ExsistStatuses.PickupedAt]: 'Комплектування',
  [ExsistStatuses.DeliveryStartAt]: 'Прийняття доставки',
  [ExsistStatuses.DeliveredAt]: 'Доставлено',
  [ExsistStatuses.ComplitedAt]: 'Доставка',
  [ExsistStatuses.FinalizedAt]: 'Порернення курьера/Видача клієнту'
}
