import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: '100%'
  }
}))

export default function EmployeeItem({ data }) {
  const classes = useStyles()
  const [age, setAge] = useState('')
  const [open, setOpen] = useState(false)

  const handleChange = (event) => {
    setAge(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <div>
      <Button className={classes.button} onClick={handleOpen}>
        Оберіть Роль
      </Button>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label" color="secondary">
          Роль
        </InputLabel>
        <Select
          color="secondary"
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={age}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Не Обрано</em>
          </MenuItem>
          {data.map(({ role, id }) => {
            return (
              <MenuItem value={role} key={id}>
                {role}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}
