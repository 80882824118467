import React, { useCallback, useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import StoresSettingsItem from './StoresSettingsItem'
import GlobalSettings from './GlobalSettings'
import { makeStyles, Typography } from '@material-ui/core'
import { successColor } from 'assets/jss/material-dashboard-react'
import { useSelector } from 'react-redux'
import { useHelper } from 'shared/hooks/useHelper'
import { StoreHelper } from 'services/helpers/StoreHelper'
import { Search } from './Search'


export default function StoresSettings() {
  const storesState = useSelector((state) => state.stores)

  const classes = useStyles()
  const storesHelper = useHelper(StoreHelper)
 
  const [shops, setShops] = useState([])
  
  /* eslint-disable */

  useEffect(() => {
    storesHelper.countStores()
    storesHelper.setStores(0, Number.MAX_SAFE_INTEGER)  
  }, [])


  useEffect(() => {
    if (!storesState.stores.length) return
    setShops(storesState.stores)
    
  }, [storesState.stores.length])

  /* eslint-enable */

  const handleDataChange = useCallback((doc) => {
    setShops(doc)
  }, []) 

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <GlobalSettings  />
        </GridItem>
        <div className={classes.secoundTitle}>
          <Typography
            style={{
              color: successColor[0],
              fontWeight: '500',
              paddingRight: 5
            }}
          >
            Локальні
          </Typography>
          <Typography>налаштування</Typography>
        </div>
        <div className={classes.searchContainer}>
          <Search setData={handleDataChange} shops={storesState.stores} />
        </div>
        {shops.map((data, i) => {
          return <StoresSettingsItem key={`${data.uuid}-${i}`} item={data} />
        })}
      </GridItem>
    </GridContainer>
  )
}

const useStyles = makeStyles(() => ({
  secoundTitle: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },
  paggingButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10
  }
}))
