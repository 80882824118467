import { AbstractRequest } from 'services/abstract/AbstractRequest'

const limit = 10

export class ClientApiService extends AbstractRequest {
  constructor() {
    super(`/users`)
  }

  async addClients({ skip, phone, email, filter }) {
    const params = {
      skip,
      limit: filter ? Number.MAX_SAFE_INTEGER : limit,
      phone,
      email,
      filter: filter && JSON.stringify({ filter })
    }
    const { data, status } = await this.get(this.baseUrl, { params })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async setTotal() {
    const { data, status } = await this.get(`${this.baseUrl}/count`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async banUser(id) {
    const refreshToken = this._getRefreshToken()
    const { data, status } = await this.patch(`${this.baseUrl}/${id}/ban/add`, {
      refreshToken
    })
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async unbanUser(id) {
    const refreshToken = this._getRefreshToken()
    const { data, status } = await this.patch(
      `${this.baseUrl}/${id}/ban/remove`,
      {
        refreshToken
      }
    )
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async vipUser(id) {
    const refreshToken = this._getRefreshToken()
    const { data, status } = await this.patch(`${this.baseUrl}/${id}/vip/add`, {
      refreshToken
    })
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async unvipUser(id) {
    const refreshToken = this._getRefreshToken()
    const { data, status } = await this.patch(
      `${this.baseUrl}/${id}/vip/remove`,
      {
        refreshToken
      }
    )
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async setBannedTotal() {
    const { data, status } = await this.get(`${this.baseUrl}/ban/count`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async setVipTotal() {
    const { data, status } = await this.get(`${this.baseUrl}/vip/count`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async addBanned(skip) {
    const { data, status } = await this.get(`${this.baseUrl}/ban`, {
      params: {
        skip,
        limit
      }
    })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async addVip(skip) {
    const { data, status } = await this.get(`${this.baseUrl}/vip`, {
      params: {
        skip,
        limit
      }
    })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }
}
