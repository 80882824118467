import React, { useState } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

export const StaticPagesItem = ({
  label,
  id,
  content,
  showNav,
  onChange,
  onSave,
  contentTitle,
  onChangeTitle,
  onCancel,
  onDelete
}) => {
  const [buttons, setButtons] = useState(false)
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div
          style={{
            margin: 5,
            padding: 5,
            backgroundColor: '#f7f6f6',
            borderRadius: 10
          }}
        >
          <IconButton
            onClick={onDelete}
            aria-label="delete"
            style={{
              position: 'absolute',
              right: 25
            }}
          >
            <DeleteIcon />
          </IconButton>
          <CustomInput
            value={contentTitle}
            onChange={(value) => {
              setButtons(showNav(value, value.target.value))
              onChange && onChangeTitle(value.target.value)
            }}
            success={contentTitle}
            error={!contentTitle}
            labelText={label}
            id={id}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              multiline: true
            }}
          />
          <CustomInput
            value={content}
            onChange={(value) => {
              setButtons(showNav(value, value.target.value))
              onChange && onChange(value.target.value)
            }}
            success={content}
            error={!content}
            labelText={'Текст Сторінки'}
            id={id}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              multiline: true
            }}
          />
          {buttons && (
            <div>
              <Button
                onClick={() => {
                  setButtons(false)
                  onCancel(content)
                }}
              >
                Скасувати
              </Button>
              <Button
                color="success"
                onClick={() => {
                  setButtons(false)
                  onSave(content)
                }}
              >
                Зберегти
              </Button>
            </div>
          )}
        </div>
      </GridItem>
    </GridContainer>
  )
}
