import { config } from 'config'
import { store } from 'redux/store'

import { AbstractRequestService } from './AbstractRequestService'
import {
  syncSuccess,
  setCategoriesLoading,
  setFamiliesLoading,
  setPricesLoading,
  setEmployeesLoading,
  setProductsLoading,
  setProductsAvailableLoading,
  setProductsImagesLoading,
  productsImagesSuccess,
  productsAvailableSuccess,
  productsSuccess,
  employeesSuccess,
  pricesSuccess,
  familiesSuccess,
  categoriesSuccess,
  storesSuccess,
  setStoresLoading,
  setGroupsLoading,
  setSyncLoading,
  groupsSuccess
} from 'redux/reducers/syncReducer'

export class SyncService extends AbstractRequestService {
  constructor() {
    super(`${config.api}/sync`)
  }

  async SyncAll() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        setLoading: setSyncLoading
      })
      if (response.status === 200) {
        store.dispatch(
          syncSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
    // count
    // synced
  }

  async SyncGroups() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/groups',
        setLoading: setGroupsLoading
      })
      if (response.status === 200) {
        store.dispatch(
          groupsSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncStores() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/stores',
        setLoading: setStoresLoading
      })
      if (response.status === 200) {
        store.dispatch(
          storesSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncCategories() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/categories',
        setLoading: setCategoriesLoading
      })
      if (response.status === 200) {
        store.dispatch(
          categoriesSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncFamilies() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/families',
        setLoading: setFamiliesLoading
      })
      if (response.status === 200) {
        store.dispatch(
          familiesSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncPrices() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/prices',
        setLoading: setPricesLoading
      })
      if (response.status === 200) {
        store.dispatch(
          pricesSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncEmployees() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/employees',
        setLoading: setEmployeesLoading
      })
      if (response.status === 200) {
        store.dispatch(
          employeesSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncProducts() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/products',
        setLoading: setProductsLoading
      })
      if (response.status === 200) {
        store.dispatch(
          productsSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncProductsAvailable() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/products/available',
        setLoading: setProductsAvailableLoading
      })
      if (response.status === 200) {
        store.dispatch(
          productsAvailableSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }

  async SyncProductsImages() {
    return this.dispatchRequest(async () => {
      const response = await this.sendRequest({
        method: 'post',
        url: '/products/images',
        setLoading: setProductsImagesLoading
      })
      if (response.status === 200) {
        store.dispatch(
          productsImagesSuccess({
            synced: true,
            count: (response && response.data.data.count) || null
          })
        )
      }
    })
  }
}
