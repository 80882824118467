import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  access: '',
  refresh: '',
  loading: false,
  error: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.user = action.payload.user
      state.access = action.payload.access
      state.refresh = action.payload.refresh
    },
    removeUserData(state) {
      state.user = null
      state.access = ''
      state.refresh = ''
    },
    setError(state, action) {
      state.error = action.payload
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
      state.error = ''
    }
  }
})

const { reducer, actions } = userSlice
export default reducer
export const { setUserData, removeUserData, setLoading, setError } = actions
