import { orderBy } from 'lodash'

export const SortUtils = {
  sortByDate(orders) {
    const sortedArray = orderBy(orders, (item) => new Date(item.createdAt), [
      'desc'
    ])
    return sortedArray
  }
}
