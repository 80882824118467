/*!

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './redux/store'

// core components
import Admin from 'layouts/Admin.js'
import 'assets/css/material-dashboard-react.css?v=1.9.0'
import Login from 'views/login/Login'
import { WindowFocus } from 'shared/hoc/WindowFocus'
// remove after release to blyzenko
// import EmployeeHistory from 'views/History/EmployeeHistory'

export const hist = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    <WindowFocus>
      <Router history={hist}>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/login" component={Login} />
          {/*
            remove after release to blyzenko
           <Route path="/history" component={EmployeeHistory} /> 
           */}
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Router>
    </WindowFocus>
  </Provider>,
  document.getElementById('root')
)
