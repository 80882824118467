import { setGroups } from 'redux/reducers/goupsReducer'
import { setLoading } from 'redux/reducers/appReducer'
import { StatefullService } from './StatefullService'
import { GroupsApiService } from 'services/api/GroupsApiService'

export class GroupHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.groupsApiService = new GroupsApiService()
  }

  static make() {
    if (!GroupHelper.instance) {
      GroupHelper.instance = new GroupHelper()
    }
    return GroupHelper.instance
  }

  async getGroups() {
    const { isSuccess, data } = await this._callApi(
      this.groupsApiService.getGroups.bind(this.groupsApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setGroups(data))
      return data
    }
    return null
  }

  async updateSettings(groups) {
    const { isSuccess, data } = await this._callApi(
      this.groupsApiService.updateSettings.bind(this.groupsApiService),
      setLoading,
      groups
    )
    if (isSuccess) {
      this._dispatch(setGroups(data))
      return data
    }
    return null
  }
}
