import axios from 'axios'
import { store } from 'redux/store'
import { UserService } from './UserService'

export class AbstractRequestService {
  constructor(baseURL) {
    this.userService = new UserService()
    this.axios = axios.create({
      baseURL
    })
  }

  async dispatchRequest(request, retry = true) {
    try {
      const result = await request()
      return result
    } catch (e) {
      console.error(e)
      if (e.response && e.response.status === 401) {
        await this.userService.refresh()
        const { error } = store.getState().user
        if (error) {
          this.userService.logout()
        } else if (retry) {
          return this.dispatchRequest(request, false)
        }
      }
    }
  }

  async sendRequest({ method, url, params, setLoading, data }) {
    const { access } = store.getState().user
    store.dispatch(setLoading(true))
    const response = await this.axios({
      url,
      method,
      params,
      data,
      headers: {
        Authorization: `Bearer ${access}`,
        Accept: 'application/json'
      }
    })
    store.dispatch(setLoading(false))
    return response
  }
}
