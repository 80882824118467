import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class DevicesApiService extends AbstractRequest {
  constructor() {
    super('/devices')
  }

  async create(deviceId) {
    const { data, status } = await this.post(this.baseUrl, {
      deviceId
    })
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }
}
