import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export const OrderMainItem = ({ styles, data, justifyContent }) => {
  return (
    <Grid
      container
      style={styles.mainContentContainer}
      justifyContent={justifyContent}
    >
      {data.map(({ key, value }) => {
        return (
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={12}
            style={styles.mainItem}
            key={key + value}
          >
            <Typography style={styles.keyText}>{key} </Typography>
            {typeof value === 'string' ? (
              <Typography style={styles.valueText}>{value}</Typography>
            ) : (
              <div style={styles.valueText}>{value}</div>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}
