import React, { useEffect, useState } from 'react'

import { createMuiTheme } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { successColor } from 'assets/jss/material-dashboard-react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
  TimePicker,
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Button from 'components/CustomButtons/Button'
import { useSelector } from 'react-redux'
import Spiner from 'components/Spiner/Spiner'
import { parse } from 'date-fns'
import { useHelper } from 'shared/hooks/useHelper'
import { SettingsHelper } from 'services/helpers/SettingsHelper'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import { uk } from 'date-fns/esm/locale'
import GridItem from 'components/Grid/GridItem'
import { DeliverySettings } from './DeliverySettings'

const useStyles = makeStyles(() => ({
  paddingRight: {
    paddingRight: 10,
    '& .MuiFormLabel-root': {
      paddingTop: 10
    }
  },
  stopService: {
    paddingRight: 10,
    '& .MuiFormLabel-root': {
      paddingTop: 10
    }
  },
  stopServiceContainer: {
    paddingTop: 10
  },
  heading: {
    display: 'flex',
    flexDirection: 'columm',
    flex: 1,
    paddingBottom: 60,
    justifyContent: 'center',
    textAlign: 'center'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30
  },
  secoundTitle: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30
  },
  buttons: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },
  content: {
    paddingBottom: 60
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row'
  },
  paddingLeft10: {
    paddingLeft: 10
  },
  '& .makeStyles-heading-74': {
    justifyContent: 'center'
  }
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      main: successColor[0]
    },
    secondary: {
      main: successColor[0]
    }
  }
})

const dateValues = ['serviceStartDate', 'serviceEndDate']

const timeValues = [
  'selfStartTime',
  'selfEndTime',
  'alcoholStartTime',
  'alcoholEndTime',
  'courierStartTime',
  'courierEndTime'
]

const numValues = [
  'packingPrice',
  'deliveryRegularPrice',
  'deliveryPrice',
  'ordering',
  'packing',
  'delivering',
  'compliting',
  'pricing',
  'selfMaxWeight'
]

const normalizeValue = (key, value) => {
  if (numValues.includes(key)) return Number(value)
  if (timeValues.includes(key)) {
    if (typeof value === 'string') {
      return parse(value, 'HH:mm', new Date())
    }
    return value
  }

  if (dateValues.includes(key)) {
    if (typeof value === 'string') {
      return parse(value, 'dd/mm/yyyy', new Date())
    }
    return value
  }

  return value
}

const GlobalSettings = () => {
  const classes = useStyles()
  const loading = useSelector(appLoadingSelector)
  const storeSettings = useSelector((state) => state.settings.settings)
  const [settings, setSettings] = useState(storeSettings)
  const settingsHelper = useHelper(SettingsHelper)
  const [selfStartTime, setSelfStartTime] = useState(new Date())
  const [selfEndTime, setSelfEndTime] = useState(new Date())
  const [alcoholStartTime, setAlcoholStartTime] = useState(new Date())
  const [alcoholEndTime, setAlcoholEndTime] = useState(new Date())
  const [courierStartTime, setCourierStartTime] = useState(new Date())
  const [courierEndTime, setCourierEndTime] = useState(new Date())
  const [serviceStartDate, setServiceStartDate] = useState(new Date())
  const [serviceEndDate, setServiceEndDate] = useState(new Date())

  /* eslint-disable */

  useEffect(() => {
    if (!storeSettings) return
    setSettings(storeSettings)
    handleReset(storeSettings)
  }, [storeSettings])

  useEffect(() => {
    settingsHelper.setSettings()
  }, [])
  /* eslint-enable */

  const handleSaveGlobal = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)

    const data = Array.from(formData.entries()).reduce((acc, [key, value]) => {
      return { ...acc, [key]: normalizeValue(key, value) }
    }, {})

    const payload = {
      ...settings,
      ...data,
      selfStartTime: normalizeValue('selfStartTime', selfStartTime),
      selfEndTime: normalizeValue('selfEndTime', selfEndTime),
      alcoholStartTime: normalizeValue('alcoholStartTime', alcoholStartTime),
      alcoholEndTime: normalizeValue('alcoholEndTime', alcoholEndTime),
      courierStartTime: normalizeValue('courierStartTime', courierStartTime),
      courierEndTime: normalizeValue('courierEndTime', courierEndTime),
      serviceStartDate: normalizeValue('serviceStartDate', serviceStartDate),
      serviceEndDate: normalizeValue('serviceEndDate', serviceEndDate)
    }
    settingsHelper.putSettings(payload)
    setSettings(payload)
  }

  const handleReset = (settings) => {
    if (!settings) return
    setServiceStartDate(new Date(settings.serviceStartDate))
    setServiceEndDate(new Date(settings.serviceEndDate))
    setSelfStartTime(new Date(settings.selfStartTime))
    setSelfEndTime(new Date(settings.selfEndTime))
    setAlcoholStartTime(new Date(settings.alcoholStartTime))
    setAlcoholEndTime(new Date(settings.alcoholEndTime))
    setCourierStartTime(new Date(settings.courierStartTime))
    setCourierEndTime(new Date(settings.courierEndTime))
  }

  if (loading) return <Spiner />
  if (!settings) return null

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uk}>
      <ThemeProvider theme={theme}>
        <form onSubmit={handleSaveGlobal}>
          <div className={classes.titleContainer}>
            <Typography
              style={{
                color: successColor[0],
                fontWeight: '500',
                paddingRight: 5
              }}
            >
              Глобальні
            </Typography>
            <Typography>налаштування</Typography>
          </div>
          {/* Start global Settings */}

          <div className={classes.heading}>
            <div>
              <TextField
                className={classes.paddingRight}
                variant="standard"
                id="standard-number"
                label="Ціна за комплектацію"
                type="number"
                name="packingPrice"
                defaultValue={settings.packingPrice}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">₴</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                className={classes.paddingRight}
                id="standard-number"
                label="Ціна доставки"
                type="number"
                name="deliveryPrice"
                defaultValue={settings.deliveryPrice}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">₴</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              <TimePicker
                className={classes.paddingRight}
                clearable
                value={selfStartTime}
                onChange={setSelfStartTime}
                ampm={false}
                name="selfStartTime"
                label="Смовивіз з"
              />
              <TimePicker
                className={classes.paddingRight}
                clearable
                value={selfEndTime}
                onChange={setSelfEndTime}
                ampm={false}
                name="selfEndTime"
                label="Смовивіз до"
              />

              <TimePicker
                className={classes.paddingRight}
                clearable
                value={courierStartTime}
                onChange={setCourierStartTime}
                ampm={false}
                name="courierStartTime"
                label="Доставка з"
              />
              <TimePicker
                className={classes.paddingRight}
                clearable
                value={courierEndTime}
                onChange={setCourierEndTime}
                ampm={false}
                name="courierEndTime"
                label="Доставка до"
              />
              <TextField
                className={classes.paddingRight}
                id="standard-number"
                label="Ліміт ваги самовивіз"
                type="number"
                name="selfMaxWeight"
                defaultValue={settings.selfMaxWeight}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">кг</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TimePicker
                className={classes.paddingRight}
                clearable
                value={alcoholStartTime}
                onChange={setAlcoholStartTime}
                ampm={false}
                name="alcoholStartTime"
                label="Замовлення алкоголю з"
              />
              <TimePicker
                className={classes.paddingRight}
                clearable
                value={alcoholEndTime}
                onChange={setAlcoholEndTime}
                ampm={false}
                name="alcoholEndTime"
                label="Замовлення алкоголю до"
              />
              <div className={classes.stopServiceContainer}>
                <DatePicker
                  autoOk
                  name="serviceStartDate"
                  className={classes.stopService}
                  value={serviceStartDate}
                  onChange={setServiceStartDate}
                  ampm={false}
                  label="Зупинити роботу сервісу з"
                  emptyLabel="Оберіть"
                  showTodayButton={true}
                  minDateMessage="Дата не повинна бути раніше мінімальної дати"
                  cancelLabel="Скасувати"
                  clearLabel="Очистити"
                  todayLabel="Сьогодні"
                  format="dd/MM/yyyy"
                />
                <DatePicker
                  name="serviceEndDate"
                  className={classes.stopService}
                  autoOk
                  value={serviceEndDate}
                  onChange={setServiceEndDate}
                  ampm={false}
                  label="Зупинити роботу сервісу до"
                  emptyLabel="Оберіть"
                  showTodayButton={true}
                  minDateMessage="Дата не повинна бути раніше мінімальної дати"
                  cancelLabel="Скасувати"
                  clearLabel="Очистити"
                  todayLabel="Сьогодні"
                  format="dd/MM/yyyy"
                />
              </div>
              <div className={classes.buttons}>
                <Button type="reset" onClick={handleReset}>
                  Скасувати
                </Button>
                <Button color="success" type="submit">
                  Зберегти
                </Button>
              </div>
            </div>
          </div>

          {/* end global Settings */}
          {/* Start Statuses */}
          <div className={classes.secoundTitle}>
            <Typography
              style={{
                color: successColor[0],
                fontWeight: '500',
                paddingRight: 5
              }}
            >
              Статусні
            </Typography>
            <Typography>дедлайни</Typography>
          </div>
          <div className={classes.heading}>
            <div className={classes.content}>
              <TextField
                className={classes.paddingRight}
                id="standard-number"
                label="Прийняти замовлення"
                type="number"
                name="ordering"
                defaultValue={settings.ordering}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Хв</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                className={classes.paddingRight}
                id="standard-number"
                label="Комплектування"
                type="number"
                name="packing"
                defaultValue={settings.packing}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Хв</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                className={classes.paddingRight}
                id="standard-number"
                label="Прийняти доставку"
                name="delivering"
                defaultValue={settings.delivering}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Хв</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                className={classes.paddingRight}
                id="standard-number"
                label="Доставка"
                type="number"
                name="compliting"
                defaultValue={settings.compliting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Хв</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                className={classes.paddingRight}
                id="standard-number"
                label="Повернення кур'єра"
                type="number"
                name="pricing"
                defaultValue={settings.pricing}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Хв</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              <div className={classes.buttons}>
                <Button type="reset" onClick={() => handleReset(settings)}>
                  Скасувати
                </Button>
                <Button color="success" type="submit">
                  Зберегти
                </Button>
              </div>
            </div>
          </div>

          {/* end Statuses */}
        </form>
        <GridItem xs={12} sm={12} md={12}>
          {loading && <Spiner />}
          <DeliverySettings settings={settings} />
        </GridItem>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default GlobalSettings
