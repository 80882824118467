import { Dialog, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'

const CustomSkinMap = withScriptjs(
  withGoogleMap((props) => {
    const [activeAddress, setActiveAddress] = useState('')

    const addresses = props && props.data
    return (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{ lat: 49.83826, lng: 24.02324 }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true,
          styles: [
            {
              featureType: 'water',
              stylers: [
                { saturation: 43 },
                { lightness: -11 },
                { hue: '#0088ff' }
              ]
            },
            {
              featureType: 'road',
              elementType: 'geometry.fill',
              stylers: [
                { hue: '#ff0000' },
                { saturation: -100 },
                { lightness: 99 }
              ]
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{ color: '#808080' }, { lightness: 54 }]
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.fill',
              stylers: [{ color: '#ece2d9' }]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [{ color: '#ccdca1' }]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{ color: '#767676' }]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [{ color: '#ffffff' }]
            },
            { featureType: 'poi', stylers: [{ visibility: 'off' }] },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry.fill',
              stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
            },
            { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
            {
              featureType: 'poi.sports_complex',
              stylers: [{ visibility: 'on' }]
            },
            { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'simplified' }]
            }
          ]
        }}
      >
        {addresses.map(
          ({ latitude, longitude, street, uuid, building, apart }) => {
            return (
              <Marker
                key={uuid}
                position={{ lat: latitude, lng: longitude }}
                onClick={() => {
                  setActiveAddress(
                    `${street} ${building}, ${apart || 'Квартиру не вказано'}`
                  )
                }}
              >
                <Dialog
                  open={!!activeAddress}
                  onClose={() => setActiveAddress('')}
                >
                  <DialogTitle>{activeAddress}</DialogTitle>
                </Dialog>
              </Marker>
            )
          }
        )}
      </GoogleMap>
    )
  })
)

export default function Maps(route) {
  if (route.location.state && route.location.state.length <= 0) {
    return (
      <h1 style={{ textAlign: 'center' }}>
        {' '}
        В данного користувача немає адрес{' '}
      </h1>
    )
  }
  return (
    <CustomSkinMap
      data={route.location.state}
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA0BrqZTmoJO5NNkqMljv-Hnml51kR5x9s"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100vh` }} />}
      mapElement={<div style={{ height: `100%`, color: 'green' }} />}
    />
  )
}
