import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  nonImgProducts: []
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setNonImageProducts(state, action) {
      state.nonImgProducts = action.payload
    }
  }
})

const { reducer, actions } = productsSlice
export default reducer
export const { setNonImageProducts } = actions
