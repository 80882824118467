import { AbstractRequest } from 'services/abstract/AbstractRequest'

const limit = Number.MAX_SAFE_INTEGER
export class ReturnActApiService extends AbstractRequest {
  constructor() {
    super('/acts')
  }

  async getAct(actId) {
    const { data, status } = await this.get(`${this.baseUrl}/${actId}`, {
      actId
    })

    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async getActs({ orderIds }) {
    const { data, status } = await this.get(this.baseUrl, {
      params: {
        skip: 0,
        limit,
        sort: 'createdAt',
        filter: JSON.stringify({ orderId: orderIds })
      }
    })
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async updateAct({ actId, products }) {
    const { data, status } = await this.patch(`${this.baseUrl}/${actId}`, {
      products
    })

    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }
}
