export const OrderStatus = Object.freeze({
  Created: 'created',
  Waiting: 'waiting',
  Pickup: 'pickup',
  Deliver: 'deliver',
  Return: 'return',
  Check: 'check',
  Canceled: 'canceled',
  Completed: 'completed',
  Self: 'self',
  Pickuped: 'pickuped',
  Delivered: 'delivered',
  Checked: 'checked',
  Discarded: 'discarded',
  Replacing: 'replacing',
  DeliveryCheck: 'delivery-check'
})
