import { AbstractStorageManager } from 'services/abstract/AbstractStorageManager'

export class CredentialsManager extends AbstractStorageManager {
  constructor() {
    super()
    this.accessKey = 'access_token'
    this.refreshKey = 'refresh_token'
  }

  getAccess() {
    return this.getItem(this.accessKey)
  }

  setAccess(value) {
    return this.setItem(this.accessKey, value)
  }

  removeAccess() {
    return this.removeItem(this.accessKey)
  }

  hasAccess() {
    return this.hasItem(this.accessKey)
  }

  getRefresh() {
    return this.getItem(this.refreshKey)
  }

  setRefresh(value) {
    return this.setItem(this.refreshKey, value)
  }

  removeRefresh() {
    return this.removeItem(this.refreshKey)
  }

  hasRefresh() {
    return this.hasItem(this.refreshKey)
  }

  setCredentials(access, refresh) {
    this.setAccess(access)
    this.setRefresh(refresh)
  }

  removeCredentials() {
    this.removeRefresh()
    this.removeAccess()
  }

  hasCredentials() {
    return this.hasAccess() && this.hasRefresh()
  }
}
