import { setLoading } from 'redux/reducers/appReducer'
import { addEmployeesSuccess } from 'redux/reducers/employeesReducer'
import { setTotal } from 'redux/reducers/employeesReducer'
import { setEmployeesSuccess } from 'redux/reducers/employeesReducer'
import { EmployeesApiService } from 'services/api/EmployeesApiService'
import { EmployeeStatus } from 'shared/enums'
import { StatefullService } from './StatefullService'

export class EmployeesHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.employeesApiService = new EmployeesApiService()
  }

  static make() {
    if (!EmployeesHelper.instance) {
      EmployeesHelper.instance = new EmployeesHelper()
    }
    return EmployeesHelper.instance
  }

  async getEmployees(skip, filter = {}) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.employeesApiService.getEmployees.bind(this.employeesApiService),
      setLoading,
      { skip, filter }
    )
    if (isSuccess) {
      if (filter) {
        this._dispatch(setEmployeesSuccess(data))
      } else {
        this._dispatch(addEmployeesSuccess(data))
      }

      return data
    }
    return null
  }

  async setEmployeeStatusFree(employeeId) {
    return await this.employeesApiService.updateEmployeeStatus(
      employeeId,
      EmployeeStatus.Free
    )
  }

  async getCountEmployees() {
    const { isSuccess, data } = await this._callApi(
      this.employeesApiService.getCountEmployees.bind(this.employeesApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setTotal(data))
      return data
    }
    return null
  }
}
