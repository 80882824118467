import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React from 'react'
import SelectRole from './SelectRole'

function Roles() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <SelectRole />
      </GridItem>
    </GridContainer>
  )
}

export default Roles
