import { store } from 'redux/store'

export class StatefullService {
  _dispatch(action) {
    store.dispatch(action)
  }

  _select(selector) {
    const state = store.getState()
    return selector(state)
  }

  async _callApi(method, setLoading, ...args) {
    setLoading && this._dispatch(setLoading(true))
    const data = await method(...args)
    setLoading && this._dispatch(setLoading(false))
    return data
  }
}
