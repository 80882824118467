import {
  setFeedbacks,
  setLoading,
  addFeedbacks
} from 'redux/reducers/feedbacksReducer'
import { FeedbackApiService } from 'services/api/FeedbackApiService'
import { SortUtils } from 'services/utils/SortUtils'
import { StatefullService } from './StatefullService'

const skip = 0
export class FeedbackHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.feedbackApiService = new FeedbackApiService()
  }

  static make() {
    if (!FeedbackHelper.instance) {
      FeedbackHelper.instance = new FeedbackHelper()
    }
    return FeedbackHelper.instance
  }

  async setFeedbacks() {
    const { isSuccess, data } = await this._callApi(
      this.feedbackApiService.getFeedbacks.bind(this.feedbackApiService),
      setLoading,
      skip
    )
    if (isSuccess) {
      this._dispatch(setFeedbacks(SortUtils.sortByDate(data.data)))
      return data
    }
    return null
  }

  async addFeedbacks(skip) {
    const { isSuccess, data } = await this._callApi(
      this.feedbackApiService.getFeedbacks.bind(this.feedbackApiService),
      setLoading,
      skip
    )

    if (isSuccess) {
      this._dispatch(addFeedbacks(SortUtils.sortByDate(data.data)))
      return data
    }
    return null
  }
}
