import React, { useEffect, useLayoutEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Spiner from 'components/Spiner/Spiner'
import { useHelper } from 'shared/hooks/useHelper'
import { groupsSelector } from 'redux/selectors/groupsSelectors'
import { GroupHelper } from 'services/helpers/GroupsHelper'
import { useSelector } from 'react-redux'
import { GroupsTable } from './GroupsTable'
import { TextField, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import { appLoadingSelector } from 'redux/selectors/appSelector'

const useStyles = makeStyles(() => ({
  buttons: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'columm',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },
  headNav: {
    width: '100%',
    margin: '0 15px !important',
    flexWrap: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

export function Groups() {
  const classes = useStyles()
  const groupsHelper = useHelper(GroupHelper)
  const initialGroups = useSelector(groupsSelector)
  const loading = useSelector(appLoadingSelector)
  const [groups, setGroups] = useState([])
  const [groupsToShow, setGroupsToShow] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    groupsHelper.getGroups()
  }, [])

  useEffect(() => {
    setGroups(initialGroups)
  }, [initialGroups])

  useLayoutEffect(() => {
    if (search) {
      const filteredData = groups.filter(({ name }) =>
        name.toLowerCase().includes(search)
      )
      setGroupsToShow(filteredData)
      return
    }
    setGroupsToShow(groups)
  }, [groups, search])

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase())
  }

  const handlePriority = (group) => {
    const index = groups.findIndex(({ groupId }) => groupId === group.groupId)
    const updatedGroups = [...groups]
    updatedGroups.splice(index, 1, group)
    setGroups(updatedGroups)
  }

  const handleReset = () => {
    setSearch('')
    setGroups(initialGroups)
  }

  const handleSave = async () => {
    if (loading) return
    groupsHelper.updateSettings(groups)
  }

  if (loading) {
    return <Spiner />
  }

  return (
    <GridContainer>
      <div className={classes.headNav}>
        <TextField
          id="search"
          label="Группа"
          variant="standard"
          onChange={handleSearch}
          value={search}
        />

        <div className={classes.buttons}>
          <Button type="reset" onClick={handleReset}>
            Скасувати
          </Button>
          <Button color="success" onClick={handleSave}>
            Зберегти
          </Button>
        </div>
      </div>
      <GridItem xs={12} sm={12} md={12}>
        {groupsToShow?.length ? (
          <GroupsTable groups={groupsToShow} onChange={handlePriority} />
        ) : (
          <div>Групп не знайдено</div>
        )}
      </GridItem>
    </GridContainer>
  )
}
