// import { OrdersModal } from './OrdersModal'

export const orderColumns = [
  { field: 'storeId', headerName: 'Id магазину', width: 150 },
  {
    field: 'uuid',
    headerName: 'Id користувача',
    width: 300
  },
  { field: 'courier', headerName: "Ім'я кур'єра", width: 300 },
  { field: 'name', headerName: "Ім'я користувача", width: 300 },
  { field: 'userPhone', headerName: 'Телефон користувача', width: 300 },
  {
    field: 'role',
    headerName: 'Статус користувача',
    width: 150
    // renderCell: (params) => {
    //   return roles[params.value]
    // }
  },
  { field: 'summ', headerName: 'Сума замовлення', width: 200 },
  { field: 'dateTime', headerName: 'Дата та час', width: 300 },
  { field: 'qualityControl', headerName: 'Оцінка', width: 300 }

  // {
  //   field: 'icons',
  //   headerName: 'Опції',
  //   width: 150,
  //   renderCell: (params) => {
  //     return <OrdersModal {...params} />
  //   }
  // }
]
