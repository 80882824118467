import React, { useCallback, useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { successColor } from 'assets/jss/material-dashboard-react'
import { warningColor } from 'assets/jss/material-dashboard-react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useHelper } from 'shared/hooks/useHelper'
import { AuthHelper } from 'services/helpers/AuthHelper'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: warningColor[2]
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: successColor[0],
    color: '#fff',
    height: 50
  }
}))

export default function Login() {
  const classes = useStyles()
  const history = useHistory()
  const state = useSelector((state) => state.user)
  const [phone, setPhone] = useState()
  const [password, setPassword] = useState()
  const authHelper = useHelper(AuthHelper)
  const login = useCallback(
    (e) => {
      e.preventDefault()
      !state.loading && authHelper.login(phone, password)
    },
    [phone, password, state.loading]
  )

  useEffect(() => {
    if (authHelper.getUserData()) {
      history.push('/admin/dashboard')
    }
  }, [state.user, history])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Увійти
        </Typography>
        <form className={classes.form} onSubmit={login} noValidate>
          <TextField
            onChange={({ target }) => setPhone(target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Логін"
            name="phone"
            autoComplete="phone"
            autoFocus
          />
          <TextField
            onChange={({ target }) => setPassword(target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          {state.error && (
            <Typography variant="h6" color="error" align="center">
              Неправильний логін або пароль
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Увійти
          </Button>
        </form>
      </div>
    </Container>
  )
}
