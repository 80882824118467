import { useEffect } from 'react'
import { config } from 'config'
import { useDispatch } from 'react-redux'
import { setOrderSocket } from 'redux/reducers/gatewayReducer'
import io from 'socket.io-client'

// ONLY for DEV
// localStorage.debug = '*'

const orderSocket = io(`${config.apiBase}/orders`)

orderSocket.on('connect_error', (err) => {
  console.error(err)
})

orderSocket.on('connect_failed', (err) => {
  console.error('CONNECT_FAILED', err)
})

orderSocket.on('error', (err) => {
  console.error(err)
})

orderSocket.on('exception', (err) => {
  console.error(err)
})

export const WsGateway = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setOrderSocket(orderSocket))
  }, [dispatch])

  return children
}
