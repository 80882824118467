import React from 'react'

import BrandsModal from './BrandsModal'

export const columnsBrand = [
  { field: 'brandId', headerName: 'ID бренду', type: 'number', width: 200 },
  { field: 'title', headerName: 'Назва українською', width: 300 },
  { field: 'titleEnd', headerName: 'Назва англійською', width: 300 },
  { field: 'groupId', headerName: 'ID групи', width: 150 },

  {
    field: 'icons',
    headerName: 'Опції',
    width: 150,
    renderCell: (params) => {
      return <BrandsModal {...params} />
    }
  }
]
