import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  orders: [],
  total: 0,
  skip: 0,
  discardedOrders: [],
  discardedTotal: 0,
  discardedSkip: 0
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setDiscardedOrdersTotal(state, action) {
      state.discardedTotal = action.payload
    },
    setDiscardedOrders(state, action) {
      state.discardedOrders = action.payload
      state.discardedSkip = action.payload.length
    },
    addDiscardedOrders(state, action) {
      state.discardedOrders = state.discardedOrders.concat(action.payload)
      state.discardedSkip += action.payload.length
    },
    setOrdersTotal(state, action) {
      state.total = action.payload
    },
    setOrders(state, action) {
      state.orders = action.payload
      state.skip = action.payload.length
    },

    addOrders(state, action) {
      state.orders = state.orders.concat(action.payload)
      state.skip += action.payload.length
    },
    updateOrder(state, action) {
      const index = state.orders.findIndex(
        (order) => order.uuid === action.payload.uuid
      )
      if (index >= 0) {
        const target = state.orders[index]
        state.orders.splice(index, 1, { ...target, ...action.payload })
      }
    },
    addToOrders(state, action) {
      const index = state.orders.findIndex(
        (order) => order.uuid === action.payload.uuid
      )
      if (index >= 0) {
        state.orders.splice(index, 1, action.payload)
      } else {
        state.orders.unshift(action.payload)
      }
    },
    addToDiscarded(state, action) {
      const index = state.discardedOrders.findIndex(
        (order) => order.uuid === action.payload.uuid
      )
      if (index >= 0) {
        state.orders.splice(index, 1, action.payload)
      } else {
        state.discardedOrders.unshift(action.payload)
      }
    }
  }
})

const { reducer, actions } = ordersSlice
export default reducer
export const {
  addOrders,
  setOrdersTotal,
  addToDiscarded,
  addToOrders,
  setOrders,
  updateOrder,
  setDiscardedOrdersTotal,
  setDiscardedOrders,
  addDiscardedOrders
} = actions
