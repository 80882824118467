import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  skip: 0,
  loading: false,
  feedbacks: []
}

const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    addFeedbacks(state, action) {
      state.feedbacks = state.feedbacks.concat(action.payload)
      state.skip += action.payload.length
      state.loading = false
    },
    setFeedbacks(state, action) {
      state.feedbacks = action.payload
      state.skip = action.payload.length
    }
  }
})

const { reducer, actions } = feedbacksSlice
export default reducer
export const { addFeedbacks, setFeedbacks, setLoading } = actions
