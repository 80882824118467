import axios from 'axios'
import { config } from 'config'
import {
  removeUserData,
  setUserData,
  setLoading,
  setError
} from 'redux/reducers/userReducer'
import { store } from 'redux/store'

export class UserService {
  constructor() {
    this.axios = axios.create({
      baseURL: config.api
    })
  }

  setUserData(data) {
    window.localStorage.setItem('user', JSON.stringify(data))
    store.dispatch(setUserData(data))
  }

  getUserData() {
    const data = store.getState().user.user
    if (data) return data
    return JSON.parse(window.localStorage.getItem('user'))
  }

  async sendRequest(request) {
    try {
      store.dispatch(setLoading(true))
      const result = await request()
      store.dispatch(setLoading(false))
      return result
    } catch (e) {
      store.dispatch(setError(e.toString()))
    }
  }

  async refresh() {
    return this.sendRequest(async () => {
      const { refresh } = JSON.parse(window.localStorage.getItem('user'))
      const response = await this.axios.post('/auth/refresh', {
        refreshToken: refresh
      })
      const { data } = response.data
      this.setUserData({ ...data, refresh })
    })
  }

  async login(phone, password) {
    return this.sendRequest(async () => {
      const response = await this.axios.post('/employees/login', {
        phone,
        password
      })
      const { data } = response.data
      this.setUserData(data)
    })
  }

  logout() {
    window.localStorage.removeItem('user')
    store.dispatch(removeUserData())
  }
}
