import { useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { config } from 'config'
import { useDispatch } from 'react-redux'
import {
  setApp,
  setAnalytics,
  setMessaging
} from 'redux/reducers/firebaseReducer'
import { getMessaging, getToken } from 'firebase/messaging'
import { useHelper } from 'shared/hooks/useHelper'
import { DeviceHelper } from 'services/helpers/DeviceHelper'
import { NotifyHelper } from 'services/helpers/NotifyHelper'
/* eslint-disable */

const app = initializeApp(config.firebase)
const analytics = getAnalytics(app)
const messaging = getMessaging(app)

export const Firebase = ({ children }) => {
  const notifyHelper = useHelper(NotifyHelper)
  const deviceHelper = useHelper(DeviceHelper)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setApp(app))
    dispatch(setAnalytics(analytics))
    dispatch(setMessaging(messaging))
  }, [dispatch])

  // Disable in App notifications cause background notifications should work
  // useEffect(() => {
  //   const unsub = notifyHelper.onMessage((payload) => {
  //     const url = `https://delivery.blyzenko.com.ua/admin/order/${payload.data.orderId}`

  //     const notificationTitle = payload.notification.title

  //     const notificationOptions = {
  //       body: payload.notification.body,
  //       data: payload.data,
  //       onClick: () => window.open(url)
  //     }
  //     notifyHelper.showNotification(notificationTitle, notificationOptions)
  //   })
  //   return unsub
  // }, [])

  useEffect(() => {
    getToken(messaging, {
      vapidKey: config.firebase.vapidKey
    })
      .then(async (currentToken) => {
        if (currentToken) {
          await deviceHelper.create(currentToken)
          notifyHelper.initHandlers()
        } else {
          console.error('Registration token unavailable')
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token:', err)
      })
  }, [])

  return children
}
