import { AbstractRequest } from 'services/abstract/AbstractRequest'
import { EmployeesResolver } from 'services/resolvers/EmployeesResolver'
import { Limits } from 'shared/enums'

export class EmployeesApiService extends AbstractRequest {
  constructor() {
    super(`/employees`)
    this.employeesResolver = new EmployeesResolver()
  }

  async getEmployees({ skip, filter }) {
    const { data, status } = await this.get(this.baseUrl, {
      params: {
        skip: skip,
        limit: filter ? Number.MAX_SAFE_INTEGER : Limits.EmployeesLimit,
        filter: filter && JSON.stringify(filter)
      }
    })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(
      this.employeesResolver.normalizeEmployees(data.data)
    )
  }

  async updateEmployeeStatus(employeeId, employeeStatus) {
    const { data, status } = await this.patch(
      `${this.baseUrl}/${employeeId}/status`,
      {
        status: employeeStatus
      }
    )
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async getCountEmployees() {
    const { data, status } = await this.get(`${this.baseUrl}/count`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }
}
