import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class StoreApiService extends AbstractRequest {
  constructor() {
    super('/stores')
  }

  async getStore(skip, limit, shopNumber) {
    const { data, status } = await this.get(
      `${this.baseUrl}/number/${shopNumber}`,
      {
        params: { skip, limit }
      }
    )
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async getStores({ skip, limit }) {
    const params = {
      params: { skip, limit }
    }
    const { data, status } = await this.get(`${this.baseUrl}/`, params)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async countStores() {
    const { data, status } = await this.get(`${this.baseUrl}/count`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async putStoresSettings({ settings, storeId }) {
    const { data, status } = await this.put(
      `${this.baseUrl}/${storeId}/settings`,
      settings
    )
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async getPromos() {
    const { data, status } = await this.get(`${this.baseUrl}/promotions`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }
}
