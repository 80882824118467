import React, { useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import CustomTabs from 'components/CustomTabs/CustomTabs'

import SpellcheckIcon from '@material-ui/icons/Spellcheck'

import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import { CreatedBanners } from './CreatedBanners'
import { BannersCreateWidget } from 'widgets/BannersCreateWidget'
import { useHelper } from 'shared/hooks/useHelper'
import { StoreHelper } from 'services/helpers/StoreHelper'

export const Banners = () => {
  const storeHelper = useHelper(StoreHelper)

  useEffect(() => {
    storeHelper.setPromos()
  }, [storeHelper])

  return (
    <GridContainer>
      <CustomTabs
        headerColor="success"
        tabs={[
          {
            tabName: 'Створити баннер',
            tabIcon: ViewCarouselIcon,
            tabContent: <BannersCreateWidget />
          },
          {
            tabName: 'Створені баннери',
            tabIcon: SpellcheckIcon,
            tabContent: <CreatedBanners />
          }
        ]}
      />
    </GridContainer>
  )
}
