import { setLoading } from 'redux/reducers/appReducer'
import { setNonImageProducts } from 'redux/reducers/productsReducer'
import { replaceStore } from 'redux/reducers/storeReducer'
import { ProductApiService } from 'services/api/ProductApiService'
import { Limits } from 'shared/enums'
import { StatefullService } from './StatefullService'

export class ProductHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.productApiService = new ProductApiService()
  }

  static make() {
    if (!ProductHelper.instance) {
      ProductHelper.instance = new ProductHelper()
    }
    return ProductHelper.instance
  }

  async getAvailableProducts(skip, limit, storeId) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.productApiService.getAvailableProducts.bind(this.productApiService),
      setLoading,
      { skip, limit, storeId }
    )
    if (isSuccess) {
      return data
    }
    return null
  }

  async getShopProducts(shop) {
    const products = await this.getAvailableProducts(
      0,
      Number.MAX_SAFE_INTEGER,
      shop.uuid
    )
    return products
  }

  async setProductsToShop(shop, isNext) {
    const store = { ...shop }
    store.productsSkip = store.productsSkip || 0
    if (!isNext) {
      store.productsSkip -= Limits.ProductsLimit * 2
      store.productsSkip = store.productsSkip > 0 ? store.productsSkip : 0
    }

    const products = await this.getShopProducts(store)
    store.productsSkip += Limits.ProductsLimit
    store.products = products
    this._dispatch(replaceStore(store))
    return store
  }

  async nonImageProducts() {
    const { isSuccess, data } = await this._callApi(
      this.productApiService.getNonImageProducts.bind(this.productApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setNonImageProducts(data))
      return data
    }
    return null
  }
}
