import { StatusCodes } from 'http-status-codes'
import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class NotifyApiHelper extends AbstractRequest {
  constructor() {
    super(`/notifications`)
  }

  async _sendNotificationToDevice(endpoint, deviceId, body) {
    const { data, status } = await this.post(endpoint, {
      deviceId,
      data: body
    })

    if (status === StatusCodes.NOT_FOUND) return this._returnSuccess(true)
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async sendOrderCancel(deviceId, body) {
    return await this._sendNotificationToDevice(
      `${this.baseUrl}/order/cancel`,
      deviceId,
      body
    )
  }

  async sendOrderStatus(deviceId, body) {
    return await this._sendNotificationToDevice(
      `${this.baseUrl}/order/status`,
      deviceId,
      body
    )
  }
}
