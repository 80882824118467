import React, { useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import CustomTabs from 'components/CustomTabs/CustomTabs'
import GroupIcon from '@material-ui/icons/Group'
import BlockIcon from '@material-ui/icons/Block'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import { makeStyles } from '@material-ui/core/styles'
import ClientsModal from 'components/Modals/ClientsModal'
import { useSelector } from 'react-redux'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { useHelper } from 'shared/hooks/useHelper'
import { ClientHelper } from 'services/helpers/ClientHelper'
import { FilterHelper } from 'services/helpers/FilterHelper'
import { format } from 'date-fns'

const roles = {
  customer: 'Клієнт'
}
const columns = [
  { field: 'phone', headerName: 'Телефон', width: 200 },
  { field: 'email', headerName: 'Пошта', width: 150 },
  { field: 'name', headerName: "Ім'я", width: 150 },
  {
    field: 'uuid',
    headerName: 'Id',
    width: 300
  },
  {
    field: 'role',
    headerName: 'Статус',
    width: 150,
    renderCell: (params) => {
      return roles[params.value]
    }
  },
  {
    field: 'birth',
    headerName: 'Дата народження',
    type: 'dateTime',
    width: 200,
    renderCell: (params) => {
      return params.value
        ? format(new Date(params.value), 'dd.MM.yyyy')
        : 'Не вказано'
    }
  },
  {
    field: 'icons',
    headerName: 'Опції',
    width: 150,
    renderCell: (params) => {
      return <ClientsModal {...params} />
    }
  }
]

const styles = {
  textCenter: {
    textAlign: 'center'
  }
}

const useStyles = makeStyles(styles)

const Tab = ({
  className,
  clients,
  loading,
  total,
  onPageChange,
  onFilterChange
}) => {
  return (
    <div className={className}>
      <DataGrid
        onFilterModelChange={onFilterChange}
        onPageChange={onPageChange}
        rows={clients}
        columns={columns}
        pagination
        {...clients}
        paginationMode="client"
        pageSize={10}
        autoHeight={true}
        loading={loading}
        disableSelectionOnClick={true}
        rowCount={total}
        checkboxSelection={false}
        autoPageSize={false}
        getRowId={(item) => item.uuid}
        localeText={rowsTableTranslate}
      />
    </div>
  )
}
export default function Clients() {
  const clientsState = useSelector((state) => state.clients)
  const classes = useStyles()
  const clientHelper = useHelper(ClientHelper)
  const filterHelper = useHelper(FilterHelper)
  const activeClients = clientsState.clients.filter(({ isBanned }) => !isBanned)
  /* eslint-disable */
  useEffect(() => {
    clientHelper.setTotal()
    clientHelper.addClients({
      skip: clientsState.skip
    })
  }, [])
  /* eslint-enable */

  const onFilterChange = (filterModel) => {
    clientHelper.addClients({
      skip: clientsState.skip,
      filter: filterHelper.setFilterData(
        filterModel.items[0]?.columnField,
        filterModel.items[0]?.value
      )
    })
  }

  const handlePageClientsSelect = () => {
    if (clientsState.skip < clientsState.total && !clientsState.loading) {
      clientHelper.addClients({
        skip: clientsState.skip
      })
    }
  }

  const handlePageBannedSelect = () => {
    if (
      clientsState.banned.skip < clientsState.banned.total &&
      !clientsState.banned.loading
    ) {
      clientHelper.addBanned(clientsState.banned.skip)
    }
  }
  const handlePageVipSelect = () => {
    if (
      clientsState.vip.skip < clientsState.vip.total &&
      !clientsState.loading
    ) {
      clientHelper.addClients(clientsState.vip.skip)
    }
  }

  const hangleTabChange = (index) => {
    if (clientsState.loading) return
    if (index === 1 && clientsState.banned.skip < clientsState.banned.total) {
      clientHelper.addBanned(clientsState.banned.skip)
      clientHelper.setBannedTotal()
    }

    if (index === 2 && clientsState.vip.skip < clientsState.vip.total) {
      clientHelper.addVip(clientsState.vip.skip)
      clientHelper.setVipTotal()
    }
  }

  if (!clientsState.clients.length) return null
  return (
    <div style={{ width: '100%' }}>
      <CustomTabs
        onChange={hangleTabChange}
        headerColor="success"
        tabs={[
          {
            tabName: 'Користувачі',
            tabIcon: GroupIcon,
            tabContent: (
              <Tab
                onFilterChange={onFilterChange}
                className={classes.textCenter}
                clients={activeClients}
                loading={clientsState.loading}
                total={clientsState.total}
                onPageChange={handlePageClientsSelect}
              />
            )
          },
          {
            tabName: 'Заблоковані користувачі',
            tabIcon: BlockIcon,
            tabContent: (
              <Tab
                onFilterChange={onFilterChange}
                className={classes.textCenter}
                clients={clientsState.banned.clients}
                loading={clientsState.loading}
                total={clientsState.banned.total}
                onPageChange={handlePageBannedSelect}
              />
            )
          },
          {
            tabName: 'VIP користувачі',
            tabIcon: HowToRegIcon,
            tabContent: (
              <Tab
                onFilterChange={onFilterChange}
                className={classes.textCenter}
                clients={clientsState.vip.clients}
                loading={clientsState.loading}
                total={clientsState.vip.total}
                onPageChange={handlePageVipSelect}
              />
            )
          }
        ]}
      />
    </div>
  )
}
