import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  groups: {
    data: [],
    skip: 0
  },
  promos: [],
  loading: false
}

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    setPromos: (state, action) => {
      state.promos = action.payload
    },
    setGroups(state, action) {
      state.groups.data = action.payload
    },
    addGroup(state, action) {
      state.groups.data = state.groups.data.concat(action.payload)
    },
    deleteGroup(state, action) {
      state.groups.data = state.groups.data.filter(
        ({ uuid }) => uuid !== action.payload.uuid
      )
    },
    addBanner(state, action) {
      const targetGroupIndex = state.groups.data.findIndex(
        ({ uuid }) => uuid === action.payload.bannerGroupId
      )
      if (targetGroupIndex >= 0) {
        state.groups.data[targetGroupIndex].banners = state.groups.data[
          targetGroupIndex
        ].banners?.concat(action.payload)
      }
    },
    deleteBanner(state, action) {
      const targetGroupIndex = state.groups.data.findIndex(
        ({ uuid }) => uuid === action.payload.bannerGroupId
      )
      if (targetGroupIndex >= 0) {
        state.groups.data[targetGroupIndex].banners = state.groups.data[
          targetGroupIndex
        ].banners.filter(({ uuid }) => uuid !== action.payload.uuid)
      }
    }
  }
})

const { reducer, actions } = bannersSlice
export default reducer
export const {
  setPromos,
  setGroups,
  addGroup,
  deleteGroup,
  addBanner,
  deleteBanner
} = actions
