import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  skip: 0,
  total: 0,
  loading: false,
  employees: []
}

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setEmployeesSuccess(state, action) {
      state.employees = action.payload
      state.skip = action.payload.length
      state.loading = false
    },
    addEmployeesSuccess(state, action) {
      state.employees = state.employees.concat(action.payload)
      state.skip += action.payload.length
      state.loading = false
    },
    addEmployeesRequest(state) {
      state.loading = true
    },
    addEmployeesFailure(state) {
      state.loading = false
    },
    setTotal(state, action) {
      state.total = action.payload
    }
  }
})

const { reducer, actions } = employeesSlice
export default reducer
export const {
  addEmployeesSuccess,
  addEmployeesRequest,
  addEmployeesFailure,
  setTotal,
  setLoading,
  setEmployeesSuccess
} = actions
