import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import styles from 'assets/jss/material-dashboard-react/components/footerStyle.js'

const useStyles = makeStyles(styles)

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="#home" className={classes.block}>
                Головна
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#company" className={classes.block}>
                Політика конфіденційності
              </a>
            </ListItem>

            <ListItem className={classes.inlineBlock}>
              <a href="#blog" className={classes.block}>
                Користувацьке погодження
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          <span>
            &copy; 2021 <div className={classes.a}>Близенько</div>
          </span>
        </div>
      </div>
    </footer>
  )
}
