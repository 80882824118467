import { ProductModal } from 'components/Modals/ProductModal'
import React from 'react'

export const columns = [
  {
    field: 'productId',
    type: 'number',
    headerName: 'Код Товару',
    width: 150
  },
  {
    field: 'name',
    headerName: 'Назва товару',
    width: 300
  },
  {
    field: 'description',
    headerName: 'Фото товару',
    width: 150,
    renderCell: (params) => {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            paddingTop: 15
          }}
        >
          {params.row.productId && (
            <picture>
              <source
                srcSet={`https://delivery.blyzenko.com.ua/assets/products/${params.row.productId}.png`}
              />
              <img
                alt="noImg"
                src="https://portexland.ru/image/default_blog.png"
                style={{
                  maxWidth: '60%',
                  maxHeight: 70
                }}
              />
            </picture>
          )}
        </div>
      )
    }
  },
  { field: 'categoryId', type: 'number', headerName: 'Категорї', width: 150 },

  { field: 'country', headerName: 'Підкатегорії', width: 150 },
  { field: 'familyId', type: 'number', headerName: 'Сімейство', width: 150 },
  { field: 'groupId', type: 'number', headerName: 'Група', width: 150 },
  {
    field: 'regularPrice',
    type: 'number',
    headerName: 'Ціна',
    width: 150,
    renderCell: (params) => {
      return params.row.discountPrice || params.row.regularPrice
    }
  },
  {
    field: 'availableQuantity',
    type: 'number',
    headerName: 'Залишки',
    width: 150
  },
  {
    field: 'measurementUnit',
    type: 'number',
    headerName: 'Одиниці',
    width: 130
  },
  {
    field: 'buttons',
    headerName: ' Опції',
    width: 100,
    renderCell: (params) => {
      return (
        <div>
          <ProductModal {...params} />
        </div>
      )
    }
  }
]
