import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TimesUtils } from 'services/utils/TimesUtils'

export const Timer = ({ statusTime, styles }) => {
  const [countdown, setCountDown] = useState(null)
  let timestamp = null

  useEffect(() => {
    if (statusTime?.start && statusTime?.end) {
      setCountDown(
        TimesUtils.getFormatDistance(statusTime.start, statusTime.end)
      )
      return () => clearInterval(timestamp)
    }
    if (statusTime?.start && !statusTime?.end) {
      timestamp = setInterval(() => {
        setCountDown(TimesUtils.getTimer(statusTime.start))
      }, 1000)
    } else {
      setCountDown(0)
    }
  }, [statusTime])

  return <Typography style={styles.valueText}>{countdown}</Typography>
}
