import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  act: null,
  acts: []
}

const actsSlice = createSlice({
  name: 'returnActs',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setActs(state, action) {
      state.acts = action.payload
    },
    setAct(state, action) {
      state.act = action.payload
    }
  }
})

const { reducer, actions } = actsSlice
export default reducer
export const { setLoading, setActs, setAct } = actions
