import { setSettings } from 'redux/reducers/settingsReducer'
import { StatefullService } from './StatefullService'
import { SettingsApiService } from 'services/api/SettingsApiService'
import { setLoading } from 'redux/reducers/appReducer'

export class SettingsHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.settingsApiService = new SettingsApiService()
  }

  static make() {
    if (!SettingsHelper.instance) {
      SettingsHelper.instance = new SettingsHelper()
    }
    return SettingsHelper.instance
  }

  async putSettings(settings) {
    const { isSuccess, data } = await this._callApi(
      this.settingsApiService.putSettings.bind(this.settingsApiService),
      setLoading,
      settings
    )

    if (isSuccess) {
      this._dispatch(setSettings(data))
      return data
    }
    return null
  }

  async setSettings() {
    const { isSuccess, data } = await this._callApi(
      this.settingsApiService.getSettings.bind(this.settingsApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setSettings(data))
      return data
    }
    return null
  }
}
