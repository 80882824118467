import { combineReducers } from 'redux'
import bonusReducer from './reducers/bonusReducer'
import storeReducer from './reducers/storeReducer'
import clientsReducer from './reducers/clientsReducer'
import employeesReducer from './reducers/employeesReducer'
import userReducer from './reducers/userReducer'
import brandsReducer from './reducers/brandsReducer'
import ordersReducer from './reducers/ordersReducer'
import syncReducer from './reducers/syncReducer'
import settingsReducer from './reducers/settingsReducer'
import BannersReducer from './reducers/BannersReducer'
import appReducer from './reducers/appReducer'
import feedbacksReducer from './reducers/feedbacksReducer'
import returnActReducer from './reducers/returnActReducer'
import staticPagesReducer from './reducers/staticPagesReducer'
import firebaseReducer from './reducers/firebaseReducer'
import gatewayReducer from './reducers/gatewayReducer'
import productsReducer from './reducers/productsReducer'
import groupsReducer from './reducers/goupsReducer'

export const rootCombine = combineReducers({
  stores: storeReducer,
  bonuses: bonusReducer,
  clients: clientsReducer,
  employees: employeesReducer,
  user: userReducer,
  brands: brandsReducer,
  orders: ordersReducer,
  sync: syncReducer,
  settings: settingsReducer,
  banners: BannersReducer,
  app: appReducer,
  feedbacks: feedbacksReducer,
  returnActs: returnActReducer,
  staticPages: staticPagesReducer,
  firebase: firebaseReducer,
  gateway: gatewayReducer,
  products: productsReducer,
  groups: groupsReducer
})
