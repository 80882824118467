import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  settings: null,
  loading: false
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setSettings(state, action) {
      state.settings = action.payload
    }
  }
})

const { reducer, actions } = settingsSlice
export default reducer
export const { setLoading, setSettings } = actions
