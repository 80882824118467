import React from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { overdueColumns } from './overdueColumns'
import { overdueFixtures } from './overdueFixtures'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'

function OverdueStatuses() {
  return (
    <DataGrid
      rows={overdueFixtures}
      columns={overdueColumns}
      pagination
      paginationMode="client"
      pageSize={10}
      autoHeight={true}
      disableSelectionOnClick={true}
      rowCount={10}
      checkboxSelection={false}
      autoPageSize={false}
      getRowId={(item) => item.uuid}
      localeText={rowsTableTranslate}
    />
  )
}

export default OverdueStatuses
