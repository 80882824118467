import React from 'react'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { brands } from './brandFixture'
import { DataGrid } from '@material-ui/data-grid'
import { columnsBrand } from './columnsBrand'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'

function Brands() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <DataGrid
          rows={brands}
          columns={columnsBrand}
          paginationMode="client"
          pageSize={10}
          autoHeight={true}
          disableSelectionOnClick={true}
          checkboxSelection={false}
          autoPageSize={false}
          getRowId={(item) => item.brandId}
          localeText={rowsTableTranslate}
        />
      </GridItem>
    </GridContainer>
  )
}

export default Brands
