export const orderFixtures = [
  {
    id: 1,
    storeId: 1,
    courier: 'Петрівський Іван Михайлович',
    name: 'Василішин Борис Петрович',
    uuid: '2241erq55124',
    role: 'VIP',
    summ: '552.52 ₴',
    dateTime: new Date(),
    qualityControl: 5,
    userPhone: '+380681234567'
  },
  {
    id: 2,
    storeId: 4,
    courier: 'Петрівський Іван Михайлович',
    name: 'Василішин Борис Петрович',
    uuid: '2241erq55124',
    role: 'Користувач',
    summ: '552.52 ₴',
    dateTime: new Date(),
    qualityControl: 5,
    userPhone: '+380681234567'
  }
]
