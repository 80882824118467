import { setLoading } from 'redux/reducers/appReducer'
import {
  setStores,
  setTotal,
  setStoreSettings
} from 'redux/reducers/storeReducer'
import { StoreApiService } from 'services/api/StoreApiService'
import { StatefullService } from './StatefullService'
import { setPromos } from 'redux/reducers/BannersReducer'

export class StoreHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.storeApiService = new StoreApiService()
  }

  static make() {
    if (!StoreHelper.instance) {
      StoreHelper.instance = new StoreHelper()
    }
    return StoreHelper.instance
  }

  async setStores(skip, limit) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.storeApiService.getStores.bind(this.storeApiService),
      setLoading,
      { skip, limit }
    )
    if (isSuccess) {
      this._dispatch(setStores(data))
      return data
    }
    return null
  }

  async countStores() {
    const { isSuccess, data } = await this._callApi(
      this.storeApiService.countStores.bind(this.storeApiService)
    )
    if (isSuccess) {
      this._dispatch(setTotal(data))
      return data
    }
    return null
  }

  async putStoresSettings({ settings, storeId }) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.storeApiService.putStoresSettings.bind(this.storeApiService),
      setLoading,
      { settings, storeId }
    )
    if (isSuccess) {
      this._dispatch(setStoreSettings({ settings: data.settings, storeId }))
      return data
    }
    return null
  }

  async setPromos() {
    const { isSuccess, data } = await this._callApi(
      this.storeApiService.getPromos.bind(this.storeApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setPromos(data))
      return data
    }
    return null
  }
}
