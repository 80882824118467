import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  app: null,
  analytics: null,
  messaging: null
}

const firebaseSlice = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    setApp(state, action) {
      state.app = action.payload
    },
    setAnalytics(state, action) {
      state.analytics = action.payload
    },
    setMessaging(state, action) {
      state.messaging = action.payload
    }
  }
})

const { reducer, actions } = firebaseSlice
export default reducer
export const { setApp, setAnalytics, setMessaging } = actions
