import {
  updateOrder,
  setOrdersTotal,
  setOrders,
  addOrders,
  addToOrders,
  addToDiscarded,
  setDiscardedOrders,
  addDiscardedOrders,
  setDiscardedOrdersTotal
} from 'redux/reducers/ordersReducer'

import { StatefullService } from './StatefullService'
import { OrderApiService } from 'services/api/OrderApiService'
import { OrdersResolver } from 'services/resolvers/OrdersResolver'
import { SortUtils } from 'services/utils/SortUtils'
import { setLoading } from 'redux/reducers/appReducer'
import { TransformUtils } from 'services/utils/TransformUtils'
import produce from 'immer'
import { OrderStatus } from 'helpers/enums'
import { ExpireOrdersResolver } from 'services/resolvers/ExpireOrdersResolver'

export const activeOrderStatuses = [
  OrderStatus.Created,
  OrderStatus.Waiting,
  OrderStatus.Pickup,
  OrderStatus.Deliver,
  OrderStatus.Return,
  OrderStatus.Check,
  OrderStatus.Completed,
  OrderStatus.Self,
  OrderStatus.Pickuped,
  OrderStatus.Delivered,
  OrderStatus.Checked,
  OrderStatus.Replacing
]

export const discardedOrderStatuses = [
  OrderStatus.Canceled,
  OrderStatus.Discarded
]

export class OrderHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.ordersResolver = new OrdersResolver()
    this.ordersApiService = new OrderApiService()
    this.expireOrdersResolver = new ExpireOrdersResolver()
  }

  static make() {
    if (!OrderHelper.instance) {
      OrderHelper.instance = new OrderHelper()
    }
    return OrderHelper.instance
  }

  async getOrderById(orderId) {
    const { isSuccess, data } = await this._callApi(
      this.ordersApiService.getOrderById.bind(this.ordersApiService),
      setLoading,
      orderId
    )
    return isSuccess ? data : null
  }

  async setDiscardedOrders(filters) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.ordersApiService.getOrders.bind(this.ordersApiService),
      setLoading,
      { filters }
    )
    if (isSuccess) {
      this._dispatch(setDiscardedOrders(SortUtils.sortByDate(data)))
      return data
    }
    return null
  }

  async addDiscardedOrders(skip, filters) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.ordersApiService.getOrders.bind(this.ordersApiService),
      setLoading,
      { skip, filters }
    )
    if (isSuccess) {
      this._dispatch(addDiscardedOrders(SortUtils.sortByDate(data)))
      return data
    }
    return null
  }

  async setDiscardedOrdersTotal(filters) {
    const { isSuccess, data } = await this._callApi(
      this.ordersApiService.getOrdersCount.bind(this.ordersApiService),
      setLoading,
      filters
    )
    if (isSuccess) {
      this._dispatch(setDiscardedOrdersTotal(data))
      return data
    }
    return null
  }

  async setOrders(filters) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.ordersApiService.getOrders.bind(this.ordersApiService),
      setLoading,
      { filters }
    )
    if (isSuccess) {
      const ordersWithExpire = this.addCurentExpireToOrders(data)
      this._dispatch(setOrders(SortUtils.sortByDate(ordersWithExpire)))
      return data
    }
    return null
  }

  async setExpiredOrders() {
    const { isSuccess, data } = await this._callApi(
      this.ordersApiService.getExpiredOrders.bind(this.ordersApiService),
      setLoading
    )
    if (isSuccess) {
      const ordersWithExpire = this.addCurentExpireToOrders(data)
      this._dispatch(setOrders(SortUtils.sortByDate(ordersWithExpire)))
      return data
    }
    return null
  }

  getOrdersWithNum(data) {
    return produce(data, (orders) => {
      orders.forEach((order) => {
        order.orderNum = TransformUtils.orderToOrderNum(order)
      })

      return orders
    })
  }

  async addOrders(filters, skip) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.ordersApiService.getOrders.bind(this.ordersApiService),
      setLoading,
      { skip, filters }
    )
    if (isSuccess) {
      const ordersWithExpire = this.addCurentExpireToOrders(data)
      this._dispatch(addOrders(SortUtils.sortByDate(ordersWithExpire)))
      return data
    }
    return null
  }

  async setOrdersTotal(filters) {
    const { isSuccess, data } = await this._callApi(
      this.ordersApiService.getOrdersCount.bind(this.ordersApiService),
      setLoading,
      filters
    )
    if (isSuccess) {
      this._dispatch(setOrdersTotal(data))
      return data
    }
    return null
  }

  async setStatusOrder({ orderId, status }) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.ordersApiService.setStatusOrder.bind(this.ordersApiService),
      setLoading,
      { orderId, status }
    )
    if (isSuccess) {
      if (data.status === OrderStatus.Discarded) {
        this._dispatch(addToDiscarded(data))
      } else {
        this._dispatch(updateOrder(data))
      }

      return data
    }

    return null
  }

  async addToDiscarded(order, filter) {
    const isSuccess = await this.setDiscardedOrdersTotal(filter)
    if (isSuccess) {
      this._dispatch(addToDiscarded(order))
    }
    return null
  }

  async addToOrders(order, filter) {
    const isSuccess = await this.setOrdersTotal(filter)
    if (isSuccess) {
      this._dispatch(addToOrders(order))
    }
    return null
  }

  activeOrders(data) {
    return this.ordersResolver.activeOrders(data)
  }

  canceledOrders(data) {
    return this.ordersResolver.canceledOrders(data)
  }

  getAllExpires(order) {
    const expires = this.expireOrdersResolver.getAllExpires(order)
    const normalizedExpires =
      expires?.length &&
      expires.map((item) => {
        return {
          ...item,
          ...this.expireOrdersResolver.normalizeTime(order, item, expires)
        }
      })

    return normalizedExpires || []
  }

  getCurrentExpire(order) {
    return this.expireOrdersResolver.getCurrentExpire(order)
  }

  addCurentExpireToOrders(data) {
    return produce(data, (orders) => {
      orders.forEach((order) => {
        const expires = this.getAllExpires(order)
        if (expires.length) {
          order.currentExpire = {
            ...this.expireOrdersResolver.getCurrentExpire(order),
            timestamp: this.expireOrdersResolver.normalizeTime(order)
          }
        }
      })

      return orders
    })
  }
}
