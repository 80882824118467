import { setLoading } from 'redux/reducers/appReducer'
import {
  setPages,
  updatePage,
  removePage,
  addPage
} from 'redux/reducers/staticPagesReducer'

import { StaticPagesApiService } from 'services/api/StaticPagesApiService'
import { StatefullService } from './StatefullService'

export class StaticPageHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.staticPagesApiService = new StaticPagesApiService()
  }

  static make() {
    if (!StaticPageHelper.instance) {
      StaticPageHelper.instance = new StaticPageHelper()
    }
    return StaticPageHelper.instance
  }

  async setStaticPages() {
    const { isSuccess, data } = await this._callApi(
      this.staticPagesApiService.getStaticPages.bind(
        this.staticPagesApiService
      ),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setPages(data))
      return data
    }
    return null
  }

  async removeStaticPage(id) {
    const { isSuccess, data } = await this._callApi(
      this.staticPagesApiService.removeStaticPage.bind(
        this.staticPagesApiService
      ),
      setLoading,
      id
    )
    if (isSuccess) {
      this._dispatch(removePage(data.uuid))
      return data
    }
    return null
  }

  async createStaticPage(incomingData) {
    const { isSuccess, data } = await this._callApi(
      this.staticPagesApiService.putStaticPage.bind(this.staticPagesApiService),
      setLoading,
      incomingData
    )
    if (isSuccess) {
      this._dispatch(addPage(data))
      return data
    }
    return null
  }

  async updateStaticPage(incomingData) {
    const { isSuccess, data } = await this._callApi(
      this.staticPagesApiService.putStaticPage.bind(this.staticPagesApiService),
      setLoading,
      incomingData
    )
    if (isSuccess) {
      this._dispatch(updatePage(data))
      return data
    }
    return null
  }
}
