import React, { useEffect } from 'react'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import FeedbackItem from './FeedbackItem'
import { useSelector } from 'react-redux'
import Spiner from 'components/Spiner/Spiner'
import { useHelper } from 'shared/hooks/useHelper'
import { FeedbackHelper } from 'services/helpers/FeedbackHelper'

export default function Feedbacks() {
  const feedbackHelper = useHelper(FeedbackHelper)
  const skip = useSelector((state) => state.feedbacks.skip)
  const feedbacks = useSelector((state) => state.feedbacks.feedbacks)
  const loading = useSelector((state) => state.feedbacks.loading)

  useEffect(() => {
    feedbackHelper.setFeedbacks(skip)
  }, [feedbackHelper])

  if (loading) {
    return <Spiner />
  }

  if (!feedbacks.length) {
    return <div>Помилка</div>
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {feedbacks.map((item) => {
          return <FeedbackItem key={item.uuid} item={item} />
        })}
      </GridItem>
    </GridContainer>
  )
}
