import React, { useState } from 'react'

import { createMuiTheme } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { successColor } from 'assets/jss/material-dashboard-react'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import { useHelper } from 'shared/hooks/useHelper'
import { StoreHelper } from 'services/helpers/StoreHelper'
import { uk } from 'date-fns/esm/locale'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    display: 'flex',
    flexDirection: 'row'
  },
  buttons: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 20,
    paddingLeft: 20
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row'
  },
  '@global': {
    '.MuiAccordionSummary-expandIcon': {
      color: successColor[0]
    },
    '.MuiTextField-root': {
      borderBottomColor: successColor[0],
      width: '25ch'
    }
  },
  blockPadding: {
    paddingLeft: 20
  },
  rowPadding: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: 150
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      main: successColor[0]
    },
    secondary: {
      main: successColor[0]
    }
  }
})

const StoresSettingsItem = ({ item }) => {
  const classes = useStyles()
  const [stopStartDate, setStopStartDate] = useState(
    item.settings.stopStartDate
  )

  const [stopEndDate, setStopEndDate] = useState(item.settings.stopEndDate)
  const [expanded, setExpanded] = useState(false)
  const [maxWeight, setMaxWeight] = useState(item.settings.maxWeight)
  const [deliveryRadius, setDeliveryRadius] = useState(
    item.settings.deliveryRadius
  )
  const [selfPickaping, setSelfPickaping] = useState(
    item.settings.selfPickaping
  )
  const storesHelper = useHelper(StoreHelper)

  const handleCheckbox = (event) => {
    setSelfPickaping(event.target.checked)
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded && panel)
  }

  const handleReset = () => {
    setSelfPickaping(item.settings.selfPickaping)
    setStopStartDate(item.settings.stopStartDate)
    setStopEndDate(item.settings.stopEndDate)
    setMaxWeight(item.settings.maxWeight)
    setDeliveryRadius(item.settings.deliveryRadius)
  }

  const handleSuccess = (storeId) => {
    if (storeId) {
      storesHelper.putStoresSettings({
        settings: {
          selfPickaping,
          deliveryRadius: Number(deliveryRadius),
          maxWeight: Number(maxWeight),
          stopStartDate,
          stopEndDate
        },
        storeId
      })
    }
  }

  return (
    <GridContainer>
      <Accordion
        style={{
          width: '100%'
        }}
        key={item.uuid}
        expanded={expanded === item.uuid}
        onChange={handleChange(item.uuid)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id={item.uuid}
        >
          <div className={classes.heading}>
            <Typography>Mагазин №</Typography>

            <Typography
              style={{
                color: successColor[0],
                fontWeight: '500',
                paddingLeft: 5
              }}
            >
              {item.shopNumber}
            </Typography>
            <Typography className={classes.blockPadding}>Адреса: </Typography>
            <Typography
              style={{
                color: successColor[0],
                fontWeight: '500',
                paddingLeft: 5
              }}
            >
              {item.address}
            </Typography>
          </div>

          <div className={classes.secondaryHeading}>
            <Typography>Максимальна вага: </Typography>
            <Typography
              style={{
                color: successColor[0],
                fontWeight: '500',
                paddingLeft: 5
              }}
            >
              {item.settings.maxWeight}кг
            </Typography>

            <Typography className={classes.rowPadding}>Самовивіз:</Typography>
            <Typography
              style={{
                color: successColor[0],
                fontWeight: '500',
                paddingLeft: 5
              }}
            >
              {item.settings.selfPickaping ? 'Так' : 'ні'}
            </Typography>

            <Typography className={classes.rowPadding}>
              Радіус доставки:{' '}
            </Typography>
            <Typography
              style={{
                color: successColor[0],
                fontWeight: '500',
                paddingLeft: 5
              }}
            >
              {item.settings.deliveryRadius}м
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          <div className={classes.rowDirection}>
            <Typography>Самовивіз</Typography>
            <Checkbox
              checked={selfPickaping}
              onChange={handleCheckbox}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div>
            <TextField
              className={classes.root}
              id={item.uuid}
              label="Змінити радіус"
              type="number"
              defaultValue={deliveryRadius}
              onChange={(e) => setDeliveryRadius(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">м</InputAdornment>
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div>
            <TextField
              className={classes.root}
              id={item.uuid}
              label="Обреріть максимальну вагу"
              type="number"
              defaultValue={maxWeight}
              onChange={(e) => setMaxWeight(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">Кг</InputAdornment>
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uk}>
              <ThemeProvider theme={theme}>
                <DateTimePicker
                  autoOk
                  ampm={false}
                  value={stopStartDate}
                  onChange={setStopStartDate}
                  format="d MMM yyyy"
                  label="Призупинити роботу з"
                  emptyLabel="Оберіть"
                  showTodayButton={true}
                  minDateMessage="Дата не повинна бути раніше мінімальної дати"
                  cancelLabel="Скасувати"
                  clearLabel="Очистити"
                  todayLabel="Сьогодні"
                />
                <div style={{ paddingLeft: 10 }}>
                  <DateTimePicker
                    autoOk
                    ampm={false}
                    value={stopEndDate}
                    onChange={setStopEndDate}
                    format="d MMM yyyy"
                    label="Призупинити роботу до"
                    emptyLabel="Оберіть"
                    showTodayButton={true}
                    minDateMessage="Дата не повинна бути раніше мінімальної дати"
                    cancelLabel="Скасувати"
                    clearLabel="Очистити"
                    todayLabel="Сьогодні"
                  />
                </div>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div>
        </AccordionDetails>
        <div className={classes.buttons}>
          <Button onClick={handleReset}>Скасувати</Button>
          <Button color="success" onClick={() => handleSuccess(item.uuid)}>
            Зберегти
          </Button>
        </div>
      </Accordion>
    </GridContainer>
  )
}

export default StoresSettingsItem
