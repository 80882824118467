import Spiner from 'components/Spiner/Spiner'
import React from 'react'
import { Grid } from '@material-ui/core'
import ItemTable from './ItemTable'
import { useInfiniteScroll } from 'shared/hooks/useInfiniteScroll'

export default function TableListComp({
  data,
  title,
  subtitle,
  total,
  extractRows,
  extractId,
  columnsTable,
  onLoadMore,
  onRowSelected,
  // if paggination don't needed delete it
  // onPageSelected,
  onListToggle,
  isLoading
}) {
  const ref = useInfiniteScroll({
    isLoading,
    hasNext: data.length < total,
    dataLength: data.length,
    onLoadMore
  })

  return (
    <>
      {data.map((item, index) => {
        return (
          <ItemTable
            key={`${item.id || item.uuid}-${index}`}
            columnsTable={columnsTable}
            data={extractRows ? extractRows(item) : item}
            title={title(item)}
            subtitle={subtitle(item)}
            extractId={(item) => (extractId ? extractId(item) : item.id)}
            onListToggle={(status) => onListToggle(item, status)}
            // if paggination don't needed delete it
            // onPageSelected={(prev, next) => onPageSelected(item, prev, next)}
            onRowSelected={onRowSelected}
            isLoading={isLoading}
          />
        )
      })}
      <Grid container justifyContent="center" alignItems="center" ref={ref}>
        {isLoading && <Spiner />}
      </Grid>
    </>
  )
}
