import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { successColor } from 'assets/jss/material-dashboard-react'
import { Grid } from '@material-ui/core'
import { dangerColor } from 'assets/jss/material-dashboard-react'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  '@global': {
    '.MuiAccordionSummary-expandIcon': {
      color: successColor[0]
    }
  },
  root: {
    borderTopWidth: 1,
    borderTopColor: successColor[0]
  }
}))

function FeedbackItem({ item }) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const { name, content, createdAt, store, user, uuid } = item

  return (
    <>
      <Accordion
        key={uuid}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {user && (
            <Typography className={classes.heading}>
              {user.name} {user.lastName || ''}
            </Typography>
          )}
          <Typography className={classes.secondaryHeading}>
            <Grid container direction="row">
              <text>Подія:</text>{' '}
              <text
                style={{
                  color: name === 'Скарга' ? dangerColor[0] : successColor[0],
                  fontWeight: '500',
                  paddingRight: 10,
                  paddingLeft: 5
                }}
              >
                {name}
              </text>
              {store && (
                <>
                  <text>Магазин</text>
                  <text
                    style={{
                      color: successColor[0],
                      fontWeight: '500',
                      paddingLeft: 5,
                      paddingRight: 10
                    }}
                  >
                    {store.address}
                  </text>
                </>
              )}
              {user && (
                <>
                  <text>Телефон</text>{' '}
                  <text
                    style={{
                      color: successColor[0],
                      fontWeight: '500',
                      paddingRight: 10,
                      paddingLeft: 5
                    }}
                  >
                    {user.phone}
                  </text>
                </>
              )}
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          <Typography>{content}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default FeedbackItem
