export const EmployeesRoles = {
  Packer: 'Пакувальник',
  AdministratorVoz: 'Адміністратор ВОЗ',
  Courier: "Кур'єр"
}
export const Limits = Object.freeze({
  EmployeesLimit: 10,
  OrdersLimit: 10,
  ProductsLimit: 10,
  StoresLimit: 10,
  maxLimit: Number.MAX_SAFE_INTEGER
})

export const EmployeeStatus = Object.freeze({
  Free: 'free',
  Pickup: 'pickup',
  Unavailable: 'unavailable',
  Delivery: 'delivery'
})

export const ExsistStatuses = Object.freeze({
  PickupStartAt: 'pickupStartAt',
  PickupedAt: 'pickupedAt',
  DeliveryStartAt: 'deliveryStartAt',
  DeliveredAt: 'deliveredAt',
  ComplitedAt: 'complitedAt',
  FinalizedAt: 'finalizedAt'
})

export const SettingsDeadlines = Object.freeze({
  Ordering: 'ordering',
  Compliting: 'compliting',
  Delivering: 'delivering',
  Packing: 'packing',
  Pricing: 'pricing'
})
