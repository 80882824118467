import React from 'react'
import { TextField } from '@material-ui/core'

export const Search = ({ setData, shops }) => {

  const handleData = (e) => {
    const search = e.target.value
    if (!search.length) {
      setData(shops)
      return
    }
    setData(
      shops.filter(
        (store) => !!store.address.toLowerCase().match(search.toLowerCase())
      )
    )
  }

  return (
    <TextField
      id="search"
      label="Адреса магазину"
      variant="standard"
      onChange={handleData}
    />
  )
}
