import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pages: []
}

const staticPagesSlice = createSlice({
  name: 'staticPages',
  initialState,
  reducers: {
    setPages(state, action) {
      state.pages = action.payload
    },
    addPage(state, action) {
      state.pages = state.pages.concat(action.payload)
    },
    removePage(state, action) {
      state.pages = state.pages.filter(({ uuid }) => uuid !== action.payload)
    },
    updatePage(state, action) {
      const index = state.pages.findIndex(
        ({ uuid }) => uuid === action.payload.uuid
      )
      if (index >= 0) {
        state.pages[index] = action.payload
      }
    }
  }
})

const { reducer, actions } = staticPagesSlice
export default reducer
export const { setPages, addPage, removePage, updatePage } = actions
