import { setLoading } from 'redux/reducers/appReducer'
import { DevicesApiService } from 'services/api/DevicesApiService'
import { StatefullService } from './StatefullService'

export class DeviceHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.devicesApiService = new DevicesApiService()
  }

  static make() {
    if (!DeviceHelper.instance) {
      DeviceHelper.instance = new DeviceHelper()
    }
    return DeviceHelper.instance
  }

  async create(deviceId) {
    const { isSuccess, data } = await this._callApi(
      this.devicesApiService.create.bind(this.devicesApiService),
      setLoading,
      deviceId
    )
    return isSuccess ? data : null
  }
}
