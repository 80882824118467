import { Button, Typography } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React from 'react'

export const BannerItem = ({ classes, item, onBannerDelete }) => {
  return (
    <div className={classes.bannerItem}>
      <div className={classes.rowDirection}>
        <Typography className={classes.headingText}>Назва банеру:</Typography>
        <Typography className={classes.secondaryHeading}>
          {item.name}
        </Typography>
      </div>

      <div className={classes.rowDirection}>
        <Typography className={classes.headingText}>
          Категорія товарів:
        </Typography>
        <Typography className={classes.secondaryHeading}>
          {item.categoryId}
        </Typography>
      </div>
      <div className={classes.rowDirection}>
        <img src={item.imageUrl} width={500} alt="no file here" />
      </div>
      <div className={classes.rowDirection}>
        <Button onClick={() => onBannerDelete(item)}>
          <DeleteForeverIcon color={'error'} />
        </Button>
      </div>
    </div>
  )
}
