import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class ProductApiService extends AbstractRequest {
  constructor() {
    super(`/products`)
  }

  async getAvailableProducts({ skip, limit, storeId }) {
    const { data, status } = await this.get(
      `${this.baseUrl}/available/${storeId}`,
      {
        params: {
          skip,
          limit
        }
      }
    )
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async getProducts(params) {
    const { data, status } = await this.get(this.baseUrl, {
      params
    })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async getNonImageProducts() {
    const { data, status } = await this.get(`${this.baseUrl}/img/empty`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }
}
