export const ordersStateSelector = (state) => state.orders
export const ordersSelector = (state) => state.orders.orders
export const ordersSkipSelector = (state) => state.orders.skip
export const ordersTotalSelector = (state) => state.orders.total
export const expiredOrdersSelector = (state) => state.orders.expiredOrders

export const discardedOrdersSelector = (state) => state.orders.discardedOrders
export const discardedOrdersSkipSelector = (state) => state.orders.discardedSkip
export const discardedOrdersTotalSelector = (state) =>
  state.orders.discardedTotal
