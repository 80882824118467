export const brands = [
  {
    brandId: 1,
    title: 'Lays',
    groupId: '39'
  },
  {
    brandId: 2,
    title: 'Мілка',
    groupId: '35'
  },
  {
    brandId: 3,
    title: 'Red Bull',
    groupId: '39'
  },
  {
    brandId: 4,
    title: 'Торчин',
    groupId: '22'
  },
  {
    brandId: 5,
    title: 'Snickers',
    groupId: '39'
  },
  {
    brandId: 6,
    title: 'Верес',
    groupId: '39'
  },
  {
    brandId: 7,
    title: 'Stimorol',
    groupId: '39'
  },
  {
    brandId: 8,
    title: 'Taco Bell',
    groupId: '22'
  },
  {
    brandId: 9,
    title: 'The Laughing cow',
    groupId: '39'
  },
  {
    brandId: 10,
    title: 'Торчин',
    groupId: '39'
  },
  {
    brandId: 11,
    title: 'Tuborg',
    groupId: '39'
  }
]
