import React from 'react'
import { makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import Box from '@material-ui/core/Box'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { useSelector } from 'react-redux'
import { SyncService } from 'services/SyncService'
import { SpinnerButton } from 'components/Sync/SpinnerButton'

const syncService = new SyncService()

export const SyncScreen = () => {
  const syncState = useSelector((state) => state.sync)
  const classes = useStyles()

  const handleSyncAll = () => syncService.SyncAll()

  const handleSyncGroups = () => syncService.SyncGroups()

  const handleSyncStores = () => syncService.SyncStores()

  const handleSyncCategories = () => syncService.SyncCategories()

  const handleSyncFamilies = () => syncService.SyncFamilies()

  const handleSyncPrices = () => syncService.SyncPrices()

  const handleSyncEmployees = () => syncService.SyncEmployees()

  const handleSyncProducts = () => syncService.SyncProducts()

  const handleSyncProductsAvailable = () => syncService.SyncProductsAvailable()

  const handleSyncProductsImages = () => syncService.SyncProductsImages()

  return (
    <GridContainer>
      <div className={classes.container}>
        <Box
          boxShadow={2}
          m={1}
          p={1}
          border={1}
          borderColor={green[500]}
          className={classes.syncAllContainer}
        >
          <SpinnerButton
            text="Повна синхронізація усіх данних"
            handleButtonClick={handleSyncAll}
            loading={syncState.sync.loading}
            success={syncState.sync.synced}
            count={syncState.sync.count}
          />
        </Box>

        <div className={classes.syncButtons}>
          <GridItem xs={12} sm={12} md={12}>
            <SpinnerButton
              text="Cинхронізація Груп"
              loading={syncState.groups.loading}
              handleButtonClick={handleSyncGroups}
              success={syncState.groups.synced}
              count={syncState.groups.count}
            />

            <SpinnerButton
              text="Синхронізація магазинів"
              loading={syncState.stores.loading}
              handleButtonClick={handleSyncStores}
              success={syncState.stores.synced}
              count={syncState.stores.count}
            />

            <SpinnerButton
              text="Синхронізація категорій"
              loading={syncState.categories.loading}
              handleButtonClick={handleSyncCategories}
              success={syncState.categories.synced}
              count={syncState.categories.count}
            />

            <SpinnerButton
              text="Синхронізація сімей"
              loading={syncState.families.loading}
              handleButtonClick={handleSyncFamilies}
              success={syncState.families.synced}
              count={syncState.families.count}
            />

            <SpinnerButton
              text="Синхронізація цін"
              loading={syncState.prices.loading}
              handleButtonClick={handleSyncPrices}
              success={syncState.prices.synced}
              count={syncState.prices.count}
            />
            <SpinnerButton
              text="Синхронізація працівників"
              loading={syncState.employees.loading}
              handleButtonClick={handleSyncEmployees}
              success={syncState.employees.synced}
              count={syncState.employees.count}
            />
            <SpinnerButton
              text="Синхронізація продуктів"
              loading={syncState.products.loading}
              handleButtonClick={handleSyncProducts}
              success={syncState.products.synced}
              count={syncState.products.count}
            />
            <SpinnerButton
              text="Синхронізація доступних продуктів"
              loading={syncState.productsAvailable.loading}
              handleButtonClick={handleSyncProductsAvailable}
              success={syncState.productsAvailable.synced}
              count={syncState.productsAvailable.count}
            />
            <SpinnerButton
              text="Синхронізація картинок"
              loading={syncState.productsImages.loading}
              handleButtonClick={handleSyncProductsImages}
              success={syncState.productsImages.synced}
              count={syncState.productsImages.count}
            />
          </GridItem>
        </div>
      </div>
    </GridContainer>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100vh'
  },
  syncAllContainer: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    marginBottom: 10,
    borderRadius: 10
  },
  syncButtons: {
    display: 'flex',
    flex: 5,
    alignItems: 'flex-start',
    justifyContent: 'space-around'
  }
}))
