import { setLoading } from 'redux/reducers/appReducer'
import { setCategories } from 'redux/reducers/storeReducer'
import { CategoriesApiService } from 'services/api/CategoriesApiService'
import { StatefullService } from './StatefullService'

export class CategoryHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.categoriesApiService = new CategoriesApiService()
  }

  static make() {
    if (!CategoryHelper.instance) {
      CategoryHelper.instance = new CategoryHelper()
    }
    return CategoryHelper.instance
  }

  async getCategories() {
    const { isSuccess, data } = await this._callApi(
      this.categoriesApiService.getCategories.bind(this.categoriesApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setCategories(data))
      return data
    }
    return null
  }
}
