import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '../Card/Card'
import CardHeader from '../Card/CardHeader'
import CardBody from '../Card/CardBody'
import Button from '../CustomButtons/Button'
import { ListItem, Collapse, Grid } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { DataGrid } from '@material-ui/data-grid'
import { warningColor } from 'assets/jss/material-dashboard-react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Spiner from 'components/Spiner/Spiner'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { Limits } from 'shared/enums'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: warningColor[6],
      margin: '0',
      fontSize: '15px',
      marginTop: '0',
      marginBottom: '0'
    },
    fontWeight: '600'
  },
  cardTitleWhite: {
    color: '#fff',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '400',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  width: {
    width: '100%'
  },
  iconBlock: {
    alignItems: 'center'
  },
  tableButtonsStyles: {
    paddingBottom: 10,
    textAlign: 'right'
  },
  addButton: {
    position: 'absolute',
    zIndex: 556,
    bottom: 30,
    right: 30
  },

  '@global': {
    'body > div[role="tooltip"]': {
      zIndex: 999
    },
    '.MuiDataGrid-cell': {
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.MuiTablePagination-caption': {
      display: 'none'
    }
  },
  datagrid: {
    '& .MuiTablePagination-caption': {
      display: 'none'
    }
  }
}

const useStyles = makeStyles(styles)

function ItemTable({
  data,
  title,
  subtitle,
  extractId,
  columnsTable,
  onRowSelected,
  onListToggle,
  // if paggination don't needed delete it
  // onPageSelected,
  isLoading
}) {
  const [currentPage, setCurrentPage] = useState(0)
  const [openList, setOpenList] = useState(false)
  const [tableButtons, setTableButtons] = useState(false)
  const classes = useStyles()

  const handlePageSelect = ({ page }) => {
    if (isLoading) return
    // if paggination don't needed delete it
    // onPageSelected(currentPage, page)
    setCurrentPage(page)
  }

  const handleOpenList = () => {
    if (isLoading) return
    onListToggle && onListToggle(!openList)
    setOpenList(!openList)
  }

  return (
    <Card plain>
      <ListItem autoFocus={true} onClick={handleOpenList}>
        <CardHeader color="success" style={styles.width} plain>
          <Grid container direction="row" style={styles.iconBlock}>
            <h4 className={classes.cardTitleWhite}>{title}</h4>
            <ArrowDropDownIcon fontSize="large" />
          </Grid>
          <p className={classes.cardCategoryWhite}>{subtitle}</p>
        </CardHeader>
      </ListItem>

      {data.length ? (
        <Collapse in={openList} timeout="auto" unmountOnExit>
          <CardBody>
            {tableButtons && (
              <div style={styles.tableButtonsStyles}>
                <Button color="warning" size="sm">
                  <VpnKeyIcon />
                  Створити Пароль
                </Button>
                <Button color="danger" size="sm" justIcon>
                  <DeleteForeverIcon />
                </Button>
              </div>
            )}

            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'center'
                }}
              >
                <Spiner />
              </div>
            ) : (
              <DataGrid
                className={classes.datagrid}
                onPageChange={handlePageSelect}
                autoHeight={true}
                rows={data}
                columns={columnsTable}
                rowCount={data.length}
                pageSize={Limits.ProductsLimit}
                // pagination
                // if paggination needed switch to "server"
                paginationMode="client"
                page={currentPage}
                getRowId={extractId}
                onSelectionChange={({ rowIds }) => {
                  setTableButtons(!!rowIds.length)
                }}
                disableSelectionOnClick={true}
                onRowSelected={onRowSelected}
                localeText={rowsTableTranslate}
              />
            )}
          </CardBody>
        </Collapse>
      ) : null}
    </Card>
  )
}

export default ItemTable
