import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { successColor } from 'assets/jss/material-dashboard-react'
import GridContainer from 'components/Grid/GridContainer'
import { Button } from '@material-ui/core'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import { BannerItem } from 'components/Banners/BannerItem'
import { useHelper } from 'shared/hooks/useHelper'
import { BannerHelper } from 'services/helpers/BannerHelper'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  headingText: {
    fontSize: theme.typography.pxToRem(17),
    color: successColor[0],
    fontWeight: '500',
    paddingRight: 5
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  buttons: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 20,
    paddingLeft: 20
  },

  '@global': {
    '.MuiAccordionSummary-expandIcon': {
      color: successColor[0]
    },
    '.MuiTextField-root': {
      borderBottomColor: successColor[0],
      width: '25ch'
    }
  },
  blockPadding: {
    paddingLeft: 20
  },
  rowPadding: {
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 150
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  bannerItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: 10
  }
}))

export const CreatedBannersItemWidget = ({ item }) => {
  const classes = useStyles()
  const bannerHelper = useHelper(BannerHelper)

  const handleDeleteGroup = (groupId) => {
    bannerHelper.deleteGroup({ id: groupId })
  }

  const handleDelete = (item) => {
    bannerHelper.deleteBanner({
      id: item.uuid
    })
  }

  return (
    <GridContainer>
      <Accordion
        style={{
          width: '100%'
        }}
        key={item.uuid}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id={item.uuid}
        >
          <div className={classes.heading}>
            <div className={classes.rowDirection}>
              <Typography className={classes.headingText}>
                Назва категорії банерів:
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {item.name}
              </Typography>
            </div>
          </div>
          <Button onClick={() => handleDeleteGroup(item.uuid)}>
            <DeleteSweepIcon color="error" style={{ paddingRight: 5 }} />{' '}
            <Typography className={classes.secondaryHeading}>
              Видалити группу з банерами
            </Typography>
          </Button>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          {!!item.banners?.length &&
            item.banners.map((item) => {
              return (
                <BannerItem
                  onBannerDelete={handleDelete}
                  classes={classes}
                  item={item}
                  key={item.uuid}
                />
              )
            })}
        </AccordionDetails>
      </Accordion>
    </GridContainer>
  )
}
