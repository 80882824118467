import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { successColor } from 'assets/jss/material-dashboard-react'
import { Grid } from '@material-ui/core'
import { dangerColor } from 'assets/jss/material-dashboard-react'
import { useSelector } from 'react-redux'
import { actSelector } from 'redux/selectors/actsSelectors'
import { actLoadingSelector } from 'redux/selectors/actsSelectors'
import { format } from 'date-fns'
import Spiner from 'components/Spiner/Spiner'
import { TransformUtils } from 'services/utils/TransformUtils'
import { NamesUtils } from 'services/utils/NamesUtils'
import { useHelper } from 'shared/hooks/useHelper'
import { ReturnActHelper } from 'services/helpers/ReturnActHelper'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  '@global': {
    '.MuiAccordionSummary-expandIcon': {
      color: successColor[0]
    }
  },
  root: {
    borderTopWidth: 1,
    borderTopColor: successColor[0]
  },
  rowTextContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  },
  titleTextContent: {
    fontWeight: '500',
    color: successColor[0],
    paddingRight: 5
  },
  productTitleContent: {
    fontWeight: '500',
    color: successColor[0],
    paddingRight: 5,
    textAlign: 'center'
  },
  productsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '15px 10 15px 10',
    padding: 10
  }
}))

export const ActsItem = ({ item }) => {
  const { createdAt, uuid } = item
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [products, setProducts] = useState([])
  const act = useSelector(actSelector)
  const isLoading = useSelector(actLoadingSelector)
  const actHelper = useHelper(ReturnActHelper)

  const handleChange = (actId) => async (event, isExpanded) => {
    const actProducts = await actHelper.setActWIthProducts(actId)
    setProducts(actProducts)
    setExpanded(isExpanded && item.uuid)
  }

  if (isLoading) return <Spiner />

  return (
    <>
      <Accordion
        key={uuid}
        expanded={expanded === uuid}
        onChange={handleChange(uuid)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {act && (
            <Typography className={classes.heading}>
              {act.order.name} {act.order.lastName || ''}
            </Typography>
          )}
          <Typography className={classes.secondaryHeading}>
            <Grid container direction="row">
              <text>Акт від:</text>{' '}
              <text
                style={{
                  color: successColor[0],
                  fontWeight: '500',
                  paddingRight: 10,
                  paddingLeft: 5
                }}
              >
                {format(new Date(createdAt), 'dd.MM.yyyy Час: HH:mm')}
              </text>
            </Grid>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          {act && (
            <Grid
              container
              direction="column"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className={classes.rowTextContent}>
                <Typography className={classes.titleTextContent}>
                  Магазин:
                </Typography>
                <Typography> {act.order.store.address}</Typography>
              </div>

              {act.order.employee && (
                <>
                  {act.order.employee.firstName && (
                    <div className={classes.rowTextContent}>
                      <Typography className={classes.titleTextContent}>
                        Курьер:
                      </Typography>
                      <Typography>
                        {NamesUtils.getEmployeeFullName(act)}
                      </Typography>
                    </div>
                  )}
                  <div className={classes.rowTextContent}>
                    <Typography className={classes.titleTextContent}>
                      Клієнт:
                    </Typography>
                    <Typography>
                      {act.order.user.name} {act.order.user.lastName}
                    </Typography>
                    <Typography
                      className={classes.titleTextContent}
                      style={{ paddingLeft: 5 }}
                    >
                      Тел.
                    </Typography>
                    <Typography>{act.order.user.phone}</Typography>
                  </div>
                  <div className={classes.productsContainer}>
                    {!!products.length && (
                      <Typography className={classes.productTitleContent}>
                        Продукти від яких відмовився:
                      </Typography>
                    )}
                    {products.map((product, index) => {
                      const {
                        productId,
                        name,
                        barCode,
                        pickQuantity,
                        quantity,
                        discountPrice,
                        regularPrice,
                        availableQuantity,
                        measurementUnit
                      } = product
                      const actQuantity = TransformUtils.normalizeQuantityDifference(
                        measurementUnit,
                        quantity,
                        pickQuantity
                      )
                      return (
                        <>
                          <div
                            className={classes.rowTextContent}
                            key={productId}
                          >
                            <Typography
                              style={{
                                color: dangerColor[0],
                                fontWeight: '500',
                                paddingRight: 5
                              }}
                            >
                              {index + 1}.
                            </Typography>
                            <Typography className={classes.titleTextContent}>
                              Назва товару:
                            </Typography>
                            <Typography>{name}</Typography>
                          </div>
                          <div className={classes.rowTextContent}>
                            <Typography className={classes.titleTextContent}>
                              Код товару:
                            </Typography>
                            <Typography>{barCode}</Typography>
                          </div>
                          <div className={classes.rowTextContent}>
                            <Typography className={classes.titleTextContent}>
                              Кількість скасованого:
                            </Typography>
                            <Typography>
                              {actQuantity.toFixed(1)} {measurementUnit}
                            </Typography>
                          </div>
                          <div className={classes.rowTextContent}>
                            <Typography className={classes.titleTextContent}>
                              Ціна за одиниці:
                            </Typography>
                            <Typography>
                              {discountPrice || regularPrice} ₴
                            </Typography>
                          </div>
                          <div
                            className={classes.rowTextContent}
                            style={{ marginBottom: 15 }}
                          >
                            <Typography className={classes.titleTextContent}>
                              Доступно у магазині:
                            </Typography>
                            <Typography>
                              {availableQuantity} {measurementUnit}
                            </Typography>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  )
}
