import { EmployeesRoles } from 'shared/enums'

export class EmployeesResolver {
  constructor() {}

  normalizeEmployees(employees) {
    return (
      employees &&
      employees.map((employee) => {
        return {
          ...employee,
          fullName: `${employee.firstName} ${employee.middleName} ${employee.lastName}`,
          role: EmployeesRoles[employee.role] || employee.role
        }
      })
    )
  }
}
