import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sync: {
    count: null,
    synced: false,
    loading: false
  },
  stores: {
    synced: false,
    loading: false
  },
  groups: {
    count: null,
    synced: false,
    loading: false
  },
  categories: {
    count: null,
    synced: false,
    loading: false
  },
  families: {
    count: null,
    synced: false,
    loading: false
  },
  prices: {
    count: null,
    synced: false,
    loading: false
  },
  employees: {
    count: null,
    synced: false,
    loading: false
  },
  products: {
    count: null,
    synced: false,
    loading: false
  },
  productsAvailable: {
    count: null,
    synced: false,
    loading: false
  },
  productsImages: {
    count: null,
    synced: false,
    loading: false
  }
}

const syncSlice = createSlice({
  name: 'sync',
  initialState,
  reducers: {
    //success
    syncSuccess(state, action) {
      state.sync.synced = action.payload.synced
      state.sync.count = action.payload.count
    },
    storesSuccess(state, action) {
      state.stores.synced = action.payload.synced
      state.stores.count = action.payload.count
    },
    groupsSuccess(state, action) {
      state.groups.synced = action.payload.synced
      state.groups.count = action.payload.count
    },
    categoriesSuccess(state, action) {
      state.categories.synced = action.payload.synced
      state.categories.count = action.payload.count
    },
    familiesSuccess(state, action) {
      state.families.synced = action.payload.synced
      state.families.count = action.payload.count
    },
    pricesSuccess(state, action) {
      state.prices.synced = action.payload.synced
      state.prices.count = action.payload.count
    },
    employeesSuccess(state, action) {
      state.employees.synced = action.payload.synced
      state.employees.count = action.payload.count
    },
    productsSuccess(state, action) {
      state.products.synced = action.payload.synced
      state.products.count = action.payload.count
    },
    productsAvailableSuccess(state, action) {
      state.productsAvailable.synced = action.payload.synced
      state.productsAvailable.count = action.payload.count
    },
    productsImagesSuccess(state, action) {
      state.productsImages.synced = action.payload.synced
      state.productsImages.count = action.payload.count
    },

    //loadings
    setSyncLoading(state, action) {
      state.sync.loading = action.payload
    },
    setStoresLoading(state, action) {
      state.stores.loading = action.payload
    },
    setGroupsLoading(state, action) {
      state.groups.loading = action.payload
    },
    setCategoriesLoading(state, action) {
      state.categories.loading = action.payload
    },
    setFamiliesLoading(state, action) {
      state.families.loading = action.payload
    },
    setPricesLoading(state, action) {
      state.prices.loading = action.payload
    },
    setEmployeesLoading(state, action) {
      state.employees.loading = action.payload
    },
    setProductsLoading(state, action) {
      state.products.loading = action.payload
    },
    setProductsAvailableLoading(state, action) {
      state.productsAvailable.loading = action.payload
    },
    setProductsImagesLoading(state, action) {
      state.productsImages.loading = action.payload
    }
  }
})

const { reducer, actions } = syncSlice
export default reducer
export const {
  syncSuccess,
  storesSuccess,
  groupsSuccess,
  categoriesSuccess,
  familiesSuccess,
  pricesSuccess,
  employeesSuccess,
  productsSuccess,
  productsAvailableSuccess,
  productsImagesSuccess,
  setSyncLoading,
  setStoresLoading,
  setGroupsLoading,
  setCategoriesLoading,
  setFamiliesLoading,
  setPricesLoading,
  setEmployeesLoading,
  setProductsLoading,
  setProductsAvailableLoading,
  setProductsImagesLoading
} = actions
