import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  }
}))

export default function Spiner() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress disableShrink color="secondary" />
    </div>
  )
}
