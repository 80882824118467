import { AbstractRequest } from 'services/abstract/AbstractRequest'

const limit = Number.MAX_SAFE_INTEGER
export class BannersApiService extends AbstractRequest {
  constructor() {
    super('/banners')
  }

  async createGroup(name) {
    const { data, status } = await this.post(`${this.baseUrl}/groups`, {
      name
    })
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async getGroups() {
    const params = {
      skip: 0,
      sort: 'name',
      limit
    }
    const { data, status } = await this.get(`${this.baseUrl}/groups`, {
      params
    })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async createBanner({ payload }) {
    const { data, status } = await this.post(this.baseUrl, payload)
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async deleteBanner({ id }) {
    const { data, status } = await this.delete(`${this.baseUrl}/${id}`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async deleteGroup({ id }) {
    const { data, status } = await this.delete(`${this.baseUrl}/groups/${id}`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }
}
