import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bonuses: {
    skip: 0,
    data: [],
    count: null
  },
  loading: false
}

const bonusSlice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    addBonuses: (state, action) => {
      state.bonuses.data = state.bonuses.data.concat(action.payload)
      state.bonuses.skip += action.payload.length
    },
    setBonuses: (state, action) => {
      state.bonuses.data = action.payload
      state.bonuses.skip = action.payload.length
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCount: (state, action) => {
      state.bonuses.count = action.payload
    }
  }
})

const { reducer, actions } = bonusSlice
export default reducer
export const { addBonuses, setLoading, setCount, setBonuses } = actions
