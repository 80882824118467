import { firebaseMessagingSelector } from 'redux/selectors/firebaseSelectors'
import { StatefullService } from './StatefullService'
import { onMessage } from 'firebase/messaging'
import { NotifyApiHelper } from 'services/api/NotifyApiHelper'

export class NotifyHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.notifyApiHelper = new NotifyApiHelper()
    this.handlers = new Set()
    this.isGranted =
      window.Notification && Notification.permission === 'granted'
  }

  static make() {
    if (!NotifyHelper.instance) {
      NotifyHelper.instance = new NotifyHelper()
    }
    return NotifyHelper.instance
  }

  initHandlers() {
    const messaging = this._select(firebaseMessagingSelector)
    if (messaging) {
      onMessage(messaging, (payload) => {
        Array.from(this.handlers).forEach((handler) => handler(payload))
      })
    }
  }

  onMessage(handler) {
    this.handlers.add(handler)
    return () => this.handlers.delete(handler)
  }

  async _requestPermission() {
    if (!window.Notification) return false
    const status = await Notification.requestPermission()
    this.isGranted = status === 'granted'
    return this.isGranted
  }

  async showNotification(title, options) {
    if (this.isGranted || (await this._requestPermission())) {
      const notification = new Notification(title, options)
      if (options.onClick) {
        notification.addEventListener('click', options.onClick)
      }
    } else {
      alert(title)
    }
  }

  sendOrderStatus(deviceId, body) {
    return this.notifyApiHelper.sendOrderStatus(deviceId, body)
  }

  sendOrderCancel(deviceId, body) {
    return this.notifyApiHelper.sendOrderCancel(deviceId, body)
  }
}
