import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flex: 1,
    alignItems: 'center'
  },
  wrapper: {
    width: '100%',
    margin: theme.spacing(1),
    position: 'relative'
  },
  button: {
    width: '100%'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5)
  },
  textTintColor: {
    color: green[700]
  },
  text: {
    display: 'flex',
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export const SpinnerButton = ({
  text,
  handleButtonClick,
  loading,
  success,
  count
}) => {
  const classes = useStyles()

  const textColor = clsx({
    [classes.textTintColor]: success,
    [classes.text]: true
  })

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
    [classes.button]: true
  })

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          className={buttonClassname}
          disabled={loading}
          onClick={handleButtonClick}
          endIcon={success ? <CheckIcon /> : <SaveIcon />}
        >
          <div className={classes.buttonContent}>{text}</div>
        </Button>
        {success && (
          <div className={textColor}>
            {' '}
            Синхронізовано {count || 0} елементів{' '}
          </div>
        )}
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  )
}
