import { formatDistance, isEqual, max } from 'date-fns'
import { ExsistStatuses } from 'shared/enums'
import { uk } from 'date-fns/esm/locale'

export class ExpireOrdersResolver {
  constructor() {}

  getAllExpires(order) {
    let array = []
    if (order.pickupStartAt && order.pickupStartAt.timestamp) {
      array.push({
        name: 'pickupStartAt',
        ...order.pickupStartAt
      })
    }

    if (order.pickupedAt && order.pickupedAt.timestamp) {
      array.push({ name: 'pickupedAt',  ...order.pickupedAt })
    }
    if (order.deliveryStartAt && order.deliveryStartAt.timestamp) {
      array.push({ name: 'deliveryStartAt', ...order.deliveryStartAt })
    }
    if (order.deliveredAt && order.deliveredAt.timestamp) {
      array.push({ name: 'deliveredAt', ...order.deliveredAt })
    }
    if (order.complitedAt && order.complitedAt.timestamp) {
      array.push({ name: 'complitedAt', ...order.complitedAt })
    }
    if (order.finalizedAt && order.finalizedAt.timestamp) {
      array.push({ name: 'finalizedAt', ...order.finalizedAt })
    }

    return array
  }

  getCurrentExpire(order) {
    const expires = this.getAllExpires(order)
    const dates = expires.map(({ timestamp }) => new Date(timestamp))
    const latestDate = max(dates)
    const currentExpiredItem =
      expires.length <= 1
        ? expires[0]
        : expires.find((item) => isEqual(new Date(item.timestamp), latestDate))

    if (currentExpiredItem) {
      return currentExpiredItem
    }
    return null
  }

  getTimeDifference(orderCreated, item, expires) {
    const locale = { locale: uk }
    let obj = {}
    expires.map((expire) => {
      if (!expire.timestamp) return
      const normalizedPrice = {
        timestamp: formatDistance(
          new Date(item.timestamp),
          new Date(expire.timestamp),
          locale
        )
      }
      if (expire.name === ExsistStatuses.PickupStartAt) {
        obj = {
          timestamp: formatDistance(
            new Date(orderCreated),
            new Date(item.timestamp),
            locale
          )
        }
      }
      if (
        expire.name === ExsistStatuses.PickupedAt &&
        item.name === ExsistStatuses.PickupStartAt
      ) {
        obj = normalizedPrice
      }
      if (
        ExsistStatuses.DeliveryStartAt === expire.name &&
        item.name === ExsistStatuses.PickupedAt
      ) {
        obj = normalizedPrice
      }
      if (
        ExsistStatuses.deliveredAt === expire.name &&
        item.name === ExsistStatuses.DeliveryStartAt
      ) {
        obj = normalizedPrice
      }
      if (
        ExsistStatuses.ComplitedAt === expire.name &&
        item.name === ExsistStatuses.deliveredAt
      ) {
        obj = normalizedPrice
      }

      if (
        ExsistStatuses.FinalizedAt === expire.name &&
        item.name === ExsistStatuses.ComplitedAt
      ) {
        obj = normalizedPrice
      }
    })

    return obj
  }

  normalizeTime(order, item, expires) {
    if ((!item || !expires) && order) {
      const currentExpire = this.getCurrentExpire(order)

      if (currentExpire) {
        const time = formatDistance(
          new Date(order.createdAt),
          new Date(currentExpire.timestamp),
          { locale: uk }
        )
        return time
      }
    } else if (order && item && expires) {
      return this.getTimeDifference(order.createdAt, item, expires)
    }
    return null
  }
}
