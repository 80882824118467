import React, { useState } from 'react'

import { makeStyles, TextField } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { successColor } from 'assets/jss/material-dashboard-react'
import TransferRoles from './TransferRoles'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInput-underline:after ': {
      borderColor: successColor[0]
    },
    '& .MuiFormLabel-root': {
      color: '#c7c7c7',
      fontSize: 14,
      fontWeight: '500'
    },
    '& .MuiInputBase-root': {
      borderColor: '#c7c7c7'
    }
  },
  select: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 20,
    paddingRight: 20,
    paddingLeft: 20
  },
  buttons: {
    paddingTop: 20
  }
}))

const roles = [
  {
    id: 2231,
    title: 'Адміністратор'
  },
  {
    id: 223,
    title: 'Оператор Call-центру'
  }
]

function SelectRole() {
  const classes = useStyles()

  const [value, setValue] = useState()
  return (
    <GridContainer>
      <div className={classes.select}>
        <Autocomplete
          fullWidth={true}
          onChange={(event, value) => {
            if (!value) {
              return 'Оберіть роль або введіть запит'
            }
            setValue(value.title)
          }}
          className={classes.root}
          style={{ minWidth: 300, paddingTop: 15 }}
          id="combo-box"
          options={roles}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField {...params} label="Оберіть роль або введіть запит" />
          )}
        />
      </div>
      {value && (
        <GridItem xs={12} sm={12} md={12}>
          <TransferRoles />
          <div className={classes.buttons}>
            <Button>Скасувати</Button>
            <Button color="success">Зберегти</Button>
          </div>
        </GridItem>
      )}
    </GridContainer>
  )
}

export default SelectRole
