import React, { createRef, useState, useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import Navbar from 'components/Navbars/Navbar.js'
import Footer from 'components/Footer/Footer.js'
import Sidebar from 'components/Sidebar/Sidebar.js'

import routes from 'routes.js'

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js'

import bgImage from 'assets/img/sidebar-2.jpg'
import logo from 'assets/img/reactlogo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { UserService } from 'services/UserService'
import { setUserData } from 'redux/reducers/userReducer'
import { Firebase } from 'shared/hoc/Firebase'
import { WsGateway } from 'shared/hoc/WsGateway'

let ps

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      switch (prop.layout) {
        case '/admin':
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          )
        case '/bonus':
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          )
        case '/order/:orderId':
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          )
        default:
          return null
      }
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
)

const useStyles = makeStyles(styles)
const userService = new UserService()

export default function Admin({ ...rest }) {
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const history = useHistory()

  // styles
  const classes = useStyles()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef()
  // states and functions

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const getRoute = () => {
    return window.location.pathname !== '/admin/logout'
  }
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }
  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (!mainPanel.current) return
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', resizeFunction)
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
      window.removeEventListener('resize', resizeFunction)
    }
  }, [mainPanel])

  if (!user) {
    const data = userService.getUserData()
    if (!data) history.push('/login')
    else dispatch(setUserData(data))
    return null
  }

  return (
    <WsGateway>
      <Firebase>
        <div className={classes.wrapper}>
          <Sidebar
            routes={routes}
            logoText={'Близенько'}
            logo={logo}
            image={bgImage}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={'green'}
            {...rest}
          />
          <div className={classes.mainPanel} ref={mainPanel}>
            <Navbar
              routes={routes}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}
            {getRoute() ? <Footer /> : null}
          </div>
        </div>
      </Firebase>
    </WsGateway>
  )
}
