export const config = {
  firebase: {
    apiKey: 'AIzaSyAnasHbfFJIKGGnTwqb0Qkg-Gg8JEek2uc',
    authDomain: 'blyzenko-f4b23.firebaseapp.com',
    projectId: 'blyzenko-f4b23',
    storageBucket: 'blyzenko-f4b23.appspot.com',
    messagingSenderId: '1058048266846',
    appId: '1:1058048266846:web:58d72344e2a753789c5acf',
    measurementId: 'G-9RVHK0ZV7V',
    vapidKey:
      'BCxSCPUXSDbzXu9F57rJ8gAPCOGHCRcinjSH3GO5k11rRgp0ClGDwvcK2Q_o_gdm8eUMWDt-56MCSbI1fytmGAY'
  },
  apiBase: 'https://delivery.blyzenko.com.ua',
  api: 'https://delivery.blyzenko.com.ua/test'
  // FOR LOCAL DEVELOP
  // apiBase: 'http://localhost:7001',
  // api: 'http://localhost:7001'
}
