import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import GridItem from '../../components/Grid/GridItem'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import EditIcon from '@material-ui/icons/Edit'
import EmployeeItem from './items/EmployeeItem'
import { hist } from 'index'
import { useDispatch } from 'react-redux'

const role = [
  {
    id: 1,
    role: 'Адміністратор'
  },
  {
    id: 2,
    role: 'Менеджер Call-центру'
  }
]

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
    fontWeight: '500'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: 0,
    minHeight: 'auto',
    fontWeight: '600',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  center: {
    textAlign: 'center',
    alignItems: 'center',
    width: 'auto'
  },
  modal: {
    textAlign: 'center',
    alignItems: 'center',
    height: 'auto'
  },
  header: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  '@global': {
    '.makeStyles-grid-114': {
      margin: '0 !important'
    },
    '.makeStyles-grid-111': {
      justifyContent: 'center'
    }
  },
  headerContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column'
  }
}

const useStyles = makeStyles(styles)

function EmployeeUpdate(props) {
  const [modal, setModal] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          color="warning"
          justIcon
          size="sm"
          onClick={() => setModal(true)}
        >
          <EditIcon />
        </Button>
      </div>
      <Dialog
        style={{ alignItems: 'center', flexDirection: 'column' }}
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(true)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <GridContainer>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.headerContainer}
          >
            <CardHeader color="success" className={classes.header}>
              <h4 className={classes.cardTitleWhite}>{props.row.fullName}</h4>
              <p className={classes.cardCategoryWhite}>
                Працює у магазині №{props.row.storeId}
              </p>
            </CardHeader>
          </DialogTitle>
          <DialogContent>
            <CardBody>
              {/* <GridContainer className={classes.cardCategoryWhite}>
                <GridItem xs={12} sm={12} md={12}>
                  <EmployeeItem data={role} />
                </GridItem>
              </GridContainer> */}

              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  paddingTop: 15,
                  paddingBottom: 15
                }}
              >
                {/* <div xs={6} sm={6} md={6}>
                  <Button
                    onClick={() => {
                      setModal(false)
                      hist.push('/history')
                    }}
                    color="warning"
                  >
                    <h4 className={classes.cardTitleWhite}>До історії</h4>
                  </Button>
                </div> */}
                <div xs={6} sm={6} md={6}>
                  <Button
                    onClick={() => {
                      hist.push('/admin/bonus', {
                        params: props.row
                      })
                    }}
                  >
                    <h4 className={classes.cardTitleWhite}>До бонусів</h4>
                  </Button>
                </div>
                {/* <Button color="danger">
                  <h4 className={classes.cardTitleWhite}>Блокувати</h4>
                </Button> */}
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  paddingTop: 15
                }}
              >
                <Button onClick={() => setModal(false)}>Скасувати</Button>
                {/* <Button onClick={() => setModal(false)} color="success">
                  Зберегти
                </Button> */}
              </div>
            </CardBody>
          </DialogContent>
        </GridContainer>
      </Dialog>
    </>
  )
}

export default EmployeeUpdate
