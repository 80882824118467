import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { UserService } from 'services/UserService'

const userService = new UserService()

export function Logout() {
  const history = useHistory()
  useEffect(() => {
    userService.logout()
    history.push('/login')
  }, [history])
  return null
}
