import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export const OrderTitle = ({ title, color, styles }) => {
  return (
    <Grid item style={styles.mainItem}>
      <Typography style={{ ...styles.titleText, color }}>{title}</Typography>
    </Grid>
  )
}
