import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  errors: [],
  loading: false,
  windowFocus: true
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    errorsSet(state, action) {
      state.errors = action.payload.errors
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setWindowFocus(state, action) {
      state.windowFocus = action.payload
    }
  }
})

const { reducer, actions } = appSlice
export default reducer
export const { errorsSet, setLoading, setWindowFocus } = actions
