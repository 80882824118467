import {
  setTotal,
  updateClient,
  addClientsSuccess,
  setLoading,
  removeVipById,
  addVip,
  setVipTotal,
  removeBannedById,
  addBanned,
  setBannedTotal
} from 'redux/reducers/clientsReducer'
import { StatefullService } from './StatefullService'
import { ClientApiService } from 'services/api/ClientApiService'

export class ClientHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.clientApiService = new ClientApiService()
  }

  static make() {
    if (!ClientHelper.instance) {
      ClientHelper.instance = new ClientHelper()
    }
    return ClientHelper.instance
  }

  async addClients({ skip, phone, email, filter }) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.clientApiService.addClients.bind(this.clientApiService),
      setLoading,
      { skip, phone, email, filter }
    )
    if (isSuccess) {
      this._dispatch(addClientsSuccess(data))
      return data
    }
    return null
  }

  async setTotal() {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.setTotal.bind(this.clientApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setTotal(data))
      return data
    }
    return null
  }

  async banUser(id) {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.banUser.bind(this.clientApiService),
      setLoading,
      id
    )
    if (isSuccess) {
      this._dispatch(updateClient(data))
      this._dispatch(addBanned([data]))
      return data
    }
    return null
  }

  async unbanUser(id) {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.unbanUser.bind(this.clientApiService),
      setLoading,
      id
    )
    if (isSuccess) {
      this._dispatch(updateClient(data))
      this._dispatch(removeBannedById(data))
      return data
    }
    return null
  }

  async vipUser(id) {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.vipUser.bind(this.clientApiService),
      setLoading,
      id
    )
    if (isSuccess) {
      this._dispatch(updateClient(data))
      this._dispatch(addVip([data]))
      return data
    }
    return null
  }

  async unvipUser(id) {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.unvipUser.bind(this.clientApiService),
      setLoading,
      id
    )
    if (isSuccess) {
      this._dispatch(updateClient(data))
      this._dispatch(removeVipById(data))
      return data
    }
    return null
  }

  async setBannedTotal() {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.setBannedTotal.bind(this.clientApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setBannedTotal(data))
      return data
    }
    return null
  }

  async setVipTotal() {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.setVipTotal.bind(this.clientApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setVipTotal(data))
      return data
    }
    return null
  }

  async addBanned(skip) {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.addBanned.bind(this.clientApiService),
      setLoading,
      skip
    )
    if (isSuccess) {
      this._dispatch(updateClient(data))
      this._dispatch(addBanned(data))
      return data
    }
    return null
  }

  async addVip(skip) {
    const { isSuccess, data } = await this._callApi(
      this.clientApiService.addVip.bind(this.clientApiService),
      setLoading,
      skip
    )
    if (isSuccess) {
      this._dispatch(updateClient(data))
      this._dispatch(addVip(data))
      return data
    }
    return null
  }
}
