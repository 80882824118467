import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  groups: []
}

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups(state, action) {
      state.groups = action.payload
    }
  }
})

const { reducer, actions } = groupsSlice
export default reducer
export const { setGroups } = actions
