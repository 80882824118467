export const rowsTableTranslate = {
  columnMenuShowColumns: 'Усі колонки',
  columnMenuLabel: 'Меню',
  columnMenuFilter: 'Фільтри',
  columnMenuHideColumn: 'Приховати колонку',
  columnMenuUnsort: 'Скасувати сортування',
  columnMenuSortAsc: 'Сортування за зростом',
  columnMenuSortDesc: 'Сортування за спадом',
  filterOperatorContains: 'Містить',
  filterOperatorEquals: 'Дорівнює',
  filterOperatorStartsWith: 'Починається з',
  filterOperatorEndsWith: 'Закінчується на',
  filterPanelAddFilter: 'Додати фільтр',
  filterPanelOperators: 'Опції',
  filterPanelColumns: 'Колонки',
  filterPanelInputLabel: 'Пошук',
  filterPanelInputPlaceholder: 'Значення фільтру',
  footerTotalRows: 'Рядків загалом:',
  footerPaginationRowsPerPage: 'Рядків на сторінці:',
  columnsPanelTextFieldLabel: 'Знайти колонку',
  columnsPanelTextFieldPlaceholder: 'Заголовок колонки',
  columnsPanelDragIconLabel: 'Повернути колонку',
  columnsPanelShowAllButton: 'Показати усі',
  columnsPanelHideAllButton: 'Приховати усі',
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} обрано`
      : `${count.toLocaleString()} обрано`
}
