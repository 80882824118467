import { setLoading } from 'redux/reducers/returnActReducer'
import { setAct, setActs } from 'redux/reducers/returnActReducer'
import { ReturnActApiService } from 'services/api/ReturnActApiService'
import { StatefullService } from './StatefullService'
import { intersectionBy } from 'lodash'
import { SortUtils } from 'services/utils/SortUtils'

export class ReturnActHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.actService = new ReturnActApiService()
  }

  static make() {
    if (!ReturnActHelper.instance) {
      ReturnActHelper.instance = new ReturnActHelper()
    }
    return ReturnActHelper.instance
  }

  async setAct(actId) {
    if (!actId) return
    const { isSuccess, data } = await this._callApi(
      this.actService.getAct.bind(this.actService),
      setLoading,
      actId
    )
    if (isSuccess) {
      this._dispatch(setAct(data))
      return data
    }

    return null
  }

  async setActWIthProducts(actId) {
    const currentAct = await this.setAct(actId)
    const order = currentAct.order
    const actProducts = intersectionBy(
      order?.products,
      currentAct?.products,
      'productId'
    )

    return actProducts || []
  }

  async setActs(orderIds) {
    const { isSuccess, data } = await this._callApi(
      this.actService.getActs.bind(this.actService),
      setLoading,
      {
        orderIds
      }
    )

    if (isSuccess) {
      this._dispatch(setActs(SortUtils.sortByDate(data)))
      return data
    }

    return null
  }

  async updateAct({ actId, products }) {
    const { isSuccess, data } = await this._callApi(
      this.actService.updateAct.bind(this.actService),
      setLoading,
      {
        actId,
        products: [...new Set(products)]
      }
    )
    if (isSuccess) {
      this._dispatch(setAct(data))
      return data
    }

    return null
  }
}
