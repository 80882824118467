import { Box } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import React from 'react'

export const OrderLayout = ({ children, styles }) => {
  return (
    <Box
      boxShadow={2}
      p={1}
      border={1}
      borderColor={green[500]}
      style={styles.mainContainer}
    >
      {children}
    </Box>
  )
}
