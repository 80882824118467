import { useEffect, useRef } from 'react'

export function useInfiniteScroll({
  onLoadMore,
  isLoading,
  dataLength,
  hasNext
}) {
  const elementRef = useRef()

  useEffect(() => {
    if (!elementRef || !elementRef.current) return

    const observer = new IntersectionObserver(([target]) => {
      if (target.isIntersecting && !isLoading && hasNext) {
        onLoadMore()
      }
    })

    observer.observe(elementRef.current)
    return () => observer.disconnect()
  }, [elementRef, isLoading, hasNext, dataLength, onLoadMore])

  return elementRef
}
