// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard'
import BubbleChart from '@material-ui/icons/BubbleChart'
import Notifications from '@material-ui/icons/Notifications'
import FeedbackIcon from '@material-ui/icons/Feedback'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import GroupIcon from '@material-ui/icons/Group'
import SettingsIcon from '@material-ui/icons/Settings'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting'
import LabelIcon from '@material-ui/icons/Label'
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import AssignmentIcon from '@material-ui/icons/Assignment'
import SyncIcon from '@material-ui/icons/Sync'
// core components/views for Admin layout

import DashboardPage from 'views/Dashboard/Dashboard.js'
import Products from 'views/Products/Products.js'
// import Maps from 'views/Maps/Maps.js'
import NotificationsPage from 'views/Notifications/Notifications.js'
import Users from 'views/Users/Users'
import Clients from 'views/Clients/Clients'
import Orders from 'views/Orders/Orders'
import StaticPages from 'views/StaticPages/StaticPages'
import Feedbacks from 'views/Feedbacks/Feedbacks'
import { Banners } from 'views/Banners/Banners'
import Bonuses from 'views/Bonuses/Bonuses'
import Maps from 'views/Maps/Maps'
import StoresSettings from 'views/StoreSettings/StoresSettings'
import Brands from 'views/Brands/Brands'
import Roles from 'views/Roles/Roles'
import { Logout } from 'views/Logout/Logout'
import { SyncScreen } from 'views/Sync/SyncScreen'
import { Order } from 'views/Orders/Order'
import { Groups } from 'views/Groups/Groups'

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Головна',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin'
  },
  {
    path: '/table',
    name: 'Товари',
    icon: 'content_paste',
    component: Products,
    layout: '/admin'
  },
  {
    path: '/storesettings',
    name: 'Опції магазинів',
    icon: PermDataSettingIcon,
    component: StoresSettings,
    layout: '/admin'
  },
  {
    path: '/sync',
    name: 'Синхронізація',
    icon: SyncIcon,
    component: SyncScreen,
    layout: '/admin'
  },
  {
    path: '/brands',
    name: 'Бренди',
    icon: LabelIcon,
    component: Brands,
    layout: '/admin'
  },
  {
    path: '/orders',
    name: 'Замовлення',
    icon: ShoppingCartIcon,
    component: Orders,
    layout: '/admin'
  },
  {
    path: '/order/:orderId',
    name: 'Перегляд замовлення',
    icon: ShoppingCartIcon,
    component: Order,
    layout: '/admin'
  },
  {
    path: '/roles',
    name: 'Редагування ролей',
    icon: PhonelinkSetupIcon,
    component: Roles,
    layout: '/admin'
  },
  {
    path: '/employees',
    name: 'Працівники',
    icon: BubbleChart,
    component: Users,
    layout: '/admin'
  },
  {
    path: '/appPages',
    name: 'Статичні сторінки',
    icon: MenuBookIcon,
    component: StaticPages,
    layout: '/admin'
  },
  {
    path: '/notifications',
    name: 'Повідомлення',
    icon: Notifications,
    component: NotificationsPage,
    layout: '/admin'
  },
  {
    path: '/clients',
    name: 'Клiєнти',
    icon: GroupIcon,
    component: Clients,
    layout: '/admin'
  },
  {
    path: '/feedbacks',
    name: 'Звернення клієнтів',
    icon: FeedbackIcon,
    component: Feedbacks,
    layout: '/admin'
  },
  {
    path: '/settings',
    name: 'Банери',
    icon: SettingsIcon,
    component: Banners,
    layout: '/admin'
  },
  {
    path: '/groups',
    name: 'Групи',
    icon: AssignmentIcon,
    component: Groups,
    layout: '/admin'
  },
  {
    path: '/logout',
    name: 'Вийти',
    icon: MeetingRoomIcon,
    component: Logout,
    layout: '/admin'
  },
  {
    path: '/bonus',
    name: 'Бонуси',
    icon: ShoppingCartIcon,
    component: Bonuses,
    layout: '/admin'
  },
  {
    path: '/maps',
    name: 'Мапа',
    icon: MeetingRoomIcon,
    component: Maps,
    layout: '/admin'
  }
]

export default dashboardRoutes
