import { AbstractRequest } from 'services/abstract/AbstractRequest'

const limit = Number.MAX_SAFE_INTEGER
const skip = 0

export class GroupsApiService extends AbstractRequest {
  constructor() {
    super(`/groups`)
  }

  async getGroups() {
    const params = {
      skip,
      limit
    }
    const { data, status } = await this.get(this.baseUrl, { params })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async updateSettings(groups) {
    const { data, status } = await this.post(`${this.baseUrl}/settings`, groups)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }
}
