import React, { useEffect } from 'react'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { DataGrid } from '@material-ui/data-grid'
import { format } from 'date-fns'
import { deliveryAction } from 'helpers/translates'
import { useHelper } from 'shared/hooks/useHelper'
import { OrderHelper } from 'services/helpers/OrderHelper'
import { useSelector } from 'react-redux'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import { useHistory } from 'react-router'
import { existedStatusesTranslate } from 'shared/existedStatusesTranslate'
import { ordersSelector } from 'redux/selectors/ordersSelectors'
import { orderSocketSelector } from 'redux/selectors/gatewaySelectors'
import { ExsistStatuses } from 'shared/enums'
import { OrderStatus } from 'helpers/enums'

const colorStatus = {
  red: {
    backgroundColor: 'red',
    width: '100%',
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  green: {
    backgroundColor: 'green',
    width: '100%',
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center'
  }
}

const columns = [
  {
    field: 'orderNum',
    type: 'number',
    headerName: 'Номер замовлення',
    width: 170
  },
  {
    field: 'expired',
    headerName: 'Протерміновано на',
    width: 220,
    renderCell: (params) => {
      return params.row.currentExpire?.timestamp
    }
  },

  {
    field: 'createdAt',
    type: 'dateTime',
    headerName: 'Дата замовлення',
    width: 200,
    renderCell: (params) => {
      return format(new Date(params.value), 'dd.MM.yyyy Час: HH:mm')
    }
  },
  {
    field: 'currentExpire',
    headerName: 'Статус',
    width: 350,
    valueGetter: ({ value }) => existedStatusesTranslate[value?.name],
    renderCell: (params) => {
      return (
        <div
          style={
            params.row.currentExpire?.name === ExsistStatuses.PickupStartAt
              ? colorStatus.red
              : colorStatus.green
          }
        >
          {params.value}
        </div>
      )
    }
  },
  {
    field: 'store',
    headerName: 'Магазин',
    width: 250,
    valueGetter: (params) => params.row.store.address
  },
  {
    field: 'deliveryAction',
    headerName: 'Спосіб доставки',
    width: 200,
    valueGetter: ({ value }) => deliveryAction[value]
  },
  {
    field: `user`,
    headerName: `Ім'я та тел. клієнта `,
    width: 300,
    valueGetter: (params) => {
      return `${params.row.user.name} ${params.row.user.lastName} ${params.row.user.phone}`
    }
  }
]

export default function Dashboard() {
  const orderSocket = useSelector(orderSocketSelector)
  const orders = useSelector(ordersSelector)
  const orderHelper = useHelper(OrderHelper)
  const loading = useSelector(appLoadingSelector)
  const ordersWithNum = orderHelper.getOrdersWithNum(orders)
  const history = useHistory()
  const filtredOrders = ordersWithNum.filter(
    ({ status }) =>
      status !== OrderStatus.Completed &&
      status !== OrderStatus.Canceled &&
      status !== OrderStatus.Discarded
  )

  useEffect(() => {
    orderHelper.setExpiredOrders()
  }, [])

  useEffect(() => {
    if (orderSocket) {
      orderSocket.on('expired', () => {
        orderHelper.setExpiredOrders()
      })
    }
  }, [orderSocket])

  const handleRowClick = (param) =>
    history.push(`/admin/order/${param.row.uuid}`)

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DataGrid
            onRowClick={handleRowClick}
            rows={filtredOrders}
            {...filtredOrders}
            pagination
            columns={columns}
            paginationMode="client"
            loading={loading}
            pageSize={10}
            autoHeight={true}
            disableSelectionOnClick={true}
            checkboxSelection={false}
            autoPageSize={false}
            getRowId={(item) => item.uuid}
            localeText={rowsTableTranslate}
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}
