import React, { useEffect, useRef, useState } from 'react'

import { makeStyles, TextField } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { successColor } from 'assets/jss/material-dashboard-react'
import CustomInput from 'components/CustomInput/CustomInput'
import { CloudUpload } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import Spiner from 'components/Spiner/Spiner'
import { useHelper } from 'shared/hooks/useHelper'
import { CategoryHelper } from 'services/helpers/CategoryHelper'
import { BannerHelper } from 'services/helpers/BannerHelper'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import { bannersGroupsSelector } from 'redux/selectors/BannersSelectors'
import { promosSelector } from 'redux/selectors/BannersSelectors'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInput-underline:after ': {
      borderColor: successColor[0]
    },
    '& .MuiFormLabel-root': {
      color: '#c7c7c7',
      fontSize: 14,
      fontWeight: '500'
    },
    '& .MuiInputBase-root': {
      borderColor: '#c7c7c7'
    }
  },
  buttonAdd: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    zIndex: 999
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  borderImg: {
    borderWidth: 1,
    borderColor: successColor[0],
    borderRadius: 10,
    maxWidth: '100%',
    maxHeight: 200
  },
  imgButton: {
    paddingRight: 10,
    paddingLeft: 10
  },
  paddingImg: {
    paddingRight: 10
  },
  buttons: {
    paddingBottom: 60
  }
}))

export const BannersCreateWidget = () => {
  const classes = useStyles()
  const categoryHelper = useHelper(CategoryHelper)
  const bannerHelper = useHelper(BannerHelper)
  const [buttons, setButtons] = useState(true)
  const [groupName, setGroupName] = useState('')
  const [bannerName, setBannerName] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [image, setImage] = useState('')
  const [bannerGroupId, setBannerGroupId] = useState('')
  const [bannerIndex, setBannerIndex] = useState(null)
  const [bannerPromo, setBannerPromo] = useState(null)
  const imageRef = useRef()
  const promos = useSelector(promosSelector)
  const groups = useSelector(bannersGroupsSelector)
  const loading = useSelector(appLoadingSelector)
  const categories = useSelector((state) => state.stores.categories)

  useEffect(() => {
    bannerHelper.setGroups()
  }, [])

  useEffect(() => {
    categoryHelper.getCategories()
  }, [])

  if (loading) {
    return <Spiner />
  }

  if (loading) {
    setButtons(false)
  }

  const handleSumbitGroupName = () => {
    setButtons(false)
    bannerHelper.createGroup(groupName)
  }

  const handleCancelGroupName = () => {
    setGroupName('')
  }

  const handleSumbitBanner = () => {
    const data = {
      bannerGroupId,
      position: Number(bannerIndex) || 0,
      image,
      categoryId: categoryId || categories[0].categoryId,
      codeRule: bannerPromo?.codeRule,
      name: bannerName || bannerPromo?.title
    }
    setButtons(false)
    bannerHelper.createBanner(data)
  }

  const handleImage = (image) => {
    if (!image) return

    const reader = new FileReader()
    reader.onloadend = () => {
      if (imageRef.current) {
        imageRef.current.src = reader.result
      }

      setImage(reader.result)
    }
    reader.onerror = console.error
    reader.readAsDataURL(image)
  }

  console.log('promos', promos)
  console.log('groups', groups)
  console.log('categories', categories)

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomInput
          value={groupName}
          onChange={(e) => {
            setButtons(true)
            setGroupName(e.target.value)
          }}
          labelText="Створити категорію банерів"
          id={'22'}
          formControlProps={{
            fullWidth: true
          }}
        />
        {buttons && (
          <div className={classes.buttons}>
            <Button onClick={handleCancelGroupName}>Скасувати</Button>
            <Button color="success" onClick={handleSumbitGroupName}>
              Зберегти
            </Button>
          </div>
        )}
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.row}>
          <CustomInput
            onChange={(e) => {
              setBannerName(e.target.value)
              setButtons(true)
            }}
            labelText="Назва Баннеру"
            id={'22'}
            formControlProps={{
              fullWidth: true
            }}
          />
          <Autocomplete
            className={classes.root}
            style={{ width: 300, paddingTop: 15, paddingLeft: 20 }}
            id="combo-box-demo"
            options={groups}
            onChange={(e, value) => {
              setBannerGroupId(value.uuid)
            }}
            getOptionLabel={(option) => option.name}
            key={({ uuid }) => uuid}
            renderInput={(params) => (
              <TextField {...params} label="Оберіть категорію банеру" />
            )}
          />
          <div style={{ width: 300, paddingLeft: 20 }}>
            <CustomInput
              onChange={(e) => {
                setBannerIndex(e.target.value)
                setButtons(true)
              }}
              labelText="Номер Баннеру"
              id={'22'}
              formControlProps={{
                fullWidth: true
              }}
            />
          </div>
          <div className={classes.imgButton}>
            <Button color="transparent" round component="label">
              <picture className={classes.paddingImg}>
                <source srcSet={''} />
                <img
                  ref={imageRef}
                  className={classes.borderImg}
                  alt="noImg"
                  src="https://allretail.ua/static/files/thumbs/1162x672/8/44/4afc93a8-5ea7fd4c4dbadd73c3c84090794ee448.jpg?v=9371"
                />
              </picture>
              <CloudUpload />
              <input
                accept=".jpg, .jpeg, .png"
                type="file"
                hidden
                onChange={(e) => handleImage(e.target.files[0])}
              />
            </Button>
          </div>
          <Autocomplete
            className={classes.root}
            style={{ width: 300, paddingTop: 15 }}
            id="combo-box-demo"
            options={categories}
            key={({ categoryId }) => categoryId}
            getOptionLabel={(item) => item.name}
            onChange={(e, value) => {
              setCategoryId(value.categoryId)
            }}
            renderInput={(params) => (
              <TextField {...params} label="Оберіть категорію товарів" />
            )}
          />
        </div>

        {buttons && (
          <div className={classes.buttons}>
            <Button>Скасувати</Button>
            <Button color="success" onClick={handleSumbitBanner}>
              Зберегти
            </Button>
          </div>
        )}
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.row}>
          <Autocomplete
            className={classes.root}
            style={{ width: 300, paddingTop: 15, paddingLeft: 20 }}
            id="combo-box-demo"
            options={promos}
            onChange={(e, value) => {
              setBannerPromo(value)
            }}
            getOptionLabel={(option) => option.title}
            key={({ codeRule, title }) => codeRule || title}
            renderInput={(params) => (
              <TextField {...params} label="Оберіть Акцію" />
            )}
          />
          <Autocomplete
            className={classes.root}
            style={{ width: 300, paddingTop: 15, paddingLeft: 20 }}
            id="combo-box-demo"
            options={groups}
            onChange={(e, value) => {
              setBannerGroupId(value.uuid)
            }}
            getOptionLabel={(option) => option.name}
            key={({ uuid }) => uuid}
            renderInput={(params) => (
              <TextField {...params} label="Оберіть категорію банеру" />
            )}
          />
          <div className={classes.imgButton}>
            <Button color="transparent" round component="label">
              <picture className={classes.paddingImg}>
                <source srcSet={''} />
                <img
                  ref={imageRef}
                  className={classes.borderImg}
                  alt="noImg"
                  src="https://allretail.ua/static/files/thumbs/1162x672/8/44/4afc93a8-5ea7fd4c4dbadd73c3c84090794ee448.jpg?v=9371"
                />
              </picture>
              <CloudUpload />
              <input
                accept=".jpg, .jpeg, .png"
                type="file"
                hidden
                onChange={(e) => handleImage(e.target.files[0])}
              />
            </Button>
          </div>
        </div>

        {buttons && (
          <div className={classes.buttons}>
            <Button>Скасувати</Button>
            <Button color="success" onClick={handleSumbitBanner}>
              Зберегти
            </Button>
          </div>
        )}
      </GridItem>
    </GridContainer>
  )
}
