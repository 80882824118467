import React from 'react'
import EmployeeUpdate from 'components/Modals/EmployeeUpdate'

export const columns = [
  { field: 'storeId', type: 'number', headerName: 'ID Магазину', width: 100 },
  {
    field: 'fullName',
    headerName: "Ім'я",
    width: 300
  },
  { field: 'role', headerName: 'Посада', width: 300 },
  {
    field: 'employeeId',
    type: 'number',
    headerName: 'ID Працівника',
    width: 150
  },
  {
    field: 'modal',
    headerName: 'Редагувати',
    width: 300,
    renderCell: (params) => {
      return <EmployeeUpdate {...params} />
    }
  }
]
