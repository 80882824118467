import React, { useState } from 'react'
// material-ui components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
// @material-ui/icons
import GridItem from '../../components/Grid/GridItem'
import CardHeader from '../../components/Card/CardHeader'
import GridContainer from '../../components/Grid/GridContainer'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import { CloudUpload } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import { v4 as uuidv4 } from 'uuid'

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
    fontWeight: '500'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '700',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  center: {
    textAlign: 'center',
    alignItems: 'center'
  },
  modal: {
    textAlign: 'center',
    alignItems: 'center',
    height: 'auto',
    paddingRight: 20,
    paddingLeft: 20
  },
  '@global': {
    '& .makeStyles-grid-102': {
      margin: '0 !important'
    },
    '& .MuiDialog-paper': {
      overflowY: 'none'
    }
  },
  paddingRight: {
    paddingRight: 20
  },
  paddingLeft: {
    paddingLeft: 20
  }
}

const useStyles = makeStyles(styles)

function BrandsModal(props) {
  const [modal, setModal] = useState(false)
  const classes = useStyles()

  const brandData = () => {
    return /[а-я]/i.test(props.row.title)
  }

  return (
    <div
      style={{
        textAlign: 'center',
        alignItems: 'center'
      }}
    >
      <Button color="warning" justIcon size="sm" onClick={() => setModal(true)}>
        <EditIcon />
      </Button>

      <Dialog
        style={{ alignItems: 'center' }}
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(true)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <DialogTitle id="classic-modal-slide-title">
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>{props.row.title}</h4>
                <p className={classes.cardCategoryWhite}>{props.row.brandId}</p>
              </CardHeader>
            </DialogTitle>
          </GridItem>

          <DialogContent>
            <div style={{ textAlign: 'center' }}>
              <GridContainer className={classes.cardCategoryWhite}>
                <GridItem xs={12} sm={12} md={12}>
                  <h5 style={{ textAlign: 'center' }}>Фото бренду</h5>
                  {!props.productId + '.png' ? (
                    <Button
                      variant="contained"
                      component="label"
                      className={classes.center}
                    >
                      <CloudUpload /> Додати фото
                      <input type="file" hidden />
                    </Button>
                  ) : (
                    <img
                      alt="product img"
                      sizes="(min-width: 10em) 80vw, 100vw"
                      src={`https://delivery.blyzenko.com.ua:4444/images/${props.row.brandId}.png`}
                      style={{
                        maxWidth: '80%',
                        height: 'auto'
                      }}
                    />
                  )}
                </GridItem>
              </GridContainer>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={
                    brandData(props.row.title)
                      ? props.row.title
                      : 'Назва українською'
                  }
                  id={uuidv4() + '-brand'}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={
                    !brandData(props.row.title)
                      ? props.row.title
                      : 'Назва англійською'
                  }
                  id={uuidv4() + '-category'}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>

            <DialogActions
              className={classes.modalFooter + ' ' + classes.modalFooterCenter}
            >
              <Button onClick={() => setModal(false)}>Відмінити</Button>
              <Button onClick={() => setModal(false)} color="success">
                Зберегти
              </Button>
            </DialogActions>
          </DialogContent>
        </GridContainer>
      </Dialog>
    </div>
  )
}

export default BrandsModal
