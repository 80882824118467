import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import GridItem from '../../components/Grid/GridItem'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import GridContainer from '../../components/Grid/GridContainer'
import Button from '../../components/CustomButtons/Button'
import { hist } from 'index'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import { Avatar } from '@material-ui/core'
import { useHelper } from 'shared/hooks/useHelper'
import { ClientHelper } from 'services/helpers/ClientHelper'

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: 0,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
    fontWeight: '500'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: 0,
    minHeight: 'auto',
    fontWeight: '600',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: 3,
    textDecoration: 'none'
  },
  center: {
    textAlign: 'center',
    alignItems: 'center'
  },
  modal: {
    textAlign: 'center',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'column'
  },
  '@global': {
    '.makeStyles-grid-114': {
      margin: '0 !important'
    },
    '.makeStyles-grid-111': {
      justifyContent: 'center'
    }
  },
  headerContainer: {
    width: '92%',
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    width: 250
  }
}

const useStyles = makeStyles(styles)

const hash = Math.random()

function ClientUpdate(props) {
  const [modal, setModal] = useState(false)
  const clientsService = useHelper(ClientHelper)
  const classes = useStyles()
  return (
    <>
      <div style={{ display: 'flex', flex: 1 }}>
        <Button
          color="warning"
          justIcon
          size="sm"
          onClick={() => setModal(true)}
        >
          <HowToRegIcon />
        </Button>
      </div>

      <Dialog
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          flex: 1,
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden'
        }}
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <Avatar
            style={{ width: 150, height: 150 }}
            src={`https://delivery.blyzenko.com.ua/assets/users/${props.row.uuid}.png?hash=${hash}`} //many 404 status, need  to handle it
          />
        </div>
        <GridContainer>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.headerContainer}
          >
            <CardHeader color="success" className={classes.header}>
              <h4 className={classes.cardTitleWhite}>
                {props.row.name} {props.row.lastName}
              </h4>
              <p className={classes.cardCategoryWhite}>
                Статус: {props.row.isVip ? 'VIP' : 'Користувач'}
              </p>
            </CardHeader>
          </DialogTitle>
          <DialogContent>
            <CardBody>
              <GridContainer>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 15,
                    paddingBottom: 15
                  }}
                >
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        hist.push(`/admin/feedbacks`)
                      }}
                    >
                      <h4 className={classes.cardTitleWhite}>
                        Запити користувача
                      </h4>
                    </Button>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        setModal(false)
                        hist.push({
                          pathname: `/admin/maps`,
                          state: props.row.addresses
                        })
                      }}
                      color="success"
                    >
                      <h4 className={classes.cardTitleWhite}>
                        Адреси користувача
                      </h4>
                    </Button>
                  </GridItem>
                </div>
              </GridContainer>
              <GridContainer>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 15,
                    paddingBottom: 15
                  }}
                >
                  <GridItem xs={6} sm={6} md={6}>
                    {props.row.isBanned ? (
                      <Button
                        onClick={() => {
                          setModal(false)
                          clientsService.unbanUser(props.row.uuid)
                        }}
                        color="success"
                        className={classes.button}
                      >
                        <h4 className={classes.cardTitleWhite}>Розблокувати</h4>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setModal(false)
                          clientsService.banUser(props.row.uuid)
                        }}
                        color="danger"
                        className={classes.button}
                      >
                        <h4 className={classes.cardTitleWhite}>Блокувати</h4>
                      </Button>
                    )}
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {props.row.isVip ? (
                      <Button
                        className={classes.button}
                        onClick={() => {
                          setModal(false)
                          clientsService.unvipUser(props.row.uuid)
                        }}
                        color="danger"
                      >
                        <h4 className={classes.cardTitleWhite}>
                          {' '}
                          скасувати VIP{' '}
                        </h4>
                      </Button>
                    ) : (
                      <Button
                        className={classes.button}
                        onClick={() => {
                          setModal(false)
                          clientsService.vipUser(props.row.uuid)
                        }}
                        color="warning"
                      >
                        <h4 className={classes.cardTitleWhite}> надати VIP </h4>
                      </Button>
                    )}
                  </GridItem>
                </div>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      paddingTop: 15
                    }}
                  >
                    <Button onClick={() => setModal(false)}>Скасувати</Button>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </DialogContent>
        </GridContainer>
      </Dialog>
    </>
  )
}

export default ClientUpdate
