import { AbstractRequest } from 'services/abstract/AbstractRequest'

export const feedbacksLimit = Number.MAX_SAFE_INTEGER

export class FeedbackApiService extends AbstractRequest {
  constructor() {
    super(`/feedbacks`)
  }

  async getFeedbacks(skip) {
    const { data, status } = await this.get(this.baseUrl, {
      params: {
        skip,
        limit: feedbacksLimit
      }
    })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data)
  }
}
