import { setLoading } from 'redux/reducers/appReducer'
import { removeUserData } from 'redux/reducers/userReducer'
import { setUserData } from 'redux/reducers/userReducer'
import { userSelector } from 'redux/selectors/userSelector'
import { AuthApiService } from 'services/api/AuthApiService'
import { StatefullService } from './StatefullService'

export class AuthHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.authApiService = new AuthApiService()
  }

  static make() {
    if (!AuthHelper.instance) {
      AuthHelper.instance = new AuthHelper()
    }
    return AuthHelper.instance
  }

  setUserData(data) {
    window.localStorage.setItem('user', JSON.stringify(data))
    this._dispatch(setUserData(data))
  }

  getUserData() {
    const data = this._select(userSelector)
    if (data) return data
    return JSON.parse(window.localStorage.getItem('user'))
  }

  async login(phone, password) {
    const { isSuccess, data } = await this._callApi(
      this.authApiService.login.bind(this.authApiService),
      setLoading,
      {
        phone,
        password
      }
    )
    if (isSuccess) {
      this.setUserData(data)
      return data
    }
    return null
  }

  isAuthorized() {
    return this.authApiService.isAuthorized()
  }

  isUserExist() {
    const user = this._select(userSelector)

    return !!user
  }

  logout() {
    this.authApiService.logout()
    this._dispatch(removeUserData())
  }
}
