import {} from 'redux/reducers/bonusReducer'
import {
  setCount as setBonusesCount,
  addBonuses,
  setBonuses as setBonusesReducer,
  setLoading
} from 'redux/reducers/bonusReducer'
import { BonusApiService } from 'services/api/BonusApiService'
import { StatefullService } from './StatefullService'

export class BonusHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.bonusApiService = new BonusApiService()
  }

  static make() {
    if (!BonusHelper.instance) {
      BonusHelper.instance = new BonusHelper()
    }
    return BonusHelper.instance
  }

  async addBonuses(skip, employeeId) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.bonusApiService.addBonuses.bind(this.bonusApiService),
      setLoading,
      { skip, employeeId }
    )
    if (isSuccess) {
      this._dispatch(addBonuses(data))
      return data
    }
    return null
  }

  async setBonuses(employeeId) {
    const {
      isSuccess,
      data
    } = await this._callApi(
      this.bonusApiService.setBonuses.bind(this.bonusApiService),
      setLoading,
      { employeeId }
    )
    if (isSuccess) {
      this._dispatch(setBonusesReducer(data))
      return data
    }
    return null
  }

  async setCount(employeeId) {
    const { isSuccess, data } = await this._callApi(
      this.bonusApiService.countBonuses.bind(this.bonusApiService),
      null,
      employeeId
    )
    if (isSuccess) {
      this._dispatch(setBonusesCount(data.count))
      return data
    }
    return null
  }
}
