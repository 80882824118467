import * as React from 'react'
import {
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

export const GroupsTable = ({ groups, onChange }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Налаштування пріорітету групп">
        <TableHead>
          <TableRow>
            <TableCell>Назва</TableCell>
            <TableCell>Пріорітет</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group) => (
            <TableRow key={group.groupId}>
              <TableCell component="th" scope="row">
                {group.name}
              </TableCell>
              <TableCell>
                <Input
                  type="number"
                  defaultValue={group.settings?.priority || 0}
                  onBlur={(e) =>
                    onChange({
                      ...group,
                      settings: {
                        ...group.settings,
                        priority: Number(e.target.value)
                      }
                    })
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
