import { SettingsDeadlines } from '../shared/enums'
export const orderStatus = {
  created: 'Нове',
  waiting: 'Очікує',
  pickup: 'Комплектація',
  deliver: 'Доставка',
  return: 'Повернення коштів',
  check: 'Звірка товарів',
  canceled: 'Скасоване',
  completed: 'Замовлення завершено',
  self: 'Очікує на самовивіз',
  pickuped: 'Комплектування завершено',
  delivered: "Поверненя кур'єра",
  checked: 'Перевірено',
  discarded: 'Скасовано',
  replacing: 'Заміни',
  'delivery-check': 'Доставлено'
}

export const payment = {
  card: 'Картка',
  cash: 'Готівка'
}

export const deliveryAction = {
  courier: `Кур'єр`,
  self: 'Самовивіз'
}

export const noProductsActionTranslate = {
  offer: 'Надіслати список пропозицій',
  phone: 'Зателефонувати'
}

export const noFeedbackActionTranslate = {
  cancel: 'Скасувати',
  relative: 'Замінити схожими'
}

export const rolesTranslate = {
  Packer: 'Пакувальник',
  Courier: "Кур'єр",
  AdministratorVoz: 'Адміністратор ВОЗ'
}

export const SettingsDeadlinesTranslate = Object.freeze({
  [SettingsDeadlines.Ordering]: 'Прийняття замовлення',
  [SettingsDeadlines.Packing]: 'Комплектування',
  [SettingsDeadlines.Delivering]: 'Прийняття доставки',
  [SettingsDeadlines.Compliting]: 'Доставка',
  [SettingsDeadlines.Pricing]: 'Порернення курьера/Видача клієнту'
})
