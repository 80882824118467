import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import GridItem from '../../components/Grid/GridItem'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import Button from '../../components/CustomButtons/Button'
import EditIcon from '@material-ui/icons/Edit'

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
    fontWeight: '500'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '700',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  },
  center: {
    textAlign: 'center',
    alignItems: 'center'
  },
  modal: {
    textAlign: 'center',
    alignItems: 'center',
    height: 'auto'
  },
  '@global': {
    '.makeStyles-grid-102': {
      margin: '0 !important'
    }
  }
}

const useStyles = makeStyles(styles)

export const ProductModal = (props) => {
  const [modal, setModal] = useState(false)
  const classes = useStyles()
  return (
    <div className={classes.center}>
      <Button color="warning" justIcon size="sm" onClick={() => setModal(true)}>
        <EditIcon />
      </Button>

      <Dialog
        className={classes.center}
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(true)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <Card>
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>{props.row.name}</h4>
              <p className={classes.cardCategoryWhite}>{props.row.vendorId}</p>
            </CardHeader>
          </DialogTitle>
          <DialogContent>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <h5 className={classes.center}>Фото товару</h5>

                <img
                  alt="product img"
                  sizes="(min-width: 10em) 80vw, 100vw"
                  src={`https://delivery.blyzenko.com.ua/assets/products/${props.row.productId}.png`}
                  style={{
                    maxWidth: '80%',
                    height: 'auto'
                  }}
                />
              </GridItem>
            </CardBody>
            <Button onClick={() => setModal(false)}>Закрити</Button>
          </DialogContent>
        </Card>
      </Dialog>
    </div>
  )
}
