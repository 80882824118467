import React, { useEffect, useState } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Grid, IconButton } from '@material-ui/core'
import Spiner from 'components/Spiner/Spiner'
import { StaticPagesItem } from 'components/StaticPages/StaticPagesItem'
import { useHelper } from 'shared/hooks/useHelper'
import { StaticPageHelper } from 'services/helpers/StaticPageHelper'
import { useSelector } from 'react-redux'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import { staticPagesSelector } from 'redux/selectors/staticPagesSelector'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { successColor } from 'assets/jss/material-dashboard-react'

function StaticPages() {
  const loading = useSelector(appLoadingSelector)
  const [changedPage, setChangedPage] = useState(null)
  const staticPageHelper = useHelper(StaticPageHelper)
  const staticPages = useSelector(staticPagesSelector)

  useEffect(() => {
    staticPageHelper.setStaticPages()
  }, [])

  const handleSave = () => {
    if (loading) return

    staticPageHelper.updateStaticPage(changedPage)
  }

  const handleCancel = () => {
    if (loading) return
    setChangedPage(null)
  }

  const handleAddNewPage = () => {
    const page = {
      content: '',
      name: new Date().toISOString(),
      title: ''
    }
    staticPageHelper.createStaticPage(page)
  }

  const handleChange = (value, index) => {
    if (loading) return
    if (changedPage && changedPage.index === index) {
      const page = { ...changedPage, content: value, index }
      setChangedPage(page)
    } else {
      const page = { ...staticPages[index], content: value, index }
      setChangedPage(page)
    }
  }

  const handleChangeTitle = (value, index) => {
    if (loading) return
    if (changedPage && changedPage.index === index) {
      const page = { ...changedPage, title: value, index }
      setChangedPage(page)
    } else {
      const page = { ...staticPages[index], title: value, index }
      setChangedPage(page)
    }
  }

  const handleDeletePage = (index) => {
    staticPageHelper.removeStaticPage(staticPages[index].uuid)
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {staticPages.map(({ uuid, title, content }, i) => {
          return (
            <StaticPagesItem
              id={uuid}
              key={uuid}
              content={
                (uuid === changedPage?.uuid && changedPage?.content) || content
              }
              onDelete={() => handleDeletePage(i)}
              label={title}
              showNav={(prev, next) => next && next !== prev}
              onChange={(value) => handleChange(value, i)}
              contentTitle={
                (uuid === changedPage?.uuid && changedPage?.title) || title
              }
              onChangeTitle={(value) => handleChangeTitle(value, i)}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          )
        })}
      </GridItem>

      <Grid container justify="center" alignItems="center">
        {loading && <Spiner />}
      </Grid>
      <Grid
        container
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <IconButton
          onClick={handleAddNewPage}
          aria-label="add"
          size="medium"
          style={{
            display: 'flex',

            justifyContent: 'flex-end'
          }}
        >
          <AddCircleIcon
            style={{ height: 50, width: 50, color: successColor[0] }}
          />
        </IconButton>
      </Grid>
    </GridContainer>
  )
}

export default StaticPages
