import { StatefullService } from './StatefullService'

export class FilterHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
  }

  static make() {
    if (!FilterHelper.instance) {
      FilterHelper.instance = new FilterHelper()
    }
    return FilterHelper.instance
  }

  setFilterData(key, value) {
    return { [key]: value }
  }

  buildFilter(obj = {}) {
    const filter = {}

    if (obj.status) {
      filter.status = Array.isArray(obj.status)
        ? { $in: obj.status }
        : obj.status
    }
    return filter
  }
}
