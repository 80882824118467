import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  skip: 0,
  loading: false,
  limit: 10,
  phone: [],
  email: [],
  clients: [],
  total: Number.MAX_SAFE_INTEGER,
  banned: {
    clients: [],
    total: Number.MAX_SAFE_INTEGER,
    skip: 0
  },
  vip: {
    clients: [],
    total: Number.MAX_SAFE_INTEGER,
    skip: 0
  }
}

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setTotal(state, action) {
      state.total = action.payload
    },
    addClientsSuccess(state, action) {
      state.clients = state.clients.concat(action.payload)
      state.skip += action.payload.length
      state.loading = false
    },
    addClientsRequest(state) {
      state.loading = true
    },
    addClientsFailure(state) {
      state.loading = false
    },
    updateClient(state, action) {
      const user = action.payload
      const userIndex = state.clients.findIndex(
        ({ uuid }) => uuid === user.uuid
      )
      if (userIndex >= 0) {
        state.clients[userIndex] = user
      }
    },
    setBannedTotal(state, action) {
      state.banned.total = action.payload
    },
    addBanned(state, action) {
      action.payload = action.payload.filter(
        ({ uuid }) => !state.banned.clients.some((user) => uuid === user.uuid)
      )
      state.banned.clients = state.banned.clients.concat(action.payload)
      state.banned.skip += action.payload.length
    },
    removeBannedById(state, action) {
      const userIndex = state.banned.clients.findIndex(
        ({ uuid }) => uuid === action.payload.uuid
      )
      if (userIndex >= 0) {
        state.banned.clients.splice(userIndex, 1)
        state.banned.total -= 1
      }
    },
    setVipTotal(state, action) {
      state.vip.total = action.payload
    },
    addVip(state, action) {
      action.payload = action.payload.filter(
        ({ uuid }) => !state.banned.clients.some((user) => uuid === user.uuid)
      )
      state.vip.clients = state.vip.clients.concat(action.payload)
      state.vip.skip += action.payload.length
    },
    removeVipById(state, action) {
      const userIndex = state.vip.clients.findIndex(
        ({ uuid }) => uuid === action.payload.uuid
      )
      if (userIndex >= 0) {
        state.vip.clients.splice(userIndex, 1)
        state.vip.total -= 1
      }
    }
  }
})

const { reducer, actions } = clientsSlice
export default reducer
export const {
  setTotal,
  updateClient,
  addClientsSuccess,
  addClientsRequest,
  addClientsFailure,
  setLoading,
  removeVipById,
  addVip,
  setVipTotal,
  removeBannedById,
  addBanned,
  setBannedTotal
} = actions
