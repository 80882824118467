/*eslint-disable*/
import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import CustomTabs from 'components/CustomTabs/CustomTabs'
import NewOrders from './orders/NewOrders'
import OverdueStatuses from './overdueStatuses/OverdueStatuses'

import WarningIcon from '@material-ui/icons/Warning'
import FiberNewIcon from '@material-ui/icons/FiberNew'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#fff',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '400',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  }
}

const useStyles = makeStyles(styles)

export default function Notifications() {
  const classes = useStyles()

  return (
    <GridContainer>
      <CustomTabs
        headerColor="success"
        tabs={[
          {
            tabName: 'Протерміновані статуси',
            tabIcon: WarningIcon,
            tabContent: <OverdueStatuses />
          },
          {
            tabName: 'Замовленно вперше',
            tabIcon: FiberNewIcon,
            tabContent: <NewOrders />
          }
        ]}
      />
    </GridContainer>
  )
}
