import { OrderStatus } from 'helpers/enums'

export class OrdersResolver {
  constructor() {}

  activeOrders(data) {
    return data.filter((order) => order.status !== OrderStatus.Canceled)
  }

  canceledOrders(data) {
    return data.filter((order) => order.status === OrderStatus.Canceled)
  }
}
