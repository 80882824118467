import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setWindowFocus } from 'redux/reducers/appReducer'

export const WindowFocus = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('focus', (e) => {
      dispatch(setWindowFocus(true))
    })
    window.addEventListener('blur', (e) => {
      dispatch(setWindowFocus(false))
    })
  }, [])

  return children
}
