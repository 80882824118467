import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { ErrorHandler } from 'services/ErrorHandler'
import { config } from 'config'
import { CredentialsManager } from 'services/utils/CredentialsManager'

export class AbstractRequest {
  // should be setup by AuthApiService
  static accessToken = ''
  static refreshToken = ''

  constructor(path = '') {
    this.minErrorStatus = 400
    this._credentials = new CredentialsManager()
    this.handler = new ErrorHandler()
    this.baseUrl = `${config.api}${path}`
    this.api = axios.create()
    this.api.interceptors.request.use(
      this._accessTokenInterceptor.bind(this),
      this.handler.apiError.bind(this.handler)
    )

    this.api.interceptors.response.use(
      (response) => response,
      this._retryInterceptor.bind(this)
    )
  }

  _getAccessToken() {
    if (AbstractRequest.accessToken) return AbstractRequest.accessToken
    const access = this._credentials.getAccess()
    AbstractRequest.accessToken = access || ''
    return AbstractRequest.accessToken
  }

  _getRefreshToken() {
    if (AbstractRequest.refreshToken) return AbstractRequest.refreshToken
    const refresh = this._credentials.getRefresh()
    AbstractRequest.refreshToken = refresh || ''
    return AbstractRequest.refreshToken
  }

  _setCredentials(access, refresh) {
    AbstractRequest.accessToken = access
    AbstractRequest.refreshToken = refresh
    this._credentials.setCredentials(access, refresh)
  }

  _removeCredentials() {
    AbstractRequest.accessToken = ''
    AbstractRequest.refreshToken = ''
    this._credentials.removeCredentials()
  }

  _accessTokenInterceptor(config) {
    config.headers = {
      Authorization: `Bearer ${this._getAccessToken()}`,
      Accept: 'application/json'
    }
    return config
  }

  async _retryInterceptor(error) {
    const originalRequest = error.config
    if (error.response) {
      const { status } = error.response
      if (status === StatusCodes.UNAUTHORIZED && !originalRequest._retry) {
        originalRequest._retry = true
        const endpoint = `${config.api}/auth/refresh`
        try {
          const refresh = this._getRefreshToken()
          const { status, data } = await this.api.post(endpoint, {
            refreshToken: refresh
          })
          if (status === StatusCodes.OK) {
            this._setCredentials(data.data.access, refresh)
            originalRequest.headers.Authorization = `Bearer ${data.data.access}`
            return await this.api(originalRequest)
          }

          throw error
        } catch (e) {
          return this.handler.apiError(e)
        }
      }
    }
    return this.handler.apiError(error)
  }

  _returnSuccess(data) {
    return {
      isSuccess: true,
      data
    }
  }

  _returnFailure(status, errors) {
    return {
      isSuccess: false,
      errors,
      status
    }
  }

  get(...args) {
    return this.api.get(...args)
  }

  post(...args) {
    return this.api.post(...args)
  }

  put(...args) {
    return this.api.put(...args)
  }

  patch(...args) {
    return this.api.patch(...args)
  }

  delete(...args) {
    return this.api.delete(...args)
  }
}
