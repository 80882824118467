import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class StaticPagesApiService extends AbstractRequest {
  constructor() {
    super(`/pages`)
  }

  async getStaticPages() {
    const { data, status } = await this.get(`${this.baseUrl}/`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async removeStaticPage(id) {
    const { data, status } = await this.delete(`${this.baseUrl}/${id}`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async putStaticPage(incomingData) {
    const { data, status } = await this.put(`${this.baseUrl}/`, incomingData)
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }
}
