import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class SettingsApiService extends AbstractRequest {
  constructor() {
    super(`/settings/global`)
  }

  async putSettings(incomingData) {
    const { data, status } = await this.put(this.baseUrl, incomingData)
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }

  async getSettings() {
    const { data, status } = await this.get(`${this.baseUrl}/`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }
}
