import { config } from 'config'
import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class AuthApiService extends AbstractRequest {
  constructor() {
    super('/auth')
  }

  async login({ phone, password }) {
    const { data, status } = await this.post(`${config.api}/employees/login`, {
      phone,
      password
    })
    if (status < this.minErrorStatus) {
      const { access, refresh } = data.data
      this._setCredentials(access, refresh)
      return this._returnSuccess(data.data)
    }
    return this._returnFailure(status, data.messages)
  }

  isAuthorized() {
    return this._credentials.hasCredentials()
  }

  logout() {
    const refreshToken = this._getRefreshToken()
    this._removeCredentials()
    this.post(`${this.baseUrl}/revoke`, {
      refreshToken
    })
  }
}
