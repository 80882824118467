import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import TableListComp from 'components/TableList/TableListComp'
import { columns } from './columns'
import { Limits } from 'shared/enums'
import { useHelper } from 'shared/hooks/useHelper'
import { ProductHelper } from 'services/helpers/ProductHelper'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import { StoreHelper } from 'services/helpers/StoreHelper'
import CustomTabs from 'components/CustomTabs/CustomTabs'
import { DataGrid } from '@material-ui/data-grid'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { nonImageProductsSelector } from 'redux/selectors/nonImageProducts'

const storesLimit = Limits.StoresLimit

const getRows = (store) => {
  return store.products
    ? store.products.filter(({ productId }) => productId)
    : []
}

export default function Products() {
  const productsHelper = useHelper(ProductHelper)
  const storesState = useSelector((state) => state.stores)
  const loading = useSelector(appLoadingSelector)
  const storeHelper = useHelper(StoreHelper)
  const nonImageProducts = useSelector(nonImageProductsSelector)

  const handleAddStores = () => {
    storeHelper.setStores(storesState.skip, storesLimit)
  }

  useEffect(() => {
    productsHelper.nonImageProducts()
  }, [productsHelper])

  // if paggination don't needed delete it
  // const handlePageSelect = (shop, prevPage, nextPage) => {
  //   productsHelper.setProductsToShop(shop, prevPage < nextPage)
  // }

  const handleListToggle = (shop) => {
    const { products } = shop
    if (!products || products.length) {
      productsHelper.setProductsToShop(shop, true)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    storeHelper.countStores()
    storeHelper.setStores(0, Number.MAX_SAFE_INTEGER)
  }, [])
  /* eslint-enable */

  if (!storesState.stores.length) return null
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="success"
          tabs={[
            {
              tabName: 'Продукти',
              tabContent: (
                <TableListComp
                  title={(store) => `Магазин ${store.shopNumber}`}
                  subtitle={(store) => store.address}
                  extractRows={getRows}
                  extractId={(product) => product.productId || uuidv4()}
                  data={storesState.stores}
                  columnsTable={columns}
                  total={storesState.total}
                  // if paggination don't needed delete it
                  // onPageSelected={handlePageSelect}
                  onListToggle={handleListToggle}
                  onLoadMore={handleAddStores}
                  isLoading={loading}
                />
              )
            },
            {
              tabName: 'Продукти без фото',
              tabContent: (
                <DataGrid
                  rows={nonImageProducts}
                  pagination
                  columns={columns}
                  paginationMode="client"
                  rowCount={nonImageProducts.length}
                  loading={loading}
                  pageSize={10}
                  autoHeight={true}
                  disableSelectionOnClick={true}
                  checkboxSelection={false}
                  autoPageSize={false}
                  getRowId={({ producId }) => producId || uuidv4()}
                  localeText={rowsTableTranslate}
                />
              )
            }
          ]}
        />
      </GridItem>
    </GridContainer>
  )
}
