import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  skip: 0,
  loading: false,
  total: 0,
  stores: [],
  categories: []
}

const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload
    },
    replaceStore(state, action) {
      const store = action.payload
      const stores = [...state.stores]
      const index = state.stores.findIndex(({ uuid }) => uuid === store.uuid)
      if (index >= 0) {
        stores.splice(index, 1, store)
        state.stores = stores
      }
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setTotal(state, action) {
      state.total = action.payload
    },
    setStores(state, action) {
      state.stores = action.payload
      state.loading = false
    },
    addStoresSuccess(state, action) {
      state.stores = state.stores.concat(action.payload)
      state.skip += action.payload.length
      state.loading = false
    },
    addStoresRequest(state) {
      state.loading = true
    },
    addStoresFailure(state) {
      state.loading = false
    },
    setStoreSettings(state, action) {
      const target = state.stores.find(
        ({ uuid }) => uuid === action.payload.storeId
      )
      if (target) {
        target.settings = action.payload.settings
      }
    }
  }
})

const { reducer, actions } = storesSlice
export default reducer
export const {
  addStoresSuccess,
  addStoresRequest,
  addStoresFailure,
  replaceStore,
  setLoading,
  setTotal,
  setStoreSettings,
  setCategories,
  setStores
} = actions
