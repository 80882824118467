import { AbstractRequest } from 'services/abstract/AbstractRequest'

const limit = Number.MAX_SAFE_INTEGER
const skip = 0

export class CategoriesApiService extends AbstractRequest {
  constructor() {
    super(`/categories`)
  }

  async getCategories() {
    const params = {
      skip,
      limit
    }
    const { data, status } = await this.get(this.baseUrl, { params })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }
}
