import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DataGrid } from '@material-ui/data-grid'
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
  makeStyles,
  Grid
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import {
  noFeedbackActionTranslate,
  rolesTranslate,
  noProductsActionTranslate
} from 'helpers/translates'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { OrderStatus } from 'helpers/enums'

const stableQuantityStatuses = [
  OrderStatus.Deliver,
  OrderStatus.Checked,
  OrderStatus.Delivered,
  OrderStatus.Completed
]

const isFloat = (num) => {
  return Number(num) === num && num % 1 !== 0
}

const styles = {
  '@global': {
    '.MuiAccordionSummary-expandIcon': {
      color: green[500]
    }
  },
  root: {
    borderTopWidth: 1,
    borderTopColor: green[500]
  },
  titleText: {
    fontWeight: '600',
    fontSize: 19
  },
  keyText: {
    fontWeight: '500',
    fontSize: 17
  },
  mainContentContainer: {
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 10
  },
  mainItem: {
    textAlign: 'center'
  },
  valueText: {
    fontWeight: '500',
    fontSize: 15,
    color: green[800]
  }
}

let status

const useStyles = makeStyles(styles)
const columns = [
  { field: 'productId', headerName: 'ID товару', width: 150 },
  {
    field: 'name',
    headerName: 'Назва',
    width: 450
  },
  {
    field: 'quantity',
    headerName: 'Кількість',
    width: 200,
    renderCell: (params) => {
      const stableQuantity =
      params.row.deliveredQuantity || params.row.pickQuantity || params.row.quantity || 0
  
    const multiplicityDelivery = params.row.multiplicityDelivery || 1

    const quantity = stableQuantityStatuses.includes(status)
    ? stableQuantity
    : stableQuantity * multiplicityDelivery

  const quantityToShow = isFloat(quantity)
    ? `${quantity.toFixed(2)} ${params.row.measurementUnit}`
    : params.row.measurementUnit === 'шт'
    ? `${quantity.toFixed(2)} ${params.row.measurementUnit}`
    : `${quantity.toFixed(2)} кг`

      return `${quantityToShow}`
    }
  },

  {
    field: 'price',
    headerName: 'Ціна за одиницю/100г',
    width: 200,
    renderCell: (params) => {
   
      return `${params.row.price.toFixed(2)} ₴`
    }
  },
  {
    field: 'sum',
    headerName: 'Вартість',
    width: 200,
    renderCell: (params) => {
      const price =
        params.row.measurementUnit === 'кг'
          ? params.row.price * 10
          : params.row.price
          
      const priotityQuantity =
        params.row.deliveredQuantity ||
        params.row.pickQuantity ||
        params.row.quantity
        
      const quantity = 
        params.row.measurementUnit === 'кг'
          ? priotityQuantity * 0.1
          : priotityQuantity
      return `${(price * quantity).toFixed(2)} ₴`
    }
  }
]



export const AccordionOrder = ({ order }) => {
  const [accordion, setAccordion] = useState(false)
  const classes = useStyles()
  status = order.status
  return (
    <Accordion expanded={accordion} onChange={() => setAccordion(!accordion)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid item lg={5} md={5} sm={5} xs={12}>
          <Typography className={classes.titleText}>
            Переглянути усе замовлення
          </Typography>
        </Grid>
        {order.total > 0 && (
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <Typography className={classes.titleText}>
              Ціна замовлення: {order.total.toFixed(2)} ₴
            </Typography>
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <Grid container className={classes.mainContentContainer}>
          <Grid container direction="row" justifyContent="space-around">
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div>
                <Typography className={classes.keyText}>
                  Працівники які працювали:
                </Typography>
              </div>

              {order.employees.length && (
                <div>
                  {order.employees.map((employee, i) => {
                    return (
                      <Typography
                        key={`${employee.deviceId}-${employee.lastName}`}
                        className={classes.valueText}
                      >
                        {i + 1}. {rolesTranslate[employee.role]},{' '}
                        {employee.firstName} {employee.lastName}, ID:{' '}
                        {employee.employeeId}
                      </Typography>
                    )
                  })}
                </div>
              )}
            </Grid>
            <Grid
              item
              className={classes.mainItem}
              lg={4}
              md={4}
              sm={4}
              xs={12}
            >
              <Typography className={classes.keyText}>
                Якщо не вийшов на зв'язок:
              </Typography>
              <Typography className={classes.valueText}>
                {noFeedbackActionTranslate[order.noFeedbackAction]}
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.mainItem}
              lg={4}
              md={4}
              sm={4}
              xs={12}
            >
              <Typography className={classes.keyText}>
                Cпоосіб заміни:
              </Typography>
              <Typography className={classes.valueText}>
                {noProductsActionTranslate[order.noProductsAction]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.mainContentContainer}>
            <Grid
              item
              className={classes.mainItem}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Typography className={classes.titleText}>Продукти:</Typography>
            </Grid>

            {order.products.length && (
              <DataGrid
                rows={order.products}
                {...order.products}
                pagination
                columns={columns}
                paginationMode="client"
                pageSize={10}
                autoHeight={true}
                disableSelectionOnClick={true}
                checkboxSelection={false}
                autoPageSize={false}
                getRowId={(item) => item.uuid}
                localeText={rowsTableTranslate}
              />
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
