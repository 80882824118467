import { format, formatDistance, intervalToDuration } from 'date-fns'
import { uk } from 'date-fns/esm/locale'

export const TimesUtils = {
  getTimer(start) {
    if (!start) return 0

    let duration = intervalToDuration({
      start: new Date(start),
      end: new Date()
    })
    const { hours, minutes, seconds } = duration

    return `${hours} год, ${minutes} хв, ${seconds} сек`
  },
  getFormatDistance(start, end) {
    const locale = { locale: uk }

    return `з ${format(new Date(start), 'HH:mm')} до ${format(
      new Date(end),
      'HH:mm'
    )} / ${formatDistance(new Date(start), new Date(end), locale)}`
  }
}
