import React, { useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { columns } from './columns'
import GridContainer from 'components/Grid/GridContainer'
import { useSelector } from 'react-redux'
import CustomTabs from 'components/CustomTabs/CustomTabs'
import { Button, makeStyles } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import BlockIcon from '@material-ui/icons/Block'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { actsSelector } from 'redux/selectors/actsSelectors'
import { ActsItem } from 'components/Acts/ActsItem'
import { useHelper } from 'shared/hooks/useHelper'
import { ReturnActHelper } from 'services/helpers/ReturnActHelper'
import { OrderHelper } from 'services/helpers/OrderHelper'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import { FilterHelper } from 'services/helpers/FilterHelper'
import {
  ordersSelector,
  ordersSkipSelector,
  ordersTotalSelector,
  discardedOrdersSelector,
  discardedOrdersSkipSelector,
  discardedOrdersTotalSelector
} from 'redux/selectors/ordersSelectors'
import {
  activeOrderStatuses,
  discardedOrderStatuses
} from 'services/helpers/OrderHelper'
import { NotifyHelper } from 'services/helpers/NotifyHelper'
import { useHistory } from 'react-router'
import * as XLSX from 'xlsx'
import { format } from 'date-fns'

const styles = {
  textCenter: {
    textAlign: 'center'
  }
}
const useStyles = makeStyles(styles)

const Tab = ({
  data,
  loading,
  total,
  handlePageSelect,
  onFilterChange,
  handleRowClick
}) => {
  const orderHelper = useHelper(OrderHelper)
  const classes = useStyles()
  const ordersWithNum = orderHelper.getOrdersWithNum(data)

  return (
    <DataGrid
      onRowClick={handleRowClick}
      onPageChange={handlePageSelect}
      onFilterModelChange={onFilterChange}
      className={classes.root}
      rows={ordersWithNum}
      columns={columns}
      pagination
      {...data}
      filterMode="client"
      paginationMode="client"
      autoHeight={true}
      loading={loading}
      pageSize={10}
      disableSelectionOnClick={true}
      rowCount={total}
      checkboxSelection={false}
      autoPageSize={false}
      getRowId={(item) => {
        return item.uuid
      }}
      localeText={rowsTableTranslate}
    />
  )
}

export default function Orders() {
  const orders = useSelector(ordersSelector)
  const ordersSkip = useSelector(ordersSkipSelector)
  const ordersTotal = useSelector(ordersTotalSelector)
  const acts = useSelector(actsSelector)
  const notifyHelper = useHelper(NotifyHelper)
  const returnActHelper = useHelper(ReturnActHelper)
  const orderHelper = useHelper(OrderHelper)
  const data = orderHelper.activeOrders(orders)
  const loading = useSelector(appLoadingSelector)
  const filterHelper = useHelper(FilterHelper)
  const discardedOrders = useSelector(discardedOrdersSelector)
  const discardedSkip = useSelector(discardedOrdersSkipSelector)
  const discardedTotal = useSelector(discardedOrdersTotalSelector)
  const [activeTab, setActiveTab] = useState(null)
  const history = useHistory()

  useEffect(() => {
    orderHelper.setOrdersTotal(
      filterHelper.buildFilter({ status: activeOrderStatuses })
    )
    orderHelper.setOrders(
      filterHelper.buildFilter({ status: activeOrderStatuses }),
      ordersSkip
    )
    returnActHelper.setActs()
  }, [])

  useEffect(() => {
    const unsub = notifyHelper.onMessage(async (payload) => {
      const order = await orderHelper.getOrderById(payload.data?.orderId)
      if (!order) return
      if (activeTab === 1 && discardedOrderStatuses.includes(order.status)) {
        const filter = filterHelper.buildFilter({
          status: discardedOrderStatuses
        })
        await orderHelper.addToDiscarded(order, filter)
      } else {
        const filter = filterHelper.buildFilter({
          status: activeOrderStatuses
        })
        await orderHelper.addToOrders(order, filter)
      }
    })
    return unsub
  }, [activeTab, orderHelper, notifyHelper])

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex)
    if (tabIndex === 1 && discardedSkip >= 0) {
      const filter = filterHelper.buildFilter({
        status: discardedOrderStatuses
      })
      orderHelper.setDiscardedOrders(filter)
      orderHelper.setDiscardedOrdersTotal(filter)
    }
  }

  const handlePageSelect = () => {
    if (loading || ordersSkip === ordersTotal) return
    orderHelper.addOrders(
      filterHelper.buildFilter({ status: activeOrderStatuses }),
      ordersSkip
    )
  }
  const handleDiscardPageSelect = () => {
    if (loading || discardedSkip === discardedTotal) return
    orderHelper.addDiscardedOrders(
      discardedSkip,
      filterHelper.buildFilter({ status: discardedOrderStatuses })
    )
  }
  const handleRowClick = (param) => {
    history.push(`/admin/order/${param.row.uuid}`)
  }

  const onFilterChange = (filterModel) => {
    if (activeTab === 0) {
      if (ordersSkip === ordersTotal) return
      orderHelper.setOrders(
        filterHelper.buildFilter({
          status: activeOrderStatuses,
          [filterModel.items[0]?.columnField]: filterModel.items[0]?.value
        }),
        ordersSkip
      )
      return
    }

    if (activeTab === 1) {
      if (ordersSkip === ordersTotal) return

      orderHelper.setDiscardedOrders(
        discardedSkip,
        filterHelper.buildFilter({
          status: discardedOrderStatuses,
          [filterModel.items[0]?.columnField]: filterModel.items[0]?.value
        })
      )
      orderHelper.setDiscardedOrdersTotal(
        filterHelper.buildFilter({ status: discardedOrderStatuses })
      )
    }
  }

  const onXlsxPress = async () => {
    const newBook = XLSX.utils.book_new();
    const newSheet = XLSX.utils.json_to_sheet(data);
  

    const date = format(new Date(), 'dd.MM.yyyy');
    XLSX.utils.book_append_sheet(newBook, newSheet, date);
  

    const blob = XLSX.write(newBook);

    const url = URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `${date}.xlsx`;
  

    document.body.appendChild(a);
    a.click();
  

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  return (
    <GridContainer>
      <div style={{ width: '100%' }}>
        <CustomTabs
          headerColor="success"
          onChange={handleTabChange}
          tabs={[
            {
              tabName: 'Усі замовлення',
              tabIcon: GroupIcon,
              tabContent: (
                <Tab
                  handleRowClick={handleRowClick}
                  handlePageSelect={handlePageSelect}
                  onFilterChange={onFilterChange}
                  className={styles.textCenter}
                  data={data}
                  loading={loading}
                  total={ordersTotal}
                />
              )
            },
            {
              tabName: 'Скасовані замовлення',
              tabIcon: BlockIcon,
              tabContent: (
                <Tab
                  handleRowClick={handleRowClick}
                  handlePageSelect={handleDiscardPageSelect}
                  onFilterChange={onFilterChange}
                  className={styles.textCenter}
                  data={discardedOrders}
                  loading={loading}
                  total={discardedTotal}
                />
              )
            },
            {
              tabName: 'Акти Повернення',
              tabIcon: ContactMailIcon,
              tabContent: acts.map((act) => (
                <ActsItem item={act} key={act.uuid} orderHelper={orderHelper} />
              ))
            }
          ]}
        />
        <Button onClick={onXlsxPress} variant="contained" color="secondary">
          Завантажити ексель
        </Button>
      </div>
    </GridContainer>
  )
}
