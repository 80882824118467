import { setLoading } from 'redux/reducers/appReducer'
import { addBanner } from 'redux/reducers/BannersReducer'
import { deleteBanner } from 'redux/reducers/BannersReducer'
import { deleteGroup } from 'redux/reducers/BannersReducer'
import { addGroup } from 'redux/reducers/BannersReducer'
import { setGroups as setGroupsAction } from 'redux/reducers/BannersReducer'
import { BannersApiService } from 'services/api/BannersApiService'
import { StatefullService } from './StatefullService'

export class BannerHelper extends StatefullService {
  static instance = null

  constructor() {
    super()
    this.bannersApiService = new BannersApiService()
  }

  static make() {
    if (!BannerHelper.instance) {
      BannerHelper.instance = new BannerHelper()
    }
    return BannerHelper.instance
  }

  async createGroup(name) {
    const { isSuccess, data } = await this._callApi(
      this.bannersApiService.createGroup.bind(this.bannersApiService),
      setLoading,
      name
    )
    if (isSuccess) {
      this._dispatch(addGroup(data))
      return data
    }
    return null
  }

  async setGroups() {
    const { isSuccess, data } = await this._callApi(
      this.bannersApiService.getGroups.bind(this.bannersApiService),
      setLoading
    )
    if (isSuccess) {
      this._dispatch(setGroupsAction(data))
      return data
    }
    return null
  }

  async createBanner(payload) {
    const { isSuccess, data } = await this._callApi(
      this.bannersApiService.createBanner.bind(this.bannersApiService),
      setLoading,
      {
        payload
      }
    )

    if (isSuccess) {
      this._dispatch(addBanner(data))
      return data
    }
    return null
  }

  async deleteBanner({ id }) {
    const { isSuccess, data } = await this._callApi(
      this.bannersApiService.deleteBanner.bind(this.bannersApiService),
      setLoading,
      {
        id
      }
    )
    if (isSuccess) {
      this._dispatch(deleteBanner(data))
      return data
    }
    return null
  }

  async deleteGroup({ id }) {
    const { isSuccess, data } = await this._callApi(
      this.bannersApiService.deleteGroup.bind(this.bannersApiService),
      setLoading,
      {
        id
      }
    )
    if (isSuccess) {
      this._dispatch(deleteGroup(data))
      return data
    }
    return null
  }
}
