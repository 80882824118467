import { AbstractRequest } from 'services/abstract/AbstractRequest'

export class OrderApiService extends AbstractRequest {
  constructor() {
    super(`/orders`)
  }

  async getOrderById(orderId) {
    const { data, status } = await this.get(`${this.baseUrl}/${orderId}`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async getOrders({ filters }) {
    const filter = filters && JSON.stringify(filters)
    const { data, status } = await this.get(`${this.baseUrl}/`, {
      params: {
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        filter,
        sort: 'createdAt'
      }
    })

    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }

    return this._returnSuccess(data.data)
  }

  async getExpiredOrders() {
    const { data, status } = await this.get(`${this.baseUrl}/expired`)
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async getOrdersCount(filterObject) {
    const filter = filterObject && JSON.stringify(filterObject)
    const { data, status } = await this.get(`${this.baseUrl}/count`, {
      params: {
        filter
      }
    })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }

  async setStatusOrder({ orderId, status }) {
    const { data, responseStatus } = await this.patch(
      `${this.baseUrl}/${orderId}/status`,
      {
        status
      }
    )
    if (responseStatus >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data.data)
  }
}
