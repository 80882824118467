import { ErrorHandler } from 'services/ErrorHandler'

export class AbstractStorageManager {
  constructor() {
    this.errorHandler = new ErrorHandler()
  }

  _normalizeValue(value) {
    if (typeof value === 'object') {
      return JSON.stringify(value)
    }
    return value
  }

  _denormalizeValue(value) {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  setItem(key, value) {
    try {
      const normalized = this._normalizeValue(value)
      localStorage.setItem(key, normalized)
      return this.getItem(key)
    } catch (e) {
      this.errorHandler.storageError(e)
      return null
    }
  }

  getItem(key) {
    try {
      const value = localStorage.getItem(key)
      return this._denormalizeValue(value)
    } catch (e) {
      this.errorHandler.storageError(e)
      return null
    }
  }

  removeItem(key) {
    try {
      const value = this.getItem(key)
      if (value !== null) {
        localStorage.removeItem(key)
      }
      return value
    } catch (e) {
      this.errorHandler.storageError(e)
      return null
    }
  }

  hasItem(key) {
    const value = this.getItem(key)
    return value !== null
  }
}
