import React, { useEffect, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { TransformUtils } from 'services/utils/TransformUtils'
import { orderStatus } from 'helpers/translates'
import { format } from 'date-fns'
import { useHistory, useParams } from 'react-router'
import { AccordionOrder } from 'components/Order/AccordionOrder'
import { useHelper } from 'shared/hooks/useHelper'
import { OrderHelper } from 'services/helpers/OrderHelper'
import { OrderStatus } from 'helpers/enums'
import { useSelector } from 'react-redux'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import Spiner from 'components/Spiner/Spiner'
import { EmployeesHelper } from 'services/helpers/EmployeesHelper'
import { NotifyHelper } from 'services/helpers/NotifyHelper'
import { orderSocketSelector } from 'redux/selectors/gatewaySelectors'
import { SettingsHelper } from 'services/helpers/SettingsHelper'
import { Timer } from 'widgets/Timer'
import { SettingsDeadlinesTranslate } from 'helpers/translates'
import { SettingsDeadlines } from 'shared/enums'
import { OrderMainItem } from './OrderItems/OrderMainItem'
import { OrderTitle } from './OrderItems/OrderTitle'
import { OrderLayout } from './OrderItems/OrderLayout'

const styles = {
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    marginBottom: 10,
    borderRadius: 10
  },
  mainItem: {
    textAlign: 'center'
  },
  mainContentContainer: {
    paddingTop: 10,
    paddingBottom: 10
  },
  titleText: {
    fontWeight: '600',
    fontSize: 19
  },
  keyText: {
    fontWeight: '500',
    fontSize: 17
  },
  valueText: {
    fontWeight: '500',
    fontSize: 15,
    color: green[400]
  },
  buttonText: {
    fontWeight: '500',
    fontSize: 17,
    color: 'fff'
  }
}
const useStyles = makeStyles(styles)

const AddressButton = ({ onAddressClick, address }) => {
  return (
    <Button onClick={onAddressClick}>
      <Typography style={styles.valueText}>
        {address.street} {address.building}
      </Typography>
    </Button>
  )
}

const getOrderMainData = (order, handleAddressClick) => {
  return [
    {
      key: 'Статус:',
      value: orderStatus[order.status]
    },
    {
      key: 'Клієнт:',
      value: `${order.user.phone} ${order.user.name}`
    },
    {
      key: 'Адміністратор:',
      value: `${order.voz.firstName} ${order.voz.lastName}, ID: ${order.voz.employeeId}`
    },
    {
      key: 'Магазин:',
      value: `${order.store.address}`
    },
    {
      key: 'Адреса клієнта:',
      value:
        order.deliveryAction === 'self' ? (
          'Самовивіз'
        ) : (
          <AddressButton
            // TODO: uncomment when map will fixed
            // onAddressClick={handleAddressClick}
            address={order.address}
          />
        )
    },
    {
      key: 'Час замовлення:',
      value: `${format(new Date(order.createdAt), 'dd.MM.yyyy Час: HH:mm')}`
    },
    order.comment && {
      key: 'Коментар:',
      value: order.comment
    }
  ]
}

export const Order = () => {
  const classes = useStyles()
  const orderSocket = useSelector(orderSocketSelector)
  const { orderId } = useParams()
  const [order, setOrder] = useState(null)
  const [orderNum, setOrderNum] = useState('')
  const orderHelper = useHelper(OrderHelper)
  const settingsHelper = useHelper(SettingsHelper)
  const employeesHelper = useHelper(EmployeesHelper)
  const notifyHelper = useHelper(NotifyHelper)
  const history = useHistory()
  const loading = useSelector(appLoadingSelector)
  const settings = useSelector((state) => state.settings.settings)

  useEffect(() => {
    settingsHelper.setSettings()
  }, [])

  useEffect(() => {
    const getOrder = async () => {
      const currOrder = await orderHelper.getOrderById(orderId)
      if (currOrder) {
        setOrderNum(TransformUtils.orderToOrderNum(currOrder))
        setOrder(currOrder)
      }
      return currOrder
    }
    getOrder()
  }, [orderId])

  useEffect(() => {
    if (orderSocket) {
      orderSocket.on('status-update', async () => {
        const currOrder = await orderHelper.getOrderById(orderId)
        if (currOrder) {
          setOrderNum(TransformUtils.orderToOrderNum(currOrder))
          setOrder(currOrder)
        }
        return currOrder
      })
    }
  }, [orderSocket])

  if (loading) return <Spiner />

  if (!order || !settings) return null

  const handleOrderCancel = async () => {
    const updated = await orderHelper.setStatusOrder({
      orderId: order.uuid,
      status: OrderStatus.Discarded
    })
    setOrder(updated)
    if (order.employee?.employeeId) {
      await Promise.all([
        employeesHelper.setEmployeeStatusFree(order.employee.employeeId),
        notifyHelper.sendOrderCancel(order.employee.deviceId, {
          orderId: order.uuid
        })
      ])
    }
    if (
      order.voz &&
      order.voz.employeeId &&
      order.voz.employeeId !== order.employee?.employeeId
    ) {
      await notifyHelper.sendOrderStatus(order.voz.deviceId, {
        orderId: order.uuid,
        status: OrderStatus.Discarded,
        storeId: order.storeId
      })
    }
  }

  const handleAddressClick = () => {
    history.push('/admin/maps', [order.address])
  }

  const orderMain = getOrderMainData(order, handleAddressClick)

  const orderDeadlines = Object.values(SettingsDeadlines).map((key) => {
    if (Object.keys(settings).includes(key)) {
      return {
        key: SettingsDeadlinesTranslate[key],
        value: `${settings[key]} хв`
      }
    }
  })

  let oldTime, oldKey
  const orderStatuses = Object.entries(order.statusDates).map(
    ([nextKey, statusesTime]) => {
      if (!oldTime) {
        oldTime = statusesTime
        return {
          key: orderStatus[nextKey],
          value: <Timer statusTime={oldTime} styles={styles} />
        }
      }
    
    oldTime = statusesTime?.end && 
    oldTime?.end
            ? {
                start: oldTime.end,
                end: statusesTime.start
            }
            : {
                start: oldTime.end
            }
      oldKey = nextKey
      return {
        key: orderStatus[nextKey],
        value: <Timer statusTime={oldTime} styles={styles} />
      }
    }
  )

  return (
    <Grid container direction="column">
      <OrderLayout styles={styles}>
        <OrderTitle
          title={`Замовлення № ${orderNum}`}
          color="black"
          styles={styles}
        />
      </OrderLayout>
      <OrderLayout styles={styles}>
        <OrderTitle title="Загальне:" color="red" styles={styles} />
        <OrderMainItem data={orderMain} styles={styles} />
      </OrderLayout>

      <OrderLayout styles={styles}>
        <OrderTitle title="Дедлайни:" color="red" styles={styles} />
        <OrderMainItem
          data={orderDeadlines}
          styles={styles}
          justifyContent="center"
        />
      </OrderLayout>

      <OrderLayout styles={styles}>
        <OrderTitle title="Статуси:" color="red" styles={styles} />

        <OrderMainItem data={orderStatuses} styles={styles} />
      </OrderLayout>
      <AccordionOrder order={order} />
      {order.status !== OrderStatus.Discarded && (
        <Grid container style={styles.mainContentContainer}>
          <Grid item lg={12} md={12} sm={12} xs={12} style={styles.mainItem}>
            <Button
              onClick={handleOrderCancel}
              variant="contained"
              color="secondary"
            >
              <Typography className={classes.buttonText}>Скасувати</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
