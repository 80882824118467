import React, { useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { columns } from './columns'
import GridContainer from 'components/Grid/GridContainer'
import { useSelector } from 'react-redux'
import CustomTabs from 'components/CustomTabs/CustomTabs'
import { makeStyles } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import BlockIcon from '@material-ui/icons/Block'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { useHelper } from 'shared/hooks/useHelper'
import { FilterHelper } from 'services/helpers/FilterHelper'
import { appLoadingSelector } from 'redux/selectors/appSelector'
import { EmployeesHelper } from 'services/helpers/EmployeesHelper'
import { Limits } from 'shared/enums'

const styles = {
  textCenter: {
    textAlign: 'center'
  }
}
const useStyles = makeStyles(styles)

const Tab = ({
  employees,
  handlePageSelect,
  loading,
  total,
  onFilterChange
}) => {
  return (
    <DataGrid
      onFilterModelChange={onFilterChange}
      onPageChange={handlePageSelect}
      rows={employees}
      columns={columns}
      pagination
      {...employees}
      paginationMode="client"
      pageSize={Limits.EmployeesLimit}
      autoHeight={true}
      loading={loading}
      disableSelectionOnClick={true}
      rowCount={total}
      checkboxSelection={false}
      autoPageSize={false}
      getRowId={(item) => {
        return item.employeeId
      }}
      localeText={rowsTableTranslate}
    />
  )
}

export default function Users() {
  const { employees, total, skip } = useSelector((state) => state.employees)
  const loading = useSelector(appLoadingSelector)
  const classes = useStyles()
  const filterHelper = useHelper(FilterHelper)
  const employeeHelper = useHelper(EmployeesHelper)

  const handlePageSelect = () => {
    if (loading || skip >= total) return
    employeeHelper.getEmployees(skip)
  }
  /* eslint-disable */
  useEffect(() => {
    employeeHelper.getEmployees(skip)
    employeeHelper.getCountEmployees()
  }, [])
  /* eslint-enable */

  const onFilterChange = (filterModel) => {
    employeeHelper.getEmployees(
      skip,
      filterHelper.setFilterData(
        filterModel.items && filterModel.items[0]?.columnField,
        filterModel.items && filterModel.items[0]?.value
      )
    )
  }

  if (!total || !employees || !employees.length) return null

  return (
    <GridContainer>
      <div style={{ width: '100%' }}>
        <CustomTabs
          headerColor="success"
          tabs={[
            {
              tabName: 'Працівники',
              tabIcon: GroupIcon,
              tabContent: (
                <Tab
                  onFilterChange={onFilterChange}
                  className={classes.textCenter}
                  handlePageSelect={handlePageSelect}
                  employees={employees}
                  loading={loading}
                  total={total}
                />
              )
            },
            {
              tabName: 'Заблоковані працівники',
              tabIcon: BlockIcon,
              tabContent: null
            }
          ]}
        />
      </div>
    </GridContainer>
  )
}
