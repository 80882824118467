import React, { useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useSelector } from 'react-redux'
import Spiner from 'components/Spiner/Spiner'
import { CreatedBannersItemWidget } from 'widgets/CreatedBannersItemWidget'
import { useHelper } from 'shared/hooks/useHelper'
import { BannerHelper } from 'services/helpers/BannerHelper'
import { appLoadingSelector } from 'redux/selectors/appSelector'

export function CreatedBanners() {
  const groups = useSelector((state) => state.banners.groups.data)
  const loading = useSelector(appLoadingSelector)
  const bannerHelper = useHelper(BannerHelper)

  useEffect(() => {
    bannerHelper.setGroups()
  }, [bannerHelper])

  if (loading) {
    return <Spiner />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {!!groups?.length &&
          groups.map((data) => {
            return <CreatedBannersItemWidget key={data.uuid} item={data} />
          })}
      </GridItem>
    </GridContainer>
  )
}
