export const TransformUtils = {
  normalizeQuantityDifference(measurementUnit, quantity, pickQuantity) {
    return measurementUnit === 'кг'
      ? (quantity - pickQuantity) * 0.1
      : quantity - pickQuantity
  },
  orderToOrderNum(order) {
    if (order.orderNum) return order.orderNum
    const date = new Date(order.createdAt)
    return Number(
      `${order.store.shopNumber}${date.getTime().toString().slice(5, -3)}`
    )
  }
}
