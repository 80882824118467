import { createStore, compose } from 'redux'

import { rootCombine } from './index'

export const store = createStore(
  rootCombine,
  compose(
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)
