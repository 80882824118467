import { DataGrid } from '@material-ui/data-grid'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { rowsTableTranslate } from 'shared/rowsTableTranslate'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core'
import { successColor } from 'assets/jss/material-dashboard-react'
import { useHelper } from 'shared/hooks/useHelper'
import { BonusHelper } from 'services/helpers/BonusHelper'

const columns = [
  {
    field: `createdAt`,
    type: 'dateTime',
    headerName: `Дата нарахування`,
    width: 300,
    renderCell: (params) => {
      const date = format(new Date(params.row.createdAt), 'dd.MM.yyyy HH:mm')
      return date
    }
  },
  {
    field: 'amount',
    type: 'number',
    headerName: 'Зарахування',
    width: 300,
    renderCell: (params) => params?.row && `${params.row.amount} ₴`
  },

  {
    field: 'employeeId',
    type: 'number',
    headerName: 'ID користувача',
    width: 300
  }
]

function Bonuses(route) {
  const bonusHelper = useHelper(BonusHelper)
  const classes = useStyles()
  const bonuses = useSelector((state) => state.bonuses.bonuses.data)
  const loading = useSelector((state) => state.bonuses.loading)
  const count = useSelector((state) => state.bonuses.bonuses.count)
  const { employeeId, role, fullName } = route.history.location.state.params

  useEffect(() => {
    bonusHelper.setBonuses(employeeId)
    bonusHelper.setCount(employeeId)
  }, [employeeId, bonusHelper])

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.headingText}>{role}</div>
        <div className={classes.secondaryHeading}>{fullName}</div>
      </div>
      <DataGrid
        disableSelectionOnClick
        rows={bonuses.data || []}
        getRowId={(item) => item.uuid}
        columns={columns}
        pagination
        paginationMode="client"
        pageSize={10}
        checkboxSelection={false}
        loading={loading}
        rowCount={count}
        autoHeight={true}
        localeText={rowsTableTranslate}
      />
    </div>
  )
}

export default Bonuses

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: theme.typography.pxToRem(17),
    color: successColor[0],
    fontWeight: '500',
    paddingRight: 5
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingBottom: 20
  },
  container: { padding: 60, textAlign: 'center' }
}))
