import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  brands: []
}

const brandsSlice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    addBrands: (state, action) => {
      state.brands = state.brands.concat(action.payload)
    }
  }
})

const { reducer, actions } = brandsSlice
export default reducer
export const { addBrands } = actions
