import { AbstractRequest } from 'services/abstract/AbstractRequest'

const limit = 10

export class BonusApiService extends AbstractRequest {
  constructor() {
    super('/bonuses')
  }

  async addBonuses({ skip, employeeId }) {
    const params = {
      skip,
      sort: 'name',
      limit,
      filter: JSON.stringify({ employeeId })
    }
    const { data, status } = await this.get(this.baseUrl, { params })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data)
  }

  async setBonuses({ employeeId }) {
    const params = {
      skip: 0,
      sort: 'name',
      limit: Number.MAX_SAFE_INTEGER,
      filter: JSON.stringify({ employeeId })
    }
    const { data, status } = await this.get(this.baseUrl, { params })
    if (status >= this.minErrorStatus) {
      return this._returnFailure(status, data.messages)
    }
    return this._returnSuccess(data)
  }

  async countBonuses(employeeId) {
    const params = {
      filter: JSON.stringify({ employeeId })
    }
    const { data, status } = await this.get(`${this.baseUrl}/count`, { params })
    return status < this.minErrorStatus
      ? this._returnSuccess(data.data)
      : this._returnFailure(status, data.messages)
  }
}
