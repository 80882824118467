import { StatusCodes } from 'http-status-codes'
import { errorsSet } from 'redux/reducers/appReducer'
import { removeUserData } from 'redux/reducers/userReducer'
import { store } from 'redux/store'

export class ErrorHandler {
  _dispatch(action) {
    store.dispatch(action)
  }

  _select(selector) {
    const state = store.getState()
    return selector(state)
  }

  _log(...args) {
    console.error(...args)
  }

  storageError(e) {
    this._log(e)
  }

  apiError(e) {
    const errorMessages = e.response?.data?.messages ?? e
    this._log(errorMessages)

    if (e.response) {
      const { status, data } = e.response
      if (
        status === StatusCodes.UNAUTHORIZED ||
        status === StatusCodes.UNPROCESSABLE_ENTITY
      ) {
        window.localStorage.removeItem('user')
        this._dispatch(removeUserData())
        return { status, data }
      }

      this._dispatch(errorsSet({ errors: e.response.data.messages }))
      return { status, data }
    }

    this._dispatch(errorsSet({ errors: ['Internal App Error'] }))
    return {
      status: StatusCodes.INTERNAL_SERVER_ERROR,
      data: {
        messages: [e.message || 'Internal App Error']
      }
    }
  }
}
