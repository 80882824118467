import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { successColor } from 'assets/jss/material-dashboard-react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from 'components/CustomButtons/Button'
import { useHelper } from 'shared/hooks/useHelper'
import { SettingsHelper } from 'services/helpers/SettingsHelper'
import { DatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(() => ({
  paddingRight: {
    paddingRight: 10,
    '& .MuiFormLabel-root': {
      paddingTop: 10
    }
  },
  stopService: {
    paddingRight: 10,
    '& .MuiFormLabel-root': {
      paddingTop: 10
    }
  },
  stopServiceContainer: {
    paddingTop: 10
  },
  heading: {
    display: 'flex',
    flexDirection: 'columm',
    flex: 1,
    paddingBottom: 60,
    justifyContent: 'center',
    textAlign: 'center'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30
  },
  secoundTitle: {
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30
  },
  buttons: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'columm',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
  },
  content: {
    paddingBottom: 60
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row'
  },
  paddingLeft10: {
    paddingLeft: 10
  },
  '& .makeStyles-heading-74': {
    justifyContent: 'center'
  }
}))

const emptyDiscount = {
  orderSum: 0,
  discount: 0,
  expireDate: new Date('01.02.2020'),
  startDate: new Date('01.01.2020')
}

export const DeliverySettings = ({ settings }) => {
  const settingsHelper = useHelper(SettingsHelper)
  const [data, setData] = useState({})
  const [deliveryDiscounts, setDeliveryDiscounts] = useState(
    settings.deliveryDiscounts || [emptyDiscount]
  )
  const classes = useStyles()

  useEffect(() => {
    if (!settings.deliveryDiscounts) return
    setDeliveryDiscounts(settings.deliveryDiscounts)
  }, [settings.deliveryDiscounts])

  const handleDiscoundPice = useCallback(
    (e) => {
      const payload = { ...data, deliveryDiscountPrice: Number(e.target.value) }
      setData(payload)
    },
    [setData]
  )

  const handleSaveSettings = useCallback(() => {
    const filtredDiscounts = deliveryDiscounts.filter(
      (item) => item.orderSum > 0
    )
    const payload = filtredDiscounts.length
      ? {
          ...settings,
          ...data,
          deliveryDiscounts: filtredDiscounts
        }
      : {
          ...settings,
          ...data,
          deliveryDiscounts: []
        }
    settingsHelper.putSettings(payload)
  }, [settingsHelper, deliveryDiscounts, data])

  const handleDeliveryDiscounts = useCallback((e, index, field) => {
    let value = e
    if (field !== 'expireDate' && field !== 'startDate') {
      value = e.target.value
    }
    setDeliveryDiscounts((prevState) => {
      const updatedDiscounts = [...prevState]
      updatedDiscounts[index] = {
        ...updatedDiscounts[index],
        [field]:
          field !== 'expireDate' && field !== 'startDate'
            ? Number(value)
            : new Date(value)
      }
      return updatedDiscounts
    })
  }, [])

  const handleAddDiscount = useCallback(() => {
    const arr = [...deliveryDiscounts]
    arr.push(emptyDiscount)
    setDeliveryDiscounts(arr)
  }, [setDeliveryDiscounts, deliveryDiscounts])

  const handleRemoveDiscount = useCallback(() => {
    if (!deliveryDiscounts.length) return
    const arr = [...deliveryDiscounts]
    arr.pop()
    setDeliveryDiscounts(arr)
  }, [setDeliveryDiscounts, deliveryDiscounts])

  const handleReset = () => {
    if (!settings) return
    settingsHelper.setSettings()
  }

  if (!settings) return null

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography
          style={{
            color: successColor[0],
            fontWeight: '500',
            paddingRight: 5
          }}
        >
          Промо
        </Typography>
        <Typography>на доставку</Typography>
      </div>
      <div className={classes.heading}>
        <div>
          <TextField
            className={classes.paddingRight}
            id="standard-deliveryPrice"
            label="Сума загальної знижки"
            type="number"
            name="deliveryDiscountPrice"
            onChange={handleDiscoundPice}
            defaultValue={settings.deliveryDiscountPrice}
            InputProps={{
              endAdornment: <InputAdornment position="end">₴</InputAdornment>
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <div>
            {deliveryDiscounts.map((item, index) => {
              return (
                <div key={index}>
                  <TextField
                    className={classes.paddingRight}
                    id={`deliveryDiscounts-orderSum-${index}`}
                    label="Ціна замовлення перевищує"
                    type="number"
                    name="deliveryDiscountPrice"
                    onChange={(e) =>
                      handleDeliveryDiscounts(e, index, 'orderSum')
                    }
                    value={item.orderSum}
                    defaultValue={item.orderSum}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₴</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <TextField
                    className={classes.paddingRight}
                    id={`deliveryDiscounts-discount-${index}`}
                    label="Сума знижки (в грн)"
                    type="number"
                    name="deliveryDiscountPrice"
                    onChange={(e) =>
                      handleDeliveryDiscounts(e, index, 'discount')
                    }
                    value={item.discount}
                    defaultValue={item.discount}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₴</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <DatePicker
                    id={`deliveryDiscounts-startDate-${index}`}
                    className={classes.stopService}
                    label="Діє з"
                    emptyLabel="Оберіть"
                    showTodayButton={true}
                    minDateMessage="Дата не повинна бути раніше мінімальної дати"
                    cancelLabel="Скасувати"
                    clearLabel="Очистити"
                    todayLabel="Сьогодні"
                    format="dd.MM.yyyy"
                    value={item.startDate}
                    onChange={(e) =>
                      handleDeliveryDiscounts(e, index, 'startDate')
                    }
                  />
                  <DatePicker
                    id={`deliveryDiscounts-expireDate-${index}`}
                    className={classes.stopService}
                    label="Діє до"
                    emptyLabel="Оберіть"
                    showTodayButton={true}
                    minDateMessage="Дата не повинна бути раніше мінімальної дати"
                    cancelLabel="Скасувати"
                    clearLabel="Очистити"
                    todayLabel="Сьогодні"
                    format="dd.MM.yyyy"
                    value={item.expireDate}
                    onChange={(e) =>
                      handleDeliveryDiscounts(e, index, 'expireDate')
                    }
                  />
                </div>
              )
            })}
            <Button type="reset" onClick={handleRemoveDiscount}>
              Видалити знижку
            </Button>
            <Button color="success" onClick={handleAddDiscount}>
              Додати знижку
            </Button>
          </div>
          <div className={classes.buttons}>
            <Button type="reset" onClick={handleReset}>
              Скасувати
            </Button>
            <Button color="success" onClick={handleSaveSettings}>
              Зберегти
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
